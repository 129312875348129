import React from 'react';

export default props => (
    <section className="content-header">
        <h1>
            {props.titulo}
            <small>{props.subtitulo}</small>
        </h1>
    </section>

)