
import React, { useEffect, useState } from 'react'
import HeaderSimples from '../../../common/HeaderSimples'
import Config from '../../../../config'
import { AxiosPost } from '../../../../lib/utils3'
import { usuario } from '../../../../lib/utils2'
import ClipLoader from "react-spinners/ClipLoader"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify";

const initialValue = {
    periodoInicial: '',
    periodoFinal: '',
    statusOs: '',
    local: '',
    problema: '',
    tipoRelatorio: '',
    ponto: 0,
}

const RelatorioConsumoEnergia = () => {
    const [spinner, setSpinner] = useState(false)
    const [values, setValue] = useState(initialValue);
    const [result, setResult] = useState([]);
    const [qtdDias, setQtdDias] = useState(0);
    var days = 0;

    function changeValue(e) {
        const { name, value } = e.target;
        setValue({ ...values, [name]: value });
    }

    const toastSuccess = (mensagem) => {
        toast.success(mensagem, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      };
    
      const toastError = (mensagem) => {
        toast.error(mensagem, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      };

    const dataDiff = () => {
        let d1 = values.periodoInicial;
        let d2 = values.periodoFinal;
        let diff = moment(d2).diff(moment(d1));
        let dias = moment.duration(diff).asDays(); 
        days = dias + 1
        setQtdDias(days)
           
        if(document.getElementById("periodoInicial").value == "" || document.getElementById("periodoFinal").value == ""){
            toastError("Não foi possivel gerar o relatório. Por favor, insira um período válido.")
        } else {
        consumo()
        }
   
      }

    const consumo = async () => { 
        
        setSpinner(true)
        try {
             await AxiosPost(`${Config.dominio}/php/relatorioConsumoEnergia.php`, 
            JSON.stringify({ 
                dias: days,
                dbAdmin: usuario().municipio.dbuser,
                idMunicipio: usuario().municipio.id,
                valor_kwh: usuario().municipio.valor_kwh,
            }))
                .then( (response) => {
                    if (response.data.length === 0) {
                        toastError("Não possui dados para gerar o relatório.")
                    } else {
                        setResult(response.data);
                    }
                    setSpinner(false)
                });

        } catch (e) {
            //alert("Não foi possível carregar a lista de materiais. Contate o administrador do sistema.");
            console.log(e);
        }
    }

    function formataMoeda(valor){
        let  atual = Number(valor)
        let f = atual.toLocaleString('pt-br', {minimumFractionDigits: 2})

        return f
    }

    function verificaForm() {
        if(document.getElementById("periodoInicial").value == "" || document.getElementById("periodoFinal").value == ""){
            toastError("Não foi possivel gerar o relatório. Por favor, insira um período válido.")
        } else {
            document.forms["formPrint"].submit()  
        }
   

    }

    return (
        <>
        <ToastContainer />
            <HeaderSimples titulo='Relatório' subtitulo='Consumo de energia' />

            <form
                target="_blank"
                id="formPrint"
                action={`${Config.dominio}/php/relatorios/consumo.php`}
                method="post"
            >
            
                <input type="hidden" name="userSys" value={usuario().nome} />
                <input type="hidden" name="municipio" value={usuario().municipio.nome} />
                <input type="hidden" name="baseMunicipio" value={usuario().municipio.dbase} />
                <input type="hidden" name="baseAdmin" value={usuario().municipio.dbuse} />
                <input type="hidden" name="idMunicipio" value={usuario().municipio.id} />
                <input type="hidden" name="valor_kwh" value={usuario().municipio.valor_kwh} />
                <input type="hidden" name="dias" value={qtdDias} />

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Período</h3>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Início:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date"
                                                    name="periodoInicial"
                                                    className="form-control pull-right"
                                                    id="periodoInicial"
                                                    onChange={changeValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Final:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date"
                                                    name="periodoFinal"
                                                    className="form-control pull-right"
                                                    id="periodoFinal"
                                                    onChange={changeValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="button" className="btn btn-block btn-success" onClick={() =>  dataDiff()}>Gerar relatório</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ClipLoader
                css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
                color={"#123abc"}
                loading={spinner}
            />

                {result.length !== 0 && <div className="col-md-8">
                    <div className="box">
                        <div className="box-header">
                            <h3 className="box-title">Consumo</h3>
                            <div className="box-tools">
                                <a className="btn btn-block btn-info" onClick={() => verificaForm()}>
                                    <i className="fa fa-fw fa-print"></i> Imprimir
                                </a>
                            </div>
                        </div>
      
                        <div className="box-body no-padding">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Qnt.</th>
                                    <th>Valor (R$)</th>
                                    <th>Kwh</th>
                                </tr>
                                {result.map((item, index) => 
                                    <>
                         
                                        <tr key={index}>
                                            <td>
                                                {item.descricao}
                                            </td>
                                     
                                            <td>
                                                {item.qtd}
                                            </td>
                                        
                                            <td>
                                                {formataMoeda(item.valor_kwh)}
                                            </td>
                                        
                                            <td>
                                                {item.Kwh}
                                            </td>
                                        </tr>
                                    </>
                                
                                )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>   } 
        
            </form>
            
        </>
        
    );
}
export default RelatorioConsumoEnergia;



