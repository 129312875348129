import React, { Component } from 'react'
import Axios from 'axios'
import Config from '../../../config'


export default class coordToAddrBatch extends Component{

  state={
          fim: false
        }

  async processar(){
    await Axios.get(`${Config.dominio}/php/coordToAddrBatch.php`)
    .then(async (response) => {
      console.log(response.data)
      this.setState({fim: true})
    })
    .catch(erro => console.log(erro))
  }
  
  render() {
    return (
    <>
      <section className="content-header">
        <h1>
          Ponto de Iluminação
          <small>Complementação de cadastro</small>
        </h1>
        <ol className="breadcrumb">
          <li>
            <button className="btn btn-primary" >Voltar</button>
          </li>
        </ol>
      </section>
      <section className="content">
          <div className="col-md-12">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Complementação de cadastro </h3>
                  <div className="box-body">
                    Este módulo fará a complementação do cadastro do ponto importado, inserindo o seu endereço.
                    <div className="form-group" style={{textAlign: 'center'}}>
                      <br />
                      <button type="button" onClick={() => this.processar()} className="btn btn-info btn-flat" > Iniciar </button>
                    </div>
                    { this.state.fim && 
                        <div className="form-group">
                          <label htmlFor="barramento">Atualizado com sucesso!</label>
                        </div>
                    }
                  </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
         </div>
        </section>
    </>
    )
  }
}
