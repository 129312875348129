import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import Config from '../../config'
import Config from '../../configdev'
import { usuario } from '../../lib/utils2'
import { AxiosPost } from '../../lib/utils3'

import Financeiro from '../actionParts/dashboard/financeiro'
import Operacional from '../actionParts/dashboard/operacional'
import MapaGeral from '../actionParts/dashboard/mapa'
import MapaGeral2 from '../actionParts/dashboard/mapa2'
import MapaDashBoard from '../actionParts/dashboard/mapaDashboard'

import LstDistritos from "../actionParts/cadastro/lstDistritos"

import LstGrupoMateriais from '../actionParts/cadastro/lstGrupoMateriais'
import LstMateriais from '../actionParts/cadastro/lstMateriais'

import LstPontosIluminacao from "../actionParts/cadastro/lstPontosIluminacao"
import LstTransformadores from "../actionParts/cadastro/lstTransformadores"
import LstEquipe from "../actionParts/cadastro/equipe/lstEquipe"
import LstTecnicos from "../actionParts/cadastro/equipe/lstTecnicos"
import LstVeiculos from "../actionParts/cadastro/equipe/lstVeiculos"

import Callcenter from "../actionParts/ordemDeServico/callCenter"
import LstAcompanhamentoOS from "../actionParts/ordemDeServico/acompanhamento/lstAcompanhamentoOS"
import Autorizacao from "../actionParts/ordemDeServico/autorizacao"
import Distribuicao from "../actionParts/ordemDeServico/distribuicao"
import LstOrdensDeServico from "../actionParts/ordemDeServico/lstOrdensDeServico"

// Contribuintes
import LstContribuintes from "../actionParts/contribuintes/lstContribuintes"
// import ListagemContribuinte from "../actionParts/contribuintes/listagem"

import CallcenterMapa from "../actionParts/ordemDeServico/callCenter/callcenterMapa"
import CallcenterConsulta from "../actionParts/ordemDeServico/callCenter/consulta"

import Estoque from "../actionParts/estoque/dashboard"
import LstEntrada from "../actionParts/estoque/lstEntrada"
import LstSaida from "../actionParts/estoque/lstSaida"
import Descarte from "../actionParts/estoque/descarte"
import Transferencia from "../actionParts/estoque/transferencia"

import CoordToAddrBatch from "../actionParts/sistema/coordToAddrBatch"
import LstFuncionalidades from "../actionParts/sistema/lstFuncionalidades"
import LstClientes from '../actionParts/sistema/lstClientes'
import LstUsuarios from '../actionParts/sistema/usuario/lstUsuario'
import LstClienteEmpresa from '../actionParts/sistema/lstClienteEmpresa'
import LstClienteMunicipio from '../actionParts/sistema/lstClienteMunicipio'

import MapaGeorref from '../actionParts/georreferenciamento/mapa'

import RelatoriosOrdemServico from '../actionParts/relatorios/ordem-servico/RelatorioOrdemServico'
import RelatorioMateriais from '../actionParts/relatorios/materiais/RelatorioMateriais'
import RelatorioConsumoEnergia from '../actionParts/relatorios/consumo/RelatorioConsumoEnergia'

import './style.css'
import MapaPrincipal from '../actionParts/pontos_mapa/mapa_principal'
import MapaTelegestao from '../actionParts/telegestao/mapaTelegestao'
import CadastroGrupo from '../actionParts/telegestao/cadastroGrupo'
import LstDispositivos from '../actionParts/telegestao/lstDispositivo'

var menuInicial = 0

export default class Menu extends Component {

  state = { lstUsuarioFuncionalidadePermisao: [] }

  async usuarioFuncionalidadePermisao() {
    await AxiosPost(`${Config.dominio}/php/sistema/usuarioFuncionalidadePermissao_list.php`,
      JSON.stringify({ idUsuario: usuario().id })
    )
      .then(async (response) => {
        this.setState({ lstUsuarioFuncionalidadePermisao: response.data })
        menuInicial = usuario.menuInicial
      })
      .catch(erro => console.log(erro))
  }

  habilitado(funcionalidade, permissao) {
    return this.state.lstUsuarioFuncionalidadePermisao && this.state.lstUsuarioFuncionalidadePermisao
      .filter(iF => iF.idFuncionalidade == funcionalidade)
      .find(ip => ip.idPermissao == permissao)
  }

  componentWillMount() {
    this.usuarioFuncionalidadePermisao()
  }

  render() {
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <ul className="sidebar-menu" data-widget="tree">
            {this.habilitado(1, 5) && <li className="treeview"><a href="#"><i className="fa fa-dashboard"></i><span>Dashboard</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(2, 5) && <li><a href="#" onClick={() => ReactDOM.render(<MapaDashBoard />, document.getElementById('Content'))}><i className="fa fa-map-o"></i>Mapa Geral</a></li>}
                {this.habilitado(3, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Operacional />, document.getElementById('Content'))}><i className="fa fa-area-chart"></i>Operacional</a></li>}
                {/* {this.habilitado(4, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Financeiro />, document.getElementById('Content'))}><i className="fa fa-line-chart"></i>Financeiro</a></li>} */}
                {/* {this.habilitado(2, 5) && <li><a href="#" onClick={() => ReactDOM.render(<MapaGeral2 />, document.getElementById('Content'))}><i className="fa fa-map-o"></i>Mapa Teste</a></li>} */}
              </ul>
            </li>
            }
            {this.habilitado(18, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i><span>Cadastro</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(24, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstDistritos />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i> Distritos</a></li>}
                {this.habilitado(25, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstGrupoMateriais />, document.getElementById('Content'))}><i className="fa fa-object-group"></i> Grupo de Materiais</a></li>}
                {this.habilitado(26, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstMateriais />, document.getElementById('Content'))}><i className="fa fa-wrench"></i> Materiais / Serviços</a></li>}
                {this.habilitado(27, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))}><i className="fa fa-lightbulb-o"></i> Pontos de Iluminação</a></li>}
                {this.habilitado(27, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstContribuintes />, document.getElementById('Content'))}><i className="fa fa-users"></i> Contribuintes</a></li>}
                {this.habilitado(28, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstTransformadores />, document.getElementById('Content'))}><i className="fa fa-flash"></i> Transformadores</a></li>}
                {this.habilitado(29, 5) && <li className="treeview"><a href="#" onClick={() => ReactDOM.render(<LstEquipe />, document.getElementById('Content'))}><i className="fa fa-users"></i> Equipe de Trabalho<span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
                  <ul className="treeview-menu">
                    <li><a href="#" onClick={() => ReactDOM.render(<LstTecnicos />, document.getElementById('Content'))}><i className="fa  fa-user"></i> Técnicos</a></li>
                    <li><a href="#" onClick={() => ReactDOM.render(<LstVeiculos />, document.getElementById('Content'))}><i className="fa  fa-truck"></i> Veículos</a></li>
                  </ul>
                </li>
                }
                {this.habilitado(30, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))}><i className="fa fa-user-secret"></i> Fiscais</a></li>}
                {this.habilitado(31, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))}><i className="fa fa-eye"></i> Contribuintes</a></li>}
              </ul>
            </li>
            }
            {this.habilitado(19, 5) && <li className="treeview"><a href="#" ><i className="fa fa-shopping-cart"></i><span>Controle de Materiais</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(32, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstEntrada />, document.getElementById("Content"))}><i className="fa fa-download"></i> Entrada</a></li>}
                {this.habilitado(33, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstSaida />, document.getElementById("Content"))}><i className="fa fa-upload"></i> Saída</a></li>}
                {/* {this.habilitado(34, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Transferencia />, document.getElementById("Content"))}><i className="fa fa-exchange"></i> Transferência</a></li>} */}
                {this.habilitado(35, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Descarte />, document.getElementById("Content"))}><i className="fa fa-trash-o"></i> Descarte</a></li>}
              </ul>
            </li>
            }
            {this.habilitado(10, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i> <span>Ordem de Serviço</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(11, 5) &&
                  <li className="treeview"><a href="#" onClick={() => ReactDOM.render(<Callcenter />, document.getElementById('Content'))}><i className="fa fa-bullhorn"></i> Call Center</a>
                    <ul className="treeview-menu">
                      <li><a href="#" onClick={() => ReactDOM.render(<CallcenterMapa />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i> Novo atendimento</a></li>
                      <li><a href="#" onClick={() => ReactDOM.render(<CallcenterConsulta />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i> Consultar protocolo</a></li>
                    </ul>
                  </li>
                }
                {this.habilitado(12, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Autorizacao />, document.getElementById('Content'))}><i className="fa fa-check-square-o"></i> Autorização</a></li>
                }
                {this.habilitado(13, 5) && <li><a href="#" onClick={() => ReactDOM.render(<Distribuicao />, document.getElementById('Content'))}><i className="fa fa-arrows-alt"></i> Distribuição</a></li>
                }
                {this.habilitado(14, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstAcompanhamentoOS />, document.getElementById('Content'))}><i className="fa fa-binoculars"></i> Acompanhamento</a></li>
                }
                {this.habilitado(44, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstOrdensDeServico />, document.getElementById('Content'))}><i className="fa  fa-flag-checkered"></i> Listagem Geral</a></li>
                }
              </ul>
            </li>
            }
            {this.habilitado(21, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i><span>Diário de Obras</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a></li>
            }
            {this.habilitado(20, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i><span>Medições</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a></li>
            }
            {this.habilitado(22, 5) && <li className="treeview"><a href="#" onClick={() => ""} ><i className="fa fa-edit"></i> <span>Telegestão</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className='treeview-menu'>
                {this.habilitado(9, 5) && <li><a href="#" onClick={() => ReactDOM.render(<MapaTelegestao />, document.getElementById('Content'))}><i className='fa fa-circle-o'></i>Mapa Geral</a></li>}
                {this.habilitado(9, 5) && <li className="treeview"><a href="#"><i className='fa fa-circle-o'></i>Cadastros<span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
                  <ul className="treeview-menu">
                    <li><a href="#" onClick={() => ReactDOM.render(<LstDispositivos />, document.getElementById('Content'))}><i className='fa fa-circle-o'></i>Dispositivos</a></li>
                    <li><a href="#" onClick={() => ReactDOM.render(<CadastroGrupo />, document.getElementById('Content'))}><i className='fa fa-circle-o'></i>Grupos</a></li>
                  </ul>
                </li>}
                
              </ul>
            </li>
            }
            {this.habilitado(9, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i><span>Georreferenciamento</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(38, 5) && <li><a href="#" onClick={() => ReactDOM.render(<MapaGeorref />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i>Mapa georreferenciado</a></li>}
                {this.habilitado(39, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstClientes />, document.getElementById("Content"))}><i className="fa fa-building"></i>Equipe de Trabalho</a></li>}
              </ul>
            </li>
            }
            {this.habilitado(5, 5) && <li className="treeview"><a href="#"><i className="fa fa-edit"></i> <span>Sistema</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(37, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstFuncionalidades />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i>Funcionalidades</a></li>}
                {this.habilitado(36, 5) && <li className="treeview"><a href="#" ><i className="fa fa-building"></i>Clientes</a>
                  <ul className="treeview-menu">
                    {this.habilitado(40, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstClienteEmpresa />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i> Empresa</a></li>}
                    {this.habilitado(41, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstClienteMunicipio />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i> Município</a></li>}
                  </ul>
                </li>}
                {this.habilitado(6, 5) && <li><a href="#" onClick={() => ReactDOM.render(<LstUsuarios />, document.getElementById("Content"))}><i className="fa fa-users"></i>Usuários</a></li>}
                {this.habilitado(8, 5) && <li><a href="#"><i className="fa fa-circle-o"></i>Importação de dados</a></li>}
              </ul>
            </li>
            }
            {this.habilitado(10, 5) && <li className="treeview"><a href="#"><i className="fa fa-book"></i> <span>Relatórios</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
              <ul className="treeview-menu">
                {this.habilitado(10, 5) && <li><a href="#" onClick={() => ReactDOM.render(<RelatoriosOrdemServico />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i>Ordem serviços</a></li>}
                {this.habilitado(10, 5) && <li><a href="#" onClick={() => ReactDOM.render(<RelatorioMateriais />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i>Materiais</a></li>}
                {this.habilitado(10, 5) && <li><a href="#" onClick={() => ReactDOM.render(<RelatorioConsumoEnergia />, document.getElementById('Content'))}><i className="fa fa-circle-o"></i>Consumo de energia</a></li>}
              </ul>

            </li>
            }
                 {this.habilitado(1, 5) && <li className="treeview"><a href="#"><i className="fa fa-play"></i><span>Smart city</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
            <ul className="treeview-menu">
              {this.habilitado(2, 5) && <li><a href="#" /> </li>}
            </ul>
          </li>
          }
           {this.habilitado(1, 5) && <li className="treeview"><a href="#"><i className="fa fa-television"></i><span>Robô teleatendimento</span><span className="pull-right-container"><i className="fa fa-angle-left pull-right"></i></span></a>
            <ul className="treeview-menu">
              {this.habilitado(2, 5) && <li><a href="#" /> </li>}
            </ul>
          </li>
          }
          </ul>
     
        </section>
      </aside>
    )
  }

}
