import React from "react";

export default (props) => (

  <section className="content">
    <div className="error-page">
      
      <div className="error-content">
        <h3>  <i className="fa fa-warning text-yellow"></i> {props.titulo}</h3>
        <p>
         {props.mensagem}
          
        </p>
      </div>
    </div>
  </section>
)
