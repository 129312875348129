import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LstTecnicos from './lstTecnicos'
import { AxiosPost } from '../../../../lib/utils3'
import Config from '../../../../config'

export default class tecnico extends Component {

  state = {
            id: null,
            nome: '',
            contato: '',
            showModal: false
          }
  handleSubmit = this.handleSubmit.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)


  async handleSubmit(event) {
    event.preventDefault()
    let _acao = this.state.id == 0? 'insert.php': 'update.php'

    if (this.state.nome== "") {
      toast.warning('Informe o nome da Equipe.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
        })
    } else {
      await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/${_acao}`, 
                        JSON.stringify({
                            id: this.state.id,
                            nome: this.state.nome,
                            contato: this.state.contato
                        })
      )
      .then(async function (response) {
        toast.info('Equipe armazenada com sucesso!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstTecnicos />, document.getElementById('Content'))
          })
        
      })
      .catch(function (erro) {
        console.error(erro)
      })
    }
  }

  cancelaExcluir() {
    ReactDOM.render(<LstTecnicos />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
      await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/delete.php`, 
          JSON.stringify({ id: this.state.id })
      )
      .then(async function (response) {
        toast.info('Equipe removida.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstTecnicos />, document.getElementById('Content'))
          })
      })
      .catch(function (erro) {
        console.error(erro)
      })
  }

  async buscar(_id = 0) {
    if (_id != 0) {
      this.setState({
        id: this.props.tecnicoData.id,
        nome: this.props.tecnicoData.nome,
        contato: this.props.tecnicoData.contato
     })
    }
    //   await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`,
    //                   JSON.stringify({ id: _id })
    //   )
    //   .then(async function (response) {
    //         response.data.map(t =>
    //           this.setState({
    //                           id: _id,
    //                           nome: t.nome,
    //                           contato: t.contato
    //                        })
    //         )
    //   }.bind(this))
    //   .catch(erro => console.error(erro))
    // }
  }

  componentDidMount() {
    this.buscar(this.props.id)
    
    this.setState({
                    showModal: this.props.action == "delete",
                    id: this.props.id
                 })
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal show={this.state.showModal} containerStyle={{background: 'Transparent'}} transitionSpeed={100}>
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir essa Equipe?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  id="btnExcluir" 
                  onClick={this.confirmaExcluir} 
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={this.cancelaExcluir} 
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <form onSubmit={this.handleSubmit}>
          <section className="content-header">
            <h1>
              Técnico
              <small>Cadastro</small>
            </h1>
            <ol className="breadcrumb">
              <li>
              <button onClick={this.cancelaExcluir} className="btn btn-danger btn-flat">Voltar</button>
              <button type="submit" className="btn btn-info btn-flat" >Salvar</button>  
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Sobre a Equipe</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-10">
                    <div className="form-group">
                      <label htmlFor="nome">Técnicos</label>
                      <input 
                        type="text" 
                        className="form-control"
                        placeholder=""
                        value={ this.state.nome==null? '' : this.state.nome } 
                        onChange={event => this.setState({nome: event.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-8">
                    <div className="form-group">
                      <label htmlFor="funcao">Contato</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="contato"
                        placeholder=""
                        value={ this.state.contato==null? '' : this.state.contato } 
                        onChange={event => this.setState({contato: event.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">      
              </div>
            </div>
          </section>
        </form>
      </>
    )
  }
}