import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Config from '../../../config'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import LstDistritos from './lstDistritos'

const animatedComponents = makeAnimated()

export default class distrito extends Component {

  state={
          id: this.props.id,
          nome: "",
          lstBairros: null,
          lstBairrosSelecionados: [],
          lstEquipe: [],
          equipe: null,
          showModal: false
        }
  handleSubmit = this.handleSubmit.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)

  async handleSubmit(event) {
    event.preventDefault()

    let _acao = this.state.id==0 ? "/php/distrito/insert.php" : "/php/distrito/update.php"

    let lista = []
    let listaEquipe = []
    this.state.lstBairrosSelecionados.map(item => lista.push(item.bairro))
    this.state.equipe.map(item => listaEquipe.push(item.value))
    await AxiosPost(`${Config.dominio}${_acao}`, 
      JSON.stringify({
                        id: this.state.id,
                        nome: this.state.nome,
                        equipes: listaEquipe.join(),
                        bairros: lista.join()
                    })
    )
    .then(async function (response) {
      toast.info('Distrito registrado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => ReactDOM.render(<LstDistritos />, document.getElementById('Content'))
      })
    })
    .catch(erro => console.log(erro))
  }

  cancelaExcluir() {
    ReactDOM.render(<LstDistritos />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
      await AxiosPost(`${Config.dominio}/php/distrito/delete.php`, 
                        JSON.stringify({ id: this.state.id,
                                         local: sessionStorage.getItem('local'),
                                         chaveMunicipio: usuario().municipio.chave
                                      })
      )
      .then(async response => 
        toast.info('Distrito removido!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => ReactDOM.render(<LstDistritos />, document.getElementById('Content'))}
                  )
      )
      .catch(erro => console.log(erro))
  }

  selecionarBairros(e) {
    e.preventDefault()
    let lst= this.state.lstBairrosSelecionados
    let lst2 = this.state.lstBairros
    let lista = document.getElementById("naoSelecionados").selectedOptions
    for(let i=0;i<lista.length;i++){
      lst.push(this.state.lstBairros.find(item => item.bairro==lista[i].value))
      lst2.splice(lst2.findIndex(item => item.bairro==lista[i].value), 1)
    }
    this.setState({lstBairrosSelecionados: lst})
    this.setState({lstBairros: lst2})
  }

  desselecionarBairros(e) {
    e.preventDefault()
    let lst2= this.state.lstBairrosSelecionados
    let lst = this.state.lstBairros
    let lista = document.getElementById("selecionados").selectedOptions
    for(let i=0;i<lista.length;i++){
      lst.push(this.state.lstBairrosSelecionados.find(item => item.bairro==lista[i].value))
      lst2.splice(lst2.findIndex(item => item.bairro==lista[i].value), 1)
    }
    this.setState({lstBairrosSelecionados: lst2})
    this.setState({lstBairros: lst})
  }

  async buscarBairros() {
    await AxiosPost(`${Config.dominio}/php/bairro_list.php`,
                      JSON.stringify({
                                      local: sessionStorage.getItem('local'),
                                      chaveMunicipio: usuario().municipio.chave
                                    })
    )
    .then(response =>this.setState({lstBairros: response.data.filter(item=> item.idDistrito==null)}))
    .catch(erro => console.log(erro))
  }

  async listarEquipe() {
    await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`,
                    JSON.stringify({
                      a:1
                    })
    )
    .then( async response => {
      let lista = []
      response.data.map(item => lista.push({ value: item.id, label: item.nome}))
      this.setState({lstEquipe: lista})
    })
    .catch(erro => console.log(erro))
  }

  async buscar(id) {
    if (id != 0) {
      await AxiosPost(`${Config.dominio}/php/distrito/list.php`,
                        JSON.stringify({ id: id })
      )
      .then (async response => this.setState({ nome: response.data[0].distrito }))
      .then(async () => {
        await AxiosPost(`${Config.dominio}/php/bairro_list.php`,
                          JSON.stringify({ a:1 })
        )
        .then(res => {
          let listaSelecionados = res.data.filter(item => item.idDistrito == id)
          let listaBairros = res.data.filter(item=> item.idDistrito==null)
          this.setState({
                          lstBairrosSelecionados: listaSelecionados,
                          lstBairros: listaBairros
                       })
        })
        .catch(erro => console.log(erro))

        await AxiosPost(`${Config.dominio}/php/distrito/equipe_list.php`,
                          JSON.stringify({ idDistrito: id })
        )
        .then(async res => {
          let lista=[]
          res.data.map(item => lista.push(this.state.lstEquipe.find(i => i.value == item.idTecnico)))
          this.setState({ equipe: lista })
        })
        .catch(erro => console.log(erro))
      })
      .catch(erro => console.log(erro))
    }
  }

  componentWillMount() {
    this.listarEquipe()
  }

  componentDidMount(){
    this.buscarBairros()
    if (this.props.id !=0) this.buscar(this.props.id)
    this.setState({showModal: this.props.action == 'delete'})
  }

  render() {
    return (
      <>
      <ToastContainer />
        <Modal 
            show={this.state.showModal} 
            containerStyle={{background: 'Transparent'}} 
            transitionSpeed={400}
          >
            <div className="box box-danger">
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-12">
                  <h3>Confirma excluir esse Distrito?</h3>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <center>
                  <button 
                    id="btnExcluir" 
                    onClick={this.confirmaExcluir} 
                    className="btn btn-danger btn-flat"
                  >
                    Excluir
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    onClick={this.cancelaExcluir} 
                    className="btn btn-warning btn-flat"
                  >
                    Cancelar
                  </button>
                </center>
              </div>
            </div>
          </Modal>
          
        <form onSubmit={this.handleSubmit}>
          <section className="content-header">
            <h1>
              Distrito 
              <small>Cadastro</small>
            </h1>
            <ol className="breadcrumb">
              <li>
                <button 
                  className="btn btn-danger btn-flat" 
                  onClick={() => ReactDOM.render(<LstDistritos />, document.getElementById('Content'))}>
                  Voltar
                </button>&nbsp;&nbsp;<button id="btnSalvar" type="submit" className="btn btn-info btn-flat" >Salvar</button>  
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="col-md-4">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="nome">Nome</label>
                        <input 
                          type="text" 
                          value={this.state.nome} 
                          className="form-control" 
                          placeholder="" 
                          onChange={e => this.setState({nome: e.target.value})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Equipe Associada</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="equipe">Equipe</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={this.state.lstEquipe}
                          style={{width: '100%'}}
                          value={this.state.equipe}
                          onChange={selectedOption => this.setState({equipe: selectedOption})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="box box-solid">
                <div className="box-header"> 
                  <div className="pull-right box-tools">
                  </div>
                  <h3 className="box-title">Área de abrangência</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Bairros não atribuídos a distritos</label>
                    <select multiple className="form-control" id="naoSelecionados">
                      {
                        this.state.lstBairros &&
                        this.state.lstBairros.sort((a,b)=> a.nome < b.nome).map((item, index)=> <option key={index} value={item.bairro}>{`${item.bairro} (${item.total_pontos})`}</option>)
                      }
                    </select>
                  </div>
                  <div className="form-group">
                    <center>
                      <button className="btn btn-success" onClick={e=>this.selecionarBairros(e)}>Incluir</button>
                      &nbsp;<button className="btn btn-warning" onClick={e=>this.desselecionarBairros(e)}>Remover</button>
                    </center>
                  </div>
                  <div className="form-group">
                    <label>Bairros atribuídos a este distrito</label>
                    <select multiple className="form-control" id="selecionados">
                      {
                        this.state.lstBairrosSelecionados &&
                        this.state.lstBairrosSelecionados.sort((a,b)=> a.nome < b.nome).map((item, index)=> <option key={index} value={item.bairro}>{`${item.bairro} (${item.total_pontos})`}</option>)
                      }
                    </select>
                      Total de pontos: &nbsp;
                      {
                        this.state.lstBairrosSelecionados &&
                        this.state.lstBairrosSelecionados.reduce(function (total, bs){return total+parseInt(bs.total_pontos)}, 0)
                      }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </>
    )
  }
}