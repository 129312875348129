import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import EstoqueCss from './estoque.css'
import LstEntrada from './lstEntrada'

const cssToast = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export default class entradaEstoque extends Component {

  state = {
    showModal: false,
  }

  render() {
    return(
      <>
        <ToastContainer />
        <section className="content-header">
          <h1>
          Estoque
            <small>Entrada</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button 
                type="button" 
                className="btn btn-danger btn-flat" 
                onClick={() => ReactDOM.render(<LstEntrada />, document.getElementById('Content'))}
              >Voltar</button>&nbsp;&nbsp;
            </li>
          </ol>
        </section>
        <section className="content">
        <div className="box box-success">
            <div className="box-header with-border">
              <h3 className="box-title">Informações da nota</h3>
            </div>
            <div className="box-body">
              <div className="row">
                <div className="col-xs-2">
                  <div className="form-group">
                    <label htmlFor="Grupo">Documento</label>
                    <div>
                      {this.props.id}
                    </div>
                  </div>
                </div>
                <div className="col-xs-8">
                  <div className="form-group">
                    <label htmlFor="Grupo">Fornecedor</label>
                    <div>
                      {this.props.fornecedor}
                    </div>
                  </div>
                </div>
                <div className="col-xs-2">
                  <div className="form-group">
                    <label htmlFor="Grupo">Emissão</label>
                    <div>
                      {this.props.dataemissao}
                    </div>
                  </div>
                </div>
                <div className="col-xs-2">
                  <div className="form-group">
                    <label htmlFor="quantidade">Total de Materiais</label>
                    <div>
                      {this.props.total}
                    </div>
                  </div>
                </div>
                <div className="col-xs-10">
                  <div className="form-group">
                    <label htmlFor="quantidade">Valor total dos Materiais</label>
                    <div>
                      R$ {Number(this.props.valor).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box box-success">
            <div className="box-header with-border">
              <h3 className="box-title">Itens da nota</h3>
            </div>
            <div className="box-body">

              <table id="entrada-table-view" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="whiteSpace textAlignCenter defaultWidth">&nbsp;</th>
                    <th className="whiteSpace textAlignCenter defaultWidth">Código</th>
                    <th className="whiteSpace textAlignCenter defaultWidth">Tipo</th>
                    <th className="whiteSpace"> Material</th>
                    <th className= "whiteSpace textAlignCenter defaultWidth">Qtd</th>
                    <th className= "whiteSpace textAlignRight defaultWidth">Valor unit.</th>
                    <th className= "whiteSpace textAlignRight defaultWidth">Total</th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.props.materiais.map((item, index) => 
                    <tr key={index}>
                      <td style={{textAlign: "center"}}>{index+1}</td>
                      <td>{item.id}</td>
                      <td></td>
                      <td>{item.material}</td>
                      <td className="textAlignRight">{item.quantidade}</td>
                      <td className="textAlignRight">{(1*item.valor).toFixed(2)}</td>
                      <td className="textAlignRight">{(item.valor * item.quantidade).toFixed(2)}</td>
                    </tr>
                  )
                }
                </tbody>
              </table>
            </div>
            <div className="box-footer">      
            </div>
          </div>
        </section>
      </>
    )
  }
}