import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import ClipLoader from 'react-spinners/ClipLoader'
import GrupoMateriais from './grupoMateriais'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export default class lstGrupoMateriais extends Component { 

  state = { result: null }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 )
        return (
          <center><h2>Não há Grupos de Materiais cadastrados.</h2></center>
        )
        
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{whiteSpace: "nowrap", verticalAlign: "middle"}}>Descrição</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap"}}><span style={{ marginLeft: `${item.level*4}rem` }}>{item.vgrupo_desc} {item.vdados_ponto == 1? "*": ""}</span></td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<GrupoMateriais id={`${item.vgrupo_id}`} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<GrupoMateriais id={`${item.vgrupo_id}`} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>Descrição</th>
            <th style={{width:"85px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }

  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/grupoMateriais_list.php`,
                      JSON.stringify({ a: 1 })
    )
    .then( async (response) => this.setState({result: response.data})) 
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Grupos de Materiais
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<GrupoMateriais id={0} />, document.getElementById('Content'))}> Novo Grupo de Materiais</button>
        </ol>
        </section>
        <section className="content">
        <div className="box">
            <div className="box-header">
              <div className="form-group">
              </div>
              <div className="box-tools">
              </div>
            </div>
            <div className="box-body">
            <ClipLoader
                  css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                  color={"#123abc"}
                  loading={!this.state.result}
              />
              Os materiais dos grupo_materiais marcados com * farão parte da relação de materiais relevantes do ponto.

              {this.relatorio()}
            </div>
          </div>
        </section>
      </>
    )
  }
}