import React, { Component } from 'react'

export default class lstEquipe extends Component {


  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Equipe de trabalho
            <small>Listagem geral</small>
          </h1>
        </section>
        <section className="content">
        <div className="box">
            <div className="box-header">
            </div>
            <div className="box-body">
              <center><h1>No menu ao lado, escolha Técnicos ou Veículos.</h1></center>
            </div>
          </div>
        </section>
      </>
    )
  }
}