import "leaflet/dist/leaflet.css";
import React, { useState } from "react"
import MapaGrupo from "./MapaGrupo";
import { useLeafletContext } from "@react-leaflet/core";
const DrawableMap = (props) => {
  const [mapInstance, setMapInstance] = useState(null);

  return (
    <div>
      {/* {console.log("drawable", props.pontosArea)} */}
      {/* {console.log("drawable mark", props.pontosDispositivos)} */}
      <MapaGrupo setMapInstance={setMapInstance} setPontosArea={props.setPontosArea} pontosDispositivos={props.pontosDispositivos} pontosArea={props.pontosArea}/>
    </div>
  );
}

export default DrawableMap;