import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import LstGrupoMateriais from './lstGrupoMateriais'
import Config from '../../../config'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AxiosPost } from '../../../lib/utils3' 

export default class grupoMateriais extends Component {

  state={
          id: 0,
          nome: '',
          grupoSuperior: 0,
          lstGrupoMateriais: [],
          dadosPonto: 0,
          showModal: false
        }
  handleSubmit = this.handleSubmit.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)


  async handleSubmit(e) {
    e.preventDefault()
    if (this.state.nome.trim() ==''){
      toast.error('Informe o nome do Grupo de Materiais.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
      return
    }
    
    let _acao = this.state.id == 0 ? `grupoMateriais_insert.php` : `grupoMateriais_update.php`

    await AxiosPost(`${Config.dominio}/php/${_acao}`,
                     JSON.stringify({
                                      id: this.state.id,
                                      nome: this.state.nome,
                                      grupoSuperior: this.state.grupoSuperior,
                                      dadosPonto: this.state.dadosPonto
                                    })
                    ) 
    .then(async response =>  
      toast.success('Informações atualizadas no sistema.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: ReactDOM.render(<LstGrupoMateriais />, document.getElementById('Content'))
        })
    )
    .catch(erro => console.log(erro))
  }

  cancelaExcluir() {
    ReactDOM.render(<LstGrupoMateriais />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
      await AxiosPost(`${Config.dominio}/php/grupoMateriais_delete.php`, JSON.stringify({ id: this.state.id }))
      .then(async response => ReactDOM.render(<LstGrupoMateriais />, document.getElementById('Content')))
      .catch(erro =>console.log(erro))
  }

  async componentDidMount(){
    this.setState({id: this.props.id})
    await AxiosPost(`${Config.dominio}/php/grupoMateriais_list.php`, JSON.stringify({ a: 1 }))
    .then(async (response) => { 
      let nome = ""
      let grupoSuperior = 0
      let dadosPonto = 0
      let item = response.data.filter(a => a.vgrupo_id == this.props.id)[0]
      if (item) {
        nome = item.vgrupo_desc
        grupoSuperior = item.vgrupo_superior
        dadosPonto = item.vdados_ponto
      }
      this.setState({nome: nome,
                     grupoSuperior: grupoSuperior,
                     lstGrupoMateriais: response.data,
                     dadosPonto: dadosPonto
                    })

    if (this.props.action == "delete") this.setState({showModal: true})
    })
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <Modal 
        show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir esse grupo de materiais?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  id="btnExcluir" 
                  onClick={this.confirmaExcluir} 
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={this.cancelaExcluir} 
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <ToastContainer />
        <form onSubmit={this.handleSubmit}>
          <section className="content-header">
            <h1>
              Grupo de Materiais
              <small>Cadastro</small>
            </h1>
            <ol className="breadcrumb">
              <li>
                <button className="btn btn-danger"  onClick={()=>ReactDOM.render(<LstGrupoMateriais />, document.getElementById('Content'))}>Voltar</button>
                <button className="btn btn-primary" onClick={this.salvar} >Salvar</button>
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="col-md-12">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-8">
                      <div className="form-group">
                        <label htmlFor="descricao">Descrição</label>
                        <input 
                          type="text" 
                          value={this.state.nome} 
                          className="form-control" 
                          placeholder="" 
                          onChange={event => this.setState({nome: event.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="superior">Este grupo é subgrupo de:</label>
                        <select
                          value={this.state.grupoSuperior || 0 }
                          className="form-control" 
                          placeholder="" 
                          onChange={event => this.setState({grupoSuperior: event.target.value})}
                        >
                          <option value="0" key="-1">Grupo Principal</option>
                          {
                            this.state.lstGrupoMateriais && 
                              this.state.lstGrupoMateriais.filter(i => i.vgrupo_id !=this.state.id).map((item, index) => <option key={index} value={item.vgrupo_id}>{String('.').repeat(item.level*4)}{item.vgrupo_desc}</option>)
                          }
                        </select> 
                      </div>
                      <div className="form-group">
                        <div className="mailbox-controls">
                          <input 
                            type="checkbox" 
                            checked = {this.state.dadosPonto == 1}
                            onChange={() => this.setState({dadosPonto: this.state.dadosPonto==0?1:0})}
                          /> Os materiais desse grupo farão parte da relação de materiais relevantes do ponto
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
          </section>
        </form>
      </>
    )
  }
}