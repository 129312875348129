import React from "react";

const RowsTable = ({classe, datahora_atual, dataLine, dataDiff = () => {}, format = () => {}}) => (
  <td className={classe}>
    {dataDiff(format(datahora_atual)) === 0 ? (
      <p className="text-green">{dataLine}</p>
    ) : dataDiff(format(datahora_atual)) ===
      1 ? (
      <p className="text-yellow">{dataLine}</p>
    ) : (
      <p className="text-red">{dataLine}</p>
    )}
  </td>
);
export default RowsTable;
