import React, { Component } from 'react'
import Axios from 'axios'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { addDays, format } from 'date-fns'
import { usuario } from '../../../../lib/utils2'

import Config from '../../../../config'

export default class callcenter extends Component {

  state = {result: null,
                  de: null,
                  ate: null
                 }
  
  async componentWillMount() {
    let data=[]
    await Axios.post(`${Config.dominio}/php/os/ordemServico_estatistica.php`, 
                    JSON.stringify({
                                    periodo: 30,
                                    local: sessionStorage.getItem('local'),
                                    chaveMunicipio: usuario().municipio.chave
                    })
    )
    .then(async (response) => {
      for (let i=0;i < 24; i++)
        data.push({ hora: i,
                    name: ("0"+i).substr(-2)+":00 - "+("0"+i).substr(-2)+":59", 
                    Atendimentos: 0
                 })
      
      response.data.map((item) => data.find(a => a.hora == item.hora).Atendimentos = parseInt(item.atendimentos) )
      
      this.setState({
                      result: data, 
                      ate: new Date((new Date()).toDateString()),
                      de: addDays(new Date((new Date()).toDateString()), -30)
                    })
    
    })
    .catch(erro => console.log(erro))
  }

  render () {
    let soma = 0
    this.state.result &&  this.state.result.map(item => soma += item.Atendimentos) 

    return (
      <>
      <section className="content-header">
        <h1>
          Call Center
          <small></small>
        </h1>
      </section>
      <section className="content">
        <center>  
          <h1>
            Atendimentos
          </h1>
          <h3>Período de {this.state.de && format(this.state.de, 'dd/MM/yyyy')} a {this.state.ate && format(this.state.ate, 'dd/MM/yyyy')}</h3>
        </center>
        <p />
        <p />
        <p />
        <p />
        <center>
          <table>
            <tbody>
              <tr>
                <td>
                  <BarChart 
                    width={800} 
                    height={200} 
                    data={this.state.result} 
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Atendimentos" fill="#8884d8" />
                  </BarChart>
                </td>
                <td align="center">
                  <h3>Total no período</h3><br />
                  <h2>{soma}</h2>
                </td>
              </tr>
            </tbody>
          </table>
        </center>
      </section>
      </>
    )
  }


}
