import overvoltageLamp from '../../../assets/lamp_posts/tensao_alta.png'
import accessOnLamp from '../../../assets/lamp_posts/luz_acesa.png'
import accessOffLamp from '../../../assets/lamp_posts/luz_apagada.png'
import undervoltageLamp from '../../../assets/lamp_posts/tensao_baixa.png'
import noCommunicationIcon from '../../../assets/lamp_posts/no_comunication.png'
import flashingLamp from '../../../assets/lamp_posts/luz_piscando.png'
import normalIcon from '../dashboard/iconMap.png'
import totalDevicesIcon from '../../../assets/lamp_posts/total_devices.png'
export const TYPE_ALERTS = {
  normal: 'normal',
  undervoltage: 'undervoltage',
  overvoltage: 'overvoltage',
  access_off: 'access_off',
  no_communication: 'no_communication',
  access_on: 'access_on',
  flashing: 'flashing',
  total_devices: 'total_devices',
  wiped_outside_hour: 'wiped_outside_hour',
  lit_outside_hour: 'lit_outside_hour'
}

export const ALERTS_ICONS = {
  normal: normalIcon,
  undervoltage: undervoltageLamp,
  overvoltage: overvoltageLamp,
  wiped_outside_hour: accessOffLamp,
  access_off: accessOffLamp,
  no_communication: noCommunicationIcon,
  access_on: accessOnLamp,
  lit_outside_hour: accessOnLamp,
  flashing: flashingLamp,
  total_devices: totalDevicesIcon
}

export const ALERTS_MESSAGE = {
  normal: 'Normalidade',
  undervoltage: "Subtensão",
  overvoltage: "Sobretensão",
  wiped_outside_hour: 'Apagado fora de hora',
  access_off: "Apagados",
  no_communication: "Sem com.",
  access_on: "Acesos",
  lit_outside_hour: 'Aceso fora de hora',
  flashing: 'Piscando',
  total_devices: 'Disp.'
}

export const TIPOS_AGENDAMENTOS = {
  DIARIO: 'daily',
  EVENTO: 'event'
}

export const TIPOS_COMANDOS = {
  control_rele: 'Controle',
  get_status: 'Atualizar estado',
  schedule_device: 'Agendamento'
}