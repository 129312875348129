import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import Modal from 'simple-react-modal'
import {
  MapContainer, TileLayer, LayersControl,
  LayerGroup, FeatureGroup, Circle, Marker, Popup
} from 'react-leaflet'
import L from 'leaflet'
import "leaflet/dist/leaflet.css"
import { usuario, factoryLamp } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export default class dashboardMapa2 extends Component {

  state = {
    resultTodos: [],
    resultAbertos: [],
    resultAutorizados: [],
    resultDistribuidos: [],
    totalPontos: 0,
    showModal: false,
    showModalCarga: false,
    pontoSelecionado: null,
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude
  }


  async componentWillMount() {
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/totalPontos.php`,
      JSON.stringify({ a: 1 })
    )
      .then(async (response) => {
        console.log(response.data[0].total)
        this.setState({ totalPontos: response.data[0].total })
      })
      .catch(erro => console.log(erro))

  }

  async buscarPontosCamada(_status_os, _totalPontos) {
    let lista = []
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
      JSON.stringify({
        status_os: _status_os,
        paginacao: _totalPontos,
        pagina: 1
      })
    )
      .then(async (response) => lista = response.data)
      .catch(erro => console.log(erro))
    console.log('filtro: ', _status_os, 'total encontrado: ', lista.length)
    return lista
  }

  async listaPontos() {
    let lista = []
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
      JSON.stringify({

      })
    )
      .then(async (response) => {
        this.setState({ resultTodos: response.data })
        //console.log(response);
      })
      .catch(erro => console.log(erro))
  }

  async componentDidMount() {
    // this.setState({resultAbertos: await this.buscarPontosCamada(1, this.state.totalPontos)})
    // this.setState({resultAutorizados: await this.buscarPontosCamada(2, this.state.totalPontos)})
    // this.setState({resultDistribuidos: await this.buscarPontosCamada(3, this.state.totalPontos)})
    //this.setState({resultTodos: await this.buscarPontosCamada(0, this.state.totalPontos)})
    this.listaPontos();
  }

  render() {
    let center = [this.state.latitude, this.state.longitude]
    return (
      <>
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={100}
          onClose={() => this.setState({ showModal: false })}
        >
          <div className="box box-info">
            <div className="box-header">
              Dados do Ponto
            </div>
            <div className="box-body">
              Endereço: <br />
              Lâmpada: LED (80W) <br />
              Última OS em: (Sem registro)
            </div>
            <div className="box-footer">
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showModalCarga}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={100}>
          <center>
            <ClipLoader className="override" color={"#123abc"} loading={true} />
          </center>
        </Modal>
        <section className="content-header">
          <h1>
            Mapa Geral
            <small>Parque de Iluminação Pública
            </small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button
                type="button"
                className="btn btn-default"
                data-toggle="control-sidebar"
                data-target="#menuMapa"
              >
                <i className="fa fa-sliders"></i>
              </button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="box box-info">
            <div className="box-header">
              <div className="row">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-map-marker"></i>&nbsp;
                <h3 className="box-title">Localização</h3>
              </div>
            </div>
            <div className="box-body" >
              <div className="form-group" style={{ height: '460px' }}>
                <MapContainer
                  center={center}
                  zoom={13}
                  scrollWheelZoom={false}
                  style={{ height: '480px' }}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  <LayersControl position="topright">
                    <LayersControl.Overlay name="Todos os Pontos">
                      <LayerGroup>
                        {
                          this.state.resultTodos.length > 0 && this.state.resultTodos.map((ponto, index) =>
                            <Marker
                              key={index}
                              position={ponto.latitude && ponto.longitude != null ? [ponto.latitude, ponto.longitude]: [0, 0]}

                            >
                              <Popup>
                                <p className="popup">{ponto.barramento}</p>
                              </Popup>
                            </Marker>
                          )
                        }
                      </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Abertas">
                      <LayerGroup>
                        {
                          this.state.resultAbertos.length > 0 && this.state.resultAbertos.map((ponto, index) =>
                            <Marker
                              key={index}
                              position={[ponto.latitude, ponto.longitude]}
                              icon={new L.Icon({
                                iconUrl: factoryLamp(1, ponto.telegestao),
                                iconAnchor: null,
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null,
                                iconSize: new L.Point(10, 15),
                                className: 'leaflet-div-icon'
                              })}
                            >
                              <Popup>
                                <p className="popup">{ponto.barramento}</p>
                              </Popup>
                            </Marker>
                          )
                        }
                      </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Autorizadas">
                      <LayerGroup>
                        {
                          this.state.resultAutorizados.length > 0 && this.state.resultAutorizados.map((ponto, index) =>
                            <Marker
                              key={index}
                              position={[ponto.latitude, ponto.longitude]}
                              icon={new L.Icon({
                                iconUrl: factoryLamp(2, ponto.telegestao),
                                iconAnchor: null,
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null,
                                iconSize: new L.Point(10, 15),
                                className: 'leaflet-div-icon'
                              })}
                            >
                              <Popup>
                                <p className="popup">{ponto.barramento}</p>
                              </Popup>
                            </Marker>
                          )
                        }
                      </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Distribuídas">
                      <LayerGroup>
                        {
                          this.state.resultDistribuidos.length > 0 && this.state.resultDistribuidos.map((ponto, index) =>
                            <Marker
                              key={index}
                              position={[ponto.latitude, ponto.longitude]}
                              icon={new L.Icon({
                                iconUrl: factoryLamp(3, ponto.telegestao),
                                iconAnchor: null,
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null,
                                iconSize: new L.Point(10, 15),
                                className: 'leaflet-div-icon'
                              })}
                            >
                              <Popup>
                                <p className="popup">{ponto.barramento}</p>
                              </Popup>
                            </Marker>
                          )
                        }
                      </LayerGroup>
                    </LayersControl.Overlay>
                  </LayersControl>
                </MapContainer>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

} 