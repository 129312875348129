import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'

import LstTransformadores from './lstTransformadores'
import TransformadorMapa from './transformadorMapa'
import Config from '../../../config'

export default class transformador extends Component {

  state={
          latitude: -8.050000,
          longitude: 	-34.968143000561334
        }

  async salvar() {
    let ponto = {
                  barramento: document.getElementById("barramento").value,
                  tombamento: document.getElementById("tombamento").value,
                  longitude: document.getElementById("longitude").value,
                  latitude: document.getElementById("latitude").value,
                  cep: document.getElementById("cep").value,
                  logradouro: document.getElementById("logradouro").value,
                  bairro: document.getElementById("bairro").value,
                  uf: document.getElementById("uf").value,
                  municipio: document.getElementById("municipio").value,
                  potencia: document.getElementById("potencia").value,
                  compartilhado: document.getElementById("compartilhado").checked? 1:0
                }

    await Axios({
      method: 'POST',
      url: `/php/transformador_insert.php`,
      headers: {'Content-Type': 'application/json',},
      data: ponto
    })
    .then(async function (response) { 
        console.log(response.data)
        ReactDOM.render(<LstTransformadores />, document.getElementById('Content'))
      })
      .catch(function (erro) { 
        console.log(erro) 
      })
  }

  async MontaCidades(uf, _cidade="") {
    await Axios.get(`php/municipios_list.php?uf=${uf}`)
    .then(async function (response) {
        if (response.status == 200 && response.data != null) {
          document.getElementById("cidade").options.length=1
          response.data.forEach(function(item) {
            var x = document.createElement("OPTION")
            x.setAttribute("value", item.geocodigo)
            x.appendChild(document.createTextNode(item.municipio))
            document.getElementById("cidade").appendChild(x)
          })
          document.getElementById("cidade").value = _cidade
        }
        else {
          console.log("Erro na montagem das cidades.");
        }
      })
      .catch(function (erro) {
        console.log(erro);
      });
  }

  async MontaUFs(_uf="") {
    await Axios.get(`php/uf_list.php`)
      .then(async function (response) {
          if (response.status == 200 && response.data != null) {
            response.data.forEach(function(item) {
              var x = document.createElement("OPTION")
              x.setAttribute("value", item.geocodigo)
              x.appendChild(document.createTextNode(item.sigla))
              document.getElementById("uf").appendChild(x)
            })
          }
          document.getElementById("uf").value = _uf.substring(0,2)
        })
        .catch(function (erro) {
          console.log(erro);
        });
  }

  async ConsultarCEP() {
    let cepEncontrado = {}
    let _cep = document.getElementById("cep").value.replace(/\D/g, '')
    if (_cep != "") {
      let validacep = /^[0-9]{8}$/
      if(validacep.test(_cep)) {
        await Axios.get(`https://viacep.com.br/ws/${_cep}/json/`)
        .then(async function (response) {
            if (response.status == 200 && response.data != null) {
              if (!response.data.erro) {
                cepEncontrado = response.data
                document.getElementById("logradouro").value = cepEncontrado.logradouro
                document.getElementById("uf").value = cepEncontrado.ibge.substring(0,2)
                await this.MontaCidades(cepEncontrado.ibge, cepEncontrado.ibge)
                document.getElementById("bairro").text = cepEncontrado.bairro
              } else {
                alert("CEP não encontrado.")
              }
            }
            else {
              alert("Erro.");
            }
          })
          .catch(function (erro) {
            console.log(erro);
          })
  
      }
      else {
          alert("Formato de CEP inválido.");
      }
    }
  }

  async ConsultarGeo() {
    // let localEncontrado = {}
    //   await Axios.get(`https://cors-anywhere.herokuapp.com/https://api.mapbox.com/geocoding/v5/mapbox.places/${markers[0][1]},${markers[0][0]}.json?limit=1&access_token=pk.eyJ1Ijoid2lsc29uYXJhdWpvIiwiYSI6ImNrbHptOHM1YzBqeDcyd3E3Nm9wZDFrY2QifQ.F7wRsrgwobsbKdfn3fESeg`)
    //     .then(async function (response) {
    //       if (response.status == 200 && response.data != null) {
    //         if (!response.data.erro) {
    //           localEncontrado = response.data
    //           document.getElementById("logradouro").value = localEncontrado.features[0].text
    //           document.getElementById("uf").value = localEncontrado.features[0].context[3][0].short_code.slice(-2)
    //           document.getElementById("cidade").value = localEncontrado.features[0].context[2][0].text
    //           document.getElementById("bairro").text = localEncontrado.features[0].context[0][0].text
    //         } else {
    //           alert("Endereço não encontrado.")
    //         }
    //       }
    //       else {
    //         alert("Erro.");
    //       }
    //     })
    //     .catch(function (erro) {
    //       console.log(erro);
    //     })
  }

  render() {
    return (
      <form>
        <section className="content-header">
          <h1>
            Transformadores
            <small>Cadastro</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button className="btn btn-primary" onClick={this.salvar} >Salvar</button>
            </li>
          </ol>
        </section>
        <section className="content">
            <div className="col-md-6">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label htmlFor="barramento">Barramento</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="barramento" 
                          placeholder="" 
                        />
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label htmlFor="tombamento">Tombamento</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="tombamento" 
                          placeholder="" 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label htmlFor="longitude">Longitude</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="longitude" 
                          placeholder="" 
                        />
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label htmlFor="latitude">Latitude</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="latitude" 
                          placeholder="" 
                        />
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="form-group" style={{textAlign: 'center'}}>
                        <br />
                        <button 
                          type="button" 
                          className="btn btn-info btn-flat" 
                          onClick={this.ConsultarGeo}>
                            Buscar local
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                </div>
              </div>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Endereço</h3>
                </div>
                  <div className="box-body">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xs-5">
                        <label htmlFor="cep">CEP</label>
                        <div className="input-group input-group-sm">
                          <input type="text" id="cep" className="form-control" />
                            <span className="input-group-btn">
                              <button type="button" className="btn btn-info btn-flat" onClick={() => this.ConsultarCEP}>
                                Consultar
                              </button>
                            </span>
                        </div>
                      </div>  
                    </div> 
                    </div>
                    <div className="row">
                      <div className="col-xs-2">
                        <div className="form-group">
                          <label>UF</label>
                          <select 
                            id="uf" 
                            className="form-control select2" 
                            style={{width: '100%'}} 
                            onChange={event => this.MontaCidades(event.target.value)}>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-10">
                        <div className="form-group">
                          <label>Cidade</label>
                          <select id="cidade" className="form-control select2" style={{width: '100%'}}>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Região</label>
                      <select id="regiao" className="form-control select2" style={{width: '100%'}}>
                      </select>
                      <div style={{width: '100%', textAlign: 'right'}}><a href='#'>Editar Região</a></div>
                    </div>
                    <div className="form-group">
                      <label>Bairro</label>
                      <select className="form-control select2" style={{width: '100%'}}></select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="logradouro">Logradouro</label>
                      <input type="text" className="form-control" id="logradouro" placeholder="" />
                    </div>
                  </div>

                  <div className="box-footer">
                  </div>
              </div>
              <div className="box box-danger">
                <div className="box-header with-border">
                  <h3 className="box-title">Sobre o Transformador</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="potencia">Potência</label>
                        <input type="text" className="form-control" id="potencia" placeholder="Watts" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="checkbox"><label><input type="checkbox" /> Compartilhado</label></div>
                  </div>
                </div>
            </div>
            </div>
            <div className="col-md-6">
              <div className="box box-info">
                <div className="box-header">
                  <div className="pull-right box-tools">
                  </div>
                  <i className="fa fa-map-marker"></i>
                  <h3 className="box-title">Localização</h3>
                </div>
                <div className="box-body" >
                  <div className="form-group" style={{height: '280px'}}>
                    <div
                      id="Mapa"
                      google={this.props.google}
                      zoom={16}
                      containerStyle = {{position: 'relative', width: '100%', height: '100%' }}
                      initialCenter={{ lat: -8.050000, lng: -34.900002 }}
                      center={{ lat: this.state.latitude, lng: this.state.longitude }}
                      onClick={(t, map, coord) => {
                                          const {latLng} = coord
                                          document.getElementById("latitude").value = latLng.lat()
                                          document.getElementById("longitude").value = latLng.lng()
                                          this.setState({latitude: latLng.lat()})
                                          this.setState({longitude: latLng.lng()})
                                        }
                              }
                    >
                    </div>
                  </div>
                </div>
              </div>
              <div className="box box-warning">
                <div className="box-header">
                  <h3 className="box-title">Pontos Associados</h3>
                  <div className="box-tools">
                    <ul className="pagination pagination-sm no-margin pull-right">
                      <li><a href="#"><i className="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div className="box-body no-padding">
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>#</th>
                        <th style={{whiteSpace: "nowrap"}}>Barramento (Potência)</th>
                        <th style={{width: "100px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>

                      </tr>
                      <tr>
                        <td>1.</td>
                        <td>ABC0033G5544 (80W)</td>
                        <td>
                          <div className="btn-group">
                            <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<TransformadorMapa />, document.getElementById('Content'))}><i className="fa fa-map-o"></i></button>
                            <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<TransformadorMapa />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>ABC0033G5545 (80W)</td>
                        <td>
                          <div className="btn-group">
                            <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<TransformadorMapa />, document.getElementById('Content'))}><i className="fa fa-map-o"></i></button>
                            <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<TransformadorMapa />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </section>
      </form>
    )
  }
}