import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon, L } from 'leaflet';
import "./map.css";
import markerPosition from "../dashboard/iconMap.png";
import apiService from "../../../services/BaseService";
import MenuAlerta from "./menuAlerta";
import Modal from 'simple-react-modal'
import { TipoAlerta } from "./tipoAlerta";
import SimpleData from './Dashboard/simple-data'
import SimpleImage from './Dashboard/simple-image'
import ComandoGrupo from "./comandoGrupo";
import { ALERTS_ICONS, TYPE_ALERTS } from "./enumTelegestao";
import ListAlertsDevice from "./listAlertsDevice";


const MapaTelegestao = () => {
  const center = [-8.043202, -34.895833];

  var lumIntensityLevel = ["lum-0", "lum-1"];
  const [dataDevices, setDataDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [tipoFiltro, setTipoFiltro] = useState("");
  const [modalDashboard, setModalDashboard] = useState(false);
  const [amountAlertsState, setAmountAlertsState] = useState(null)
  const [historicAlertsDeviceState, setHistoricAlertsDeviceState] = useState([]);
  const fabricantes = {
    TECFAB: "TECFAB",
    BOTTOMUP: "BOTTOMUP"
  };
  const alertas = [{
    id: "uuid123",
    mensagem: "Ligado dia",
    fabricante: fabricantes.BOTTOMUP,
    tipo: TipoAlerta.LIGADO_DIA,
    gpsLat: -8.043202,
    gpsLon: -34.895833,
    luminousFlux: 25,
    hidden: false
  }, {
    id: "uuid133",
    mensagem: "Desligado noite",
    fabricante: fabricantes.TECFAB,
    tipo: TipoAlerta.DESLIGADO_NOITE,
    gpsLat: -8.044202,
    gpsLon: -34.896833,
    luminousFlux: 35,
    hidden: false
  }, {
    id: "uuid143",
    mensagem: "Oscilação de tensão",
    fabricante: fabricantes.BOTTOMUP,
    tipo: TipoAlerta.OSCILACAO_TENSAO,
    gpsLat: -8.045202,
    gpsLon: -34.898833,
    luminousFlux: 35,
    hidden: false
  }, {
    id: "uuid153",
    mensagem: "Oscilação de corrente",
    fabricante: fabricantes.TECFAB,
    tipo: TipoAlerta.OSCILACAO_CORRENTE,
    gpsLat: -8.046202,
    gpsLon: -34.898833,
    luminousFlux: 25,
    hidden: false
  }];

  useEffect(() => {
    getDataDevices();
    getAmountAlerts();
  }, [])

  useEffect(() => {
    addEventClickDashboardMenu();
  })

  const getDataDevices = async () => {
    // const resp = await apiService.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/telemetria-telemetria");
    // const resp = await apiService.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices");
    const resp = await apiService.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/alerts");
    setDataDevices(resp.data);
  };

  const getAmountAlerts = async () => {
    // const resp = await apiService.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/telemetria-telemetria");
    const resp = await apiService.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/alerts/amount");
    setAmountAlertsState(resp.data);
    // console.log('amount', resp);
  };

  const getHistoricAlertsDevice = async (id_device) => {
    const resp = await apiService.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/alerts/historic/${id_device}`);
    return resp.data;
  };

  const obterUltimoPackage = async (dev) => {
    await apiService.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/obter/${dev.id_device}`)
      // setDevice({...dev,telemetria: data.data})
      .then(async (res) => {
        setDevice({ ...dev, telemetria: res.data })
      })
      .catch((error) => {
        // console.error('Erro ao enviar dispositivo:', error);
      });
  }

  const getLuninosityLevel = (lun) => {
    if (lun < 30) {
      return lumIntensityLevel[1];
    }
    else {
      return lumIntensityLevel[0];
    }
  }

  const identifyTypeIcon = (typeAlert) => {
    return ALERTS_ICONS[typeAlert || 'normal']
  }

  const getIcon = (typeAlert) => {
    return new Icon({
      iconUrl: identifyTypeIcon(typeAlert),
      iconSize: [38, 38]
    })
  }

  const filtroAlerta = (filtro) => {
    setTipoFiltro(filtro)
    // console.log("filtro ativado mapa", tipoFiltro);
    alertas.forEach(alerta => {
      alerta.hidden = alerta.tipo !== tipoFiltro && tipoFiltro !== ""
      // console.log(`id: ${alerta.id}; tipo alerta: ${alerta.tipo}; hidde: ${alerta.tipo !== tipoFiltro && tipoFiltro.toString() !== ""}`);
    })
  }

  const addEventClickDashboardMenu = () => {
    const getContentsEl = () => {
      return [...document.querySelectorAll("#dashboard-modal .contents > div")];
    }
    const hiddenContents = (contents_el) => {
      contents_el.forEach(
        (el) => {
          el.classList.remove("active")
        }
      )
    }
    const showContent = (target) => {
      document.querySelector("#dashboard-modal .nav.nav-tabs li.active").classList.remove("active")
      document.querySelector(`#dashboard-modal [data-target='${target}']`).classList.add("active")
      document.querySelector(`#dashboard-modal .contents .${target}`).classList.add("active")
    }
    [...document.querySelectorAll("#dashboard-modal > div > div > div.box-body > div.container-data > ul > li")].forEach(
      (el) => {
        el.addEventListener(
          "click",
          (e) => {
            let target = el.dataset.target
            hiddenContents(getContentsEl())
            showContent(target)
          }
        )
      }
    )
  }

  const openDashboardModal = (mark) => {
    // console.log("mark", mark)
    setModalDashboard(true)
  }

  return (
    <>
      <Modal
        id="dashboard-modal"
        show={modalDashboard}
        containerStyle={{ background: 'Transparent' }}
        transitionSpeed={100}
      >
        <div className="box box-danger">
          <div className="box-body">
            <div className="row">
              <div className="col-xs-12">
                <h4>PONTO DE ILUMINAÇÃO</h4>
              </div>
            </div>
            <div className="container-data">
              <ul className="nav nav-tabs">
                <li role="presentation" data-target="dashboard-content" className="active"><a>Dashboard</a></li>
                {/* <li role="presentation" data-target="comando-content"><a>Comando</a></li> */}
              </ul>
              <div className="contents">
                <div className="dashboard-content active">
                  <div className="d-flex f-wrap jc-sa w-fit-content">
                    <div className="columns equipament">
                      <SimpleImage name='Consumo de Energia / Dia' info={device?.id_device} width='medium' />
                      {/* <div className="simple-data-container" style={{height:'90px', width:'250px', padding:'0px'}}> */}
                      <SimpleData name='Versão do Firmware' info={device?.telemetria?.firmware_version} width='medium'/>
                      {/* </div> */}
                      <SimpleData name='Estado da lâmpada' info={device?.telemetria?.is_access ? "Ligado" : "Desligado"} width='medium' />
                    </div>
                    <div className="d-flex f-dir-col">
                      <div className="columns metrics">
                        <SimpleData name='Consumo de Energia / Dia' info={(device?.telemetria?.consumption || "0") + 'kWh'} width='large' />
                        <SimpleData name='Consumo de Energia Real / Dia' info={(device?.telemetria?.real_consumption || "0") + 'W'} width='large' />
                        <SimpleData name='Tensão' info={(device?.telemetria?.tension || "0") + "V"} />
                        <SimpleData name='Corrente' info={(device?.telemetria?.current || "0") + "A"} />
                        <SimpleData name='Potência Aparente' info={(device?.telemetria?.pa || "0") + "VA"} />
                        <SimpleData name='Tempo Lâmp. Ligada' info={Math.floor((device?.telemetria?.time_on || 0) / 3600) + "h " + Math.floor(((device?.telemetria?.time_on || 0) % 3600) / 60) + "min"} />
                        <SimpleData name='Potência Reativa' info={(device?.telemetria?.pr || "0") + "VAr"} />
                        <SimpleData name='Fator de Potência' info={(device?.telemetria?.pf || "0") + 'Cos(φ)'} />
                      </div>
                      <div className="columns programation">
                        <ListAlertsDevice style={{ height: "inherit", width: "inherit" }} alertsDevice={historicAlertsDeviceState && historicAlertsDeviceState.length > 0 ? historicAlertsDeviceState : []} />
                        {/* <SimpleData name='Consumo de Energia / Dia' info='0.0 kWh' width='large' />
                          <SimpleData name='Consumo de Energia / Dia' info='0.0 kWh' width='large' />
                          <SimpleData name='Consumo de Energia / Dia' info='0.0 kWh' width='large' />
                          <SimpleData name='Consumo de Energia / Dia' info='0.0 kWh' width='large' /> */}
                      </div>
                      {/* <div>
                        <div className="text-center">
                          <label style={{ marginTop: "5px" }}>Estado da lâmpada: {device?.telemetria?.is_access ? "Ligado" : "Desligado"}</label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="comando-content">
                  <div className="row">
                    <div className="col-xs-12">
                      <ComandoGrupo />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="text-right">
                        <button className="btn btn-flat btn-danger" type="button">Enviar</button>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  {/* <div className='row'>
                        <div className="col-xs-12">
                          <div className="row">
                            <div className="col-xs-4 text-center">
                              <button type="button" className="btn btn-danger btn-flat" >Desligar</button>
                            </div>
                            <div className="col-xs-8">
                                <label className="form-label">Dimerizar:</label>
                                <div className="input-group input-group-sm">
                                  <input type="range" className="form-range dimerizar" min="0" max="100" step="10" />
                                  <span className='input-group-btn'>
                                    <div className='btn btn-info'>{'50'}</div>
                                  </span>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <button
              onClick={() => {
                setModalDashboard(false)
              }}
              className="btn btn-warning btn-flat"
            >
              Fechar
            </button>
          </div>
        </div>
      </Modal>

      <div className="telegestaoMap">
        <MenuAlerta setFiltro={filtroAlerta} amountAlerts={{ ...amountAlertsState, total_devices: dataDevices?.length || 0 }} />
        <MapContainer center={center} zoom={12} style={{ height: '100%' }}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            dataDevices.map(mark => (
              (mark.lat && mark.lng) ?
                <Marker
                  key={[mark.id_device]}
                  position={[mark?.lat, mark?.lng]}
                  icon={getIcon(mark.alert_type)}
                  hidden={mark.hidden}
                  eventHandlers={{
                    click: async () => {
                      setDevice(mark)
                      obterUltimoPackage(mark)
                      openDashboardModal(mark)
                      setHistoricAlertsDeviceState(await getHistoricAlertsDevice(mark.id_device))
                    }
                  }}
                >
                </Marker> : ""
            ))
          }
        </MapContainer>
      </div>
    </>
  );
}

export default MapaTelegestao
