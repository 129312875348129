import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader"
import Modal from 'simple-react-modal'
import Cookie from 'universal-cookie'
import { criptografar, descriptografar } from '../lib/utils2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Config from '../configdev'
// import Config from '../config'
import Chave from './chave'

import './styles.css'
import './styles-login.css'


const cookie = new Cookie()

export default function Login() {

  sessionStorage.removeItem('local')
  sessionStorage.removeItem('usuario')
  sessionStorage.removeItem('lstCidades')

  sessionStorage.setItem('local', Config.local)

  let _chave = ''
  if (!cookie.get('chave')) { //&& !sessionStorage.getItem('chave')
    ReactDOM.render(<Chave />, document.getElementById('root'))
  } else {
    _chave = cookie.get('chave') || sessionStorage.getItem('chave')
    Axios.post(`${Config.dominio}/php/chave.php`, JSON.stringify({ chave: descriptografar(_chave), local: sessionStorage.getItem('local') }))
      .then(async (response) => {
        // se a chave estiver errada, pede a chave novamente
        if (response.data.length == 0) {
          cookie.remove('chave')
          sessionStorage.removeItem('chave')
          ReactDOM.render(<Chave />, document.getElementById('root'))
        }
      })
      .catch(erro => console.log(erro))
  }

  const [lembrarUsuario, setLembrarUsuario] = useState(descriptografar(cookie.get('lembrarUsuario')) == 'true')
  const [usuario, setUsuario] = useState(descriptografar(cookie.get('usuario')))
  const [senha, setSenha] = useState(descriptografar(cookie.get('senha')))
  const [showModal, setShowModal] = useState(false)
  const [showPwd, setShowPwd] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setShowModal(true)
    await Axios.post(`${Config.dominio}/php/login.php`,
      JSON.stringify({
        chave: descriptografar(_chave),
        usuario: encodeURI(usuario),
        senha: encodeURI(senha),
        local: sessionStorage.getItem('local')
      })
    )
      .then(async (response) => {
        if (response.data.length == 0) {
          setShowModal(false)
          toast.error(`Credenciais inválidas. Verifique.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          if (lembrarUsuario) {
            cookie.set('usuario', criptografar(usuario), { maxAge: 365 * 24 * 60 * 60 }) // segundos
            cookie.set('senha', criptografar(senha), { maxAge: 365 * 24 * 60 * 60 }) // segundos
            cookie.set('lembrarUsuario', criptografar('true'), { maxAge: 365 * 24 * 60 * 60 }) // segundos
          }
          let _usuario = response.data[0]
          console.log(_usuario)

          // recuperar a lista de cidades do usuario
          await Axios.post(`${Config.dominio}/php/sistema/clienteMunicipio_list.php`,
            JSON.stringify({
              idUsuario: _usuario.id,
              local: sessionStorage.getItem('local'),
              chave: descriptografar(_chave)
            })

          )
            .then(async (response) => {
              let _lstCidades = response.data
              let _municipioAtual = _lstCidades.find(item => item.id == _usuario.municipioAtual) || _lstCidades[0]
              sessionStorage.setItem('lstCidades', criptografar(JSON.stringify(_lstCidades.sort((a, b) => a.nome > b.nome ? 1 : -1))))
              // define o municipio de trabalho
              _usuario.municipio = _municipioAtual

              // atualiza a base de dados com o municipio de trabalho atualizado
              await Axios.post(`${Config.dominio}/php/sistema/usuario_municipioAtual_update.php`,
                JSON.stringify({ idUsuario: _usuario.id, municipioAtual: _usuario.municipio.id }))
                .then()
                .catch(erro => console.log(erro))

              // lança na sessão o usuario
              sessionStorage.setItem('usuario', criptografar(JSON.stringify(_usuario)))

            })
            .then(() => document.location = '/actions')
            .catch(erro => console.log(erro))

        }
      })
      .catch(erro => console.log(erro))

  }

  return (
    <>
      <ToastContainer />
      <Modal show={showModal} containerStyle={{ background: 'Transparent' }} transitionSpeed={100}>
        <center>
          <ClipLoader className="override" color={"#123abc"} loading={true} />
        </center>
      </Modal>
      <div className='centerContainerLogin'>
        <div className="styleLogin">
          <form onSubmit={handleSubmit}>
            <h1 className='h1TextAlig'>Gestão de Iluminação</h1>
            <input
              className='styleLoginformInput'
              type="text"
              id="usuario"
              placeholder="Usuário"
              minLength="3"
              value={usuario}
              onChange={event => setUsuario(event.target.value)}
            />
            <div className="passwordStyle">
              <input placeholder="Senha" className="passwordStyleInput" type={showPwd ? "text" : "password"} minLength="5" value={senha} onChange={event => setSenha(event.target.value)} />
              <span >
                <button type="button" className="passwordStyleView" onClick={() => setShowPwd(!showPwd)}><span className={`fa ${showPwd ? 'fa-eye' : 'fa-eye-slash'}`} /></button>
              </span>
            </div>
            <div className='containerCheckBoxLogin'>
              <label >
                <input                  
                  type="checkbox"
                  id="lembrarSenha"
                  checked={lembrarUsuario}
                  onChange={event => setLembrarUsuario(event.target.checked)}
                />
                Lembrar senha
              </label>
            </div>
            <button className="btn" type="submit">Entrar</button>
            
          </form>
        </div>
      </div>
    </>
  )
}