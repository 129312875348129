import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader"
import Transformador from './transformador'
import TransformadorMapa from './transformadorMapa'
import Config from "../../../config"

export default class lstTransformadores extends Component {

  constructor(props) {
    super(props)
    this.state = {result: null,
                  pag_atual: 1
                 }
  }

  relatorio() {
    if (this.state.result){
      if (this.state.result[0].result.length == 0 )
        return (
          <center><h2>Não há Transformadores cadastrados.</h2></center>
        )
         
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Barramento</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>Bairro</th>
              <th style={{whiteSpace: "nowrap"}}>Logradouro</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result[0].result.map((item, index) =>
              <tr>
                <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{`${item.barramento}`}</td>
                <td style={{whiteSpace: "nowrap"}}>{`${item.nomeBairro}`}</td>
                <td style={{whiteSpace: "nowrap"}}>{`${item.rua}`}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<Transformador  id={`${item.id}`} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Transformador id={`${item.id}`} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                    <button type="button" className="btn btn-info" onClick={()=>ReactDOM.render(<TransformadorMapa id={`${item.id}`} />, document.getElementById('Content'))}><i className="fa fa-map-o"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Barramento</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>Bairro</th>
              <th style={{whiteSpace: "nowrap"}}>Logradouro</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  async componentWillMount() {
    await Axios.get(`${Config.dominio}/php/transformador_list.php`)
    .then( async (response) => {
        this.setState({result: response.data})
    }) 
    .catch(erro => console.log(erro))
  }

  paginacao(pagina) {
    // montar paginação depois de apresentar os dados
    switch(pagina) {
      case "-1":
        break
      case "1":
        break
      case "2":
        break
      case "3":
        break
      case "4":
        break
      case "5":
        break
      case "+1":
        break
      default:
  
    }
  }
  
  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Transformadores
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Transformador id="0" />, document.getElementById('Content'))}> Novo Transformador</button>
        </ol>
        </section>
        <section className="content">
        <div className="box">
            <div className="box-header">
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-8">
                    <div className="input-group input-group-sm">
                      <input type="text" id="pesquisar" className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-info btn-flat">Pesquisar</button>
                      </span>
                    </div>
                  </div>  
                </div> 
              </div>
            </div>
            <div className="box-body">
              <ClipLoader
                  css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                  color={"#123abc"}
                  loading={!this.state.result}
              />
              {this.relatorio()}
              
            </div>
          </div>
        </section>
      </>
    )
  }
}