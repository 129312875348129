import React from "react";
import { useQuery } from "react-query";
import { MapContainer, TileLayer } from "react-leaflet";

function MapaPrincipal() {
  
    return (
        <MapContainer center={[52.6376, -1.135171]} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
       
      </MapContainer>
    );

} export default MapaPrincipal;