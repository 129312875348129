import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { addDays, format } from 'date-fns'

import Config from '../../../config'

export default class transferenciaEstoque extends Component {
  render() {
    return(
      <>
        <section className="content-header">
          <h1>
            Estoque
            <small>Transferência</small>
          </h1>
        </section>
      </>
    )
  }
}