import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../../lib/utils3'
import ClipLoader from "react-spinners/ClipLoader"
import { usuario } from '../../../../lib/utils2'
import Veiculo from './veiculo'
import VeiculoMapa from './veiculoMapa'
import Config from "../../../../config"



const ListVeiculos = () => {
  const [listResponse, setListResponse] = useState(null);
  const [listFilter, setListFilter] = useState(listResponse);
  const [filtroPlaceholder, setFiltroPlaceholder] = useState("Placa");

  const searchFilter = (select = "") => {
    let list = listResponse
    setFiltroPlaceholder(select)
    const searchText = document.getElementById("pesquisar").value
    const selectFilter = select.toLowerCase()
    list = list.filter((vehicle) => {
      if (vehicle[selectFilter] !== null && vehicle[selectFilter] !== undefined) {
        return (vehicle[selectFilter]).toLowerCase().indexOf(searchText.toLowerCase()) > -1
      } else if (searchText == '') return list
    })
    setListFilter(list)
  }
  const relatorio = () => {
    if (listFilter) {
      if (listFilter.length == 0)
        return (
          <center><h2>Não há Veículos cadastrados.</h2></center>
        )

      return listFilter && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Placa</th>
              <th style={{ whiteSpace: "nowrap" }}>Modelo</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Veiculo</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Cesto Aéreo</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Munk</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {listFilter.map((item, index) =>
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap" }}>{`${item.placa}`}</td>
                <td style={{ whiteSpace: "nowrap" }}>{`${item.modelo}`}</td>
                <td style={{ whiteSpace: "nowrap" }}>{`${item.veiculo}`}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="checkbox"><label><input disabled type="checkbox" defaultChecked={item.cesto == 1} /></label></div>
                </td>
                <td style={{ textAlign: "center" }}>
                  <div className="checkbox"><label><input disabled type="checkbox" defaultChecked={item.munk == 1} /></label></div>
                </td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={() => ReactDOM.render(<Veiculo id={item.id} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={() => ReactDOM.render(<Veiculo id={item.id} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                    {/* <button type="button" className="btn btn-info" onClick={() => ReactDOM.render(<VeiculoMapa id={item.id} />, document.getElementById('Content'))}><i className="fa fa-map-o"></i></button> */}
                  </div>
                </td>
              </tr>

            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Placa</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Tipo</th>
              <th style={{ whiteSpace: "nowrap" }}>Modelo</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Cesto Aéreo</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Munk</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }
  const componentDidMount = async () => {
    await AxiosPost(`${Config.dominio}/php/equipe/veiculo_list.php`,
      JSON.stringify({
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
      })
    )
      .then(async (response) => {
        setListFilter(response.data)
        setListResponse(response.data)
      })
      .catch(erro => console.error(erro))
  }
  useEffect(() => {
    componentDidMount()
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>
          Veículos
          <small>Listagem geral</small>
        </h1>
        <ol className="breadcrumb">
          <button className="btn btn-block btn-primary" onClick={() => ReactDOM.render(<Veiculo id="0" />, document.getElementById('Content'))}> Novo Veículo</button>
        </ol>
      </section>
      <section className="content">
        <div className="box">
          <div className="box-header">
            <div className="form-group">
              <div className="row">
                <div className="col-xs-8">
                  <div className="input-group input-group-sm">
                    <div className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-warning dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Filtro&nbsp;&nbsp;
                        <span className="fa fa-caret-down"></span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            href="#"
                            onClick={() => searchFilter("Placa")}
                          >
                            Placa
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={() => searchFilter("Veiculo")}
                          >
                            Veiculo
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={() => searchFilter("Modelo")}
                          >
                            Modelo
                          </a>
                        </li>

                      </ul>
                    </div>
                    <input type="text" onChange={() => searchFilter(filtroPlaceholder)} placeholder={filtroPlaceholder} id="pesquisar" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-body">
            <ClipLoader
              css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
              color={"#123abc"}
              loading={!listFilter}
            />
            {relatorio()}
          </div>
        </div>
      </section>
    </>

  )

}

export default ListVeiculos;

