import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from 'react-geocode'
import Modal from 'simple-react-modal'
import LstPontosIluminacao from './lstPontosIluminacao'
import Utils from '../../../lib/utils'
import { format } from 'date-fns'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Config from '../../../config'
const animatedComponents = makeAnimated()
Geocode.setApiKey(Config.apiKey)

export class PontosIluminacao extends Component {

  state = {
    id: this.props.id,
    barramento: '',
    tombamento: '',
    telegestao: '',
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude,
    cep: '',
    logradouro: '',
    bairro: '',
    larguraVia: '',
    tipoPavimento: 0,
    larguraPasseio: '',
    tipoMedicao: 1,
    conduc: '',
    tipoMaterial: 0,
    altura: '',
    lstTipoPavimento: [],
    lstTipoMaterial: [],
    lstStatusOS: [],
    lstTipoMedicao: [],
    lstOS: [],
    lstItens: [],
    materialQuantidade: 1,
    materialItem: null,
    lstMaterial: [], // Material do ponto
    lstMateriaisRef: [], // lista de materiais disponíveis no estoque - conjunto não adaptado
    lstMateriais: [], // lista de materiais disponíveis no estoque - conjunto adaptado para o select
    showNovoItem: false,
    showModal: false,
    idDispositivo: '',
    horarioFuncionamentoInicio: '',
    horarioFuncionamentoFim: '',
    firmware:'',
    telemetria: {
      intensidade:50
    }
  }

  telemetria = {
    idDispositivo: "1234abcd",
    internalTemperature: "50Cel",
    gpsLon: -34.895433,
    illuminance: "100lx",
    accumulatedPower: "30J",
    apparentPower: "10VA",
    gpsLat: -8.043402,
    electricPotential: "5V",
    electricCurrent: "2A",
    power: "13W",
    luminousFlux: 55,
    horarioFuncionamentoInicio: "17:00",
    horarioFuncionamentoFim: "05:00",
    firmware:"0000567abc",
    intensidade: 50
  }

  handleSubmit = this.handleSubmit.bind(this)
  buscarLocal = this.buscarLocal.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)
  checkValidateForm = this.checkValidateForm.bind(this)

  gruposTelemetria = [];


  cancelaExcluir() {
    ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_delete.php`,
      JSON.stringify({ id: this.state.id })
    )
      .then(async function (response) {
        this.setState({ showModal: false })
        toast.info('Ponto de Iluminação removido!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
        })
      }.bind(this))
      .catch(function (erro) {
        console.error(erro)
      })
  }

  async listarTipoPavimento() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 3 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoPavimento: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async listarTipoMaterial() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 2 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoMaterial: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async listarStatusOS() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 5 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstStatusOS: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async listarTipoMedicao() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 1 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoMedicao: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async listarOS() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_list.php`,
      JSON.stringify({ idPonto: this.state.id })
    )
      .then(async (response) => {
        let data = response.data
        data.forEach((os) => {
          let teste = this.state.lstStatusOS.filter(({ valor }) => valor == os.id_status_atual)[0]
          os.status_name = teste.descricao
        })
        this.setState({ lstOS: data })

      })
      .catch(erro => console.error(erro))
  }

  async materiais() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
      JSON.stringify({ dadosPonto: 1 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.filter(item => item.dadosPonto == 1)
          .map(item => _lista.push({
            value: item.id,
            label: item.codigo + " " + item.descricao
          })
          )
        this.setState({
          lstMateriaisRef: response.data,
          lstMateriais: _lista
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async buscarMateriais(_id) {
    // listar materiais inseridos pelo cadastro
    let lista = []
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_material_list.php`,
      JSON.stringify({ ponto: this.state.id })
    )
      .then(async response => {
        response.data.sort((a, b) => a.material > b.material ? 1 : -1).map(item =>
          lista.push({
            material: this.state.lstMateriaisRef.find(ii => ii.id == item.id),
            quantidade: item.quantidade_total,
            novo: false,
            remover: false
          })
        )
        this.setState({ lstMaterial: lista })
      }
      )
      .catch(erro => console.error(erro))
  }

  adicionarItens() {
    if (this.state.materialItem) {
      let _lista = this.state.lstMaterial
      _lista.push({
        material: this.state.lstMateriaisRef.find(item => item.id == this.state.materialItem.value),
        quantidade: this.state.materialQuantidade,
        novo: true,
        remover: false
      })
      this.setState({ lstMaterial: _lista })
    } else {
      toast.warning('Informe um material para cadastrar.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    this.setState({ materialItem: null, materialQuantidade: 1 })
  }

  removerItens(index) {
    let _lista = this.state.lstMaterial
    if (_lista[index].novo) {
      _lista = this.state.lstMaterial.filter((item, ind) => index != ind)
    } else {
      _lista[index].remover = true
    }
    this.setState({ lstMaterial: _lista })
  }

  async CEP(event) {
    let _cep = this.state.cep ? this.state.cep.replace(/\D/g, '') : document.getElementById("cep").value.replace(/\D/g, '')
    if (_cep != "") {
      let validacep = /^[0-9]{8}$/
      if (validacep.test(_cep)) {
        Axios.get(`https://viacep.com.br/ws/${_cep}/json/`)
          .then(async function (response) {
            if (response.status == 200 && response.data != null) {
              if (!response.data.erro) {
                let __cep = response.data
                if (__cep.uf == usuario().municipio.nome.split(' / ')[1] &&
                  __cep.localidade.toUpperCase() == usuario().municipio.nome.split(' / ')[0].toUpperCase()) {
                  this.setState({
                    logradouro: __cep.logradouro,
                    bairro: __cep.bairro,
                  })

                  await Geocode.fromAddress((__cep.logradouro != "" ? __cep.logradouro + ", " : "") +
                    (__cep.bairro != "" ? __cep.bairro + ", " : "") +
                    (__cep.localidade != "" ? __cep.localidade + ", " : "") +
                    __cep.uf + " Brazil")
                    .then((response) => {
                      const { lat, lng } = response.results[0].geometry.location
                      this.setState({ latitude: String(lat), longitude: String(lng) })
                    })
                    .catch(function (erro) {
                      console.error(erro)
                    }
                    )
                  toast.success('Ponto localizado com sucesso!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined

                  });
                } else {
                  toast.warning('Ponto não localizado no município. Verifique.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined
                  })
                }
              } else {
                alert("CEP não encontrado.")
              }
            }
            else {
              alert("Erro.");
            }
          }.bind(this))
          .catch((erro) => {
            console.error(erro);
          })

      }
      else {
        alert("Verifique o cep informado.");
      }
    }
  }

  async buscarLocal(e) {
    await Geocode.fromLatLng(this.state.latitude, this.state.longitude)
      .then((response) => {
        let resultado = Utils.Endereco(response)
        if (resultado.siglaUF == usuario().municipio.nome.split(' / ')[1] ||
          resultado.nomeCidade.toUpperCase() == usuario().municipio.nome.split(' / ')[0].toUpperCase()) {
          this.setState({
            cep: resultado.cep,
            logradouro: (resultado.rua + (resultado.numero ? ', ' + resultado.numero : '')),
            bairro: resultado.bairro

          });
          toast.success('Ponto localizado com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined

          });
        } else {
          toast.warning('Ponto não localizado no município. Verifique.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined

          });
        }
      })
      .catch(erro => console.error(erro))
  }
  async checkValidateForm(e) {
    e.preventDefault()
    await this.handleSubmit()
  }
  async handleSubmit() {
    let lstMaterialPonto = []
    let lstMaterialPontoQuantidade = []
    let lstMaterialPontoAcao = []
    this.state.lstMaterial.map(item => {
      lstMaterialPonto.push(item.material.id)
      lstMaterialPontoQuantidade.push(item.quantidade)
      item.novo && lstMaterialPontoAcao.push("adicionar")
      !item.novo && item.remover && lstMaterialPontoAcao.push("remover")
      !item.novo && !item.remover && lstMaterialPontoAcao.push("nada")
    })
    let _acao = (this.state.id && this.state.id > 0) ? 'pontosIluminacao_update.php' : 'pontosIluminacao_insert.php'
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/${_acao}`,
      JSON.stringify({
        id: this.state.id,
        barramento: this.state.barramento,
        tombamento: this.state.tombamento,
        telegestao: this.state.telegestao,
        longitude: this.state.longitude,
        latitude: this.state.latitude,
        cep: this.state.cep,
        logradouro: encodeURI(this.state.logradouro),
        bairro: encodeURI(this.state.bairro),
        larguraVia: this.state.larguraVia,
        tipoPavimento: this.state.tipoPavimento,
        larguraPasseio: this.state.larguraPasseio,
        tipoMedicao: this.state.tipoMedicao,
        conduc: this.state.conduc,
        tipoMaterial: this.state.tipoMaterial,
        altura: this.state.altura,
        lstMaterialPonto: lstMaterialPonto.toString(),
        lstMaterialPontoQuantidade: lstMaterialPontoQuantidade.toString(),
        lstMaterialPontoAcao: lstMaterialPontoAcao.toString()
      })
    )
      .then(async function (response) {
        toast.info('Ponto armazenado com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
        })

      }.bind(this))
      .catch(function (erro) {
        console.error(erro)
      })
      await Axios.post("https://us-central1-gip-1-6c37c.cloudfunctions.net/telemetria-telemetria", {
        id: this.state.idDispositivo,
        gpsLon: parseFloat(this.state.longitude),
        gpsLat: parseFloat(this.state.latitude),
        horarioFuncionamentoInicio: this.state.horarioFuncionamentoInicio,
        horarioFuncionamentoFim: this.state.horarioFuncionamentoFim,
        firmware:this.state.firmware,
        bairro: this.state.bairro,
        logradouro: this.state.logradouro,
        cep: this.state.cep
      }).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(this.telemetria)
        console.log(error)
      })
  }

  UNSAFE_componentWillMount() {
    this.listarStatusOS()
    this.listarTipoPavimento()
    this.listarTipoMaterial()
    this.listarTipoMedicao()
    this.materiais()
    this.listarOS()
    // this.carregarGruposTelemetria()
  }

  async componentDidMount() {
    if (this.props.id) {
      await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
        JSON.stringify({ id: this.props.id })
      )
        .then(async function (response) {
          if (response.data) {
            let i = response.data[0]
            this.setState({
              id: i.id,
              barramento: i.barramento,
              tombamento: i.tombamento,
              telegestao: i.telegestao,
              latitude: i.latitude,
              longitude: i.longitude,
              cep: i.cep,
              logradouro: i.rua,
              bairro: i.bairro,
              larguraVia: i.largura_via,
              tipoPavimento: i.pavimento,
              larguraPasseio: i.largura_passeio,
              tipoMedicao: i.medicao == null ? 1 : i.medicao,
              tipoMaterial: i.tipo_poste,
              altura: i.altura
            })
            this.buscarMateriais(i.id)
          }
        }.bind(this))
        .catch(erro => console.error(erro))

      this.setState({ showModal: this.props.action == 'delete' })
    }
  }

  // carregarGruposTelemetria = async () => {
  //   await Axios.get('http://127.0.0.1:5001/gip-1-6c37c/us-central1/grupo-grupo')
  //   .then(res => {
  //     this.gruposTelemetria = JSON.parse(JSON.stringify(res.data));
  //   })
  // }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir esse Ponto de Iluminação?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  id="btnExcluir"
                  onClick={this.confirmaExcluir}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={this.cancelaExcluir}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <form id="clientForm" onSubmit={this.checkValidateForm}>
          <section className="content-header">
            <h1>
              Ponto de Iluminação
            </h1>
            <ol className="breadcrumb">
              <li>
                <button
                  type="button"
                  className="btn btn-danger btn-flat"
                  onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))}
                >Voltar</button>
                <button className="btn btn-primary" type="submit" id="btnSalvarClient" >Salvar</button>
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-3">
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Identificação</h3>
                  </div>
                  <div className="box-body box-profile">
                    <div className="form-group">
                      <label htmlFor="barramento">Barramento</label>
                      <div className="input-group input-group-sm">
                        <input
                          required
                          type="text"
                          value={this.state.barramento || ""}
                          className="form-control"
                          placeholder=""
                          onChange={event => this.setState({ barramento: event.target.value })}
                        />
                      </div>
                      <label htmlFor="tombamento">Tombamento</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          value={this.state.tombamento || ""}
                          className="form-control"
                          placeholder=""
                          onChange={event => this.setState({ tombamento: event.target.value })}
                        />
                      </div>
                      <label htmlFor="tombamento">Telegestão</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          value={this.state.telegestao || ""}
                          className="form-control"
                          placeholder=""
                          onChange={event => this.setState({ telegestao: event.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="nav-tabs-custom">
                  <ul id="tab_itens" className="nav nav-tabs">
                    <li className="active"><a href="#endereco" data-toggle="tab">Endereço</a></li>
                    <li><a href="#materiais" data-toggle="tab">Materiais</a></li>
                    {this.state.id > 0 &&
                      <li><a href="#OS" data-toggle="tab">Ordens de Serviço</a></li>
                    }
                    {/* <li><a href="#telegestao" data-toggle="tab" onClick={this.carregarGruposTelemetria}>Telegestão</a></li> */}
                  </ul>
                  <div className="tab-content">
                    <div className="active tab-pane" id="endereco">
                      <div className="row">
                        <div className="col-xs-8">
                          <div className="row">
                            <div className='col-xs-4'>
                              <label>ID Dispositivo</label>
                              <input 
                                required
                                type="text"
                                className="form-control"
                                onChange={event => this.setState({ idDispositivo: event.target.value})}
                              />
                            </div>
                            <div className='col-xs-4'>
                              <label>Firmware</label>
                              <input 
                                required
                                type="text"
                                className="form-control"
                                onChange={event => this.setState({ firmware: event.target.value})}
                              />
                            </div>
                            <div className="col-xs-4">
                              <label htmlFor="cep">CEP</label>
                              <div className="input-group input-group-sm">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  value={this.state.cep || ""}
                                  onChange={event => this.setState({ cep: event.target.value })}
                                />
                                <span className="input-group-btn">
                                  <button type="button" className="btn btn-info btn-flat" onClick={event => this.CEP(event)}>Consultar</button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <label htmlFor="logradouro">Logradouro</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                value={this.state.logradouro || ""}
                                onChange={event => this.setState({ logradouro: event.target.value })}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <label>Bairro</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                value={this.state.bairro || ""}
                                onChange={event => this.setState({ bairro: event.target.value })}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>UF</label>
                                <input
                                  type="text"
                                  disabled="disbl"
                                  className="form-control"
                                  defaultValue={usuario().municipio.nome.split(' / ')[1]}
                                />
                              </div>
                            </div>
                            <div className="col-xs-8">
                              <div className="form-group">
                                <label>Cidade</label>
                                <input
                                  type="text"
                                  disabled="disabled"
                                  className="form-control"
                                  defaultValue={usuario().municipio.nome.split(' / ')[0]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label htmlFor="latitude">Latitude</label>
                                <input
                                  required
                                  type="text"
                                  value={this.state.latitude || ""}
                                  className="form-control"
                                  placeholder=""
                                  onChange={event => this.setState({ latitude: event.target.value })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label htmlFor="longitude">Longitude</label>
                                <input
                                  required
                                  type="text"
                                  value={this.state.longitude || ""}
                                  className="form-control"
                                  placeholder=""
                                  onChange={event => this.setState({ longitude: event.target.value })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group" style={{ textAlign: 'right' }}>
                                <br />
                                <button type="button" onClick={event => this.buscarLocal(event)} className="btn btn-info btn-flat" >Buscar local</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label >Mapa</label>
                          <div className="form-group" style={{ height: '280px', height: '280px', border: "outset" }}>
                            <Map
                              google={this.props.google}
                              zoom={16}
                              containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                              initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                              center={{ lat: this.state.latitude, lng: this.state.longitude }}
                              mapTypeControl={false}
                              fullscreenControl={false}
                              streetViewControl={false}
                              onClick={(t, map, coord) => {
                                const { latLng } = coord
                                this.setState({
                                  latitude: latLng.lat().toString(),
                                  longitude: latLng.lng().toString()
                                })
                              }
                              }
                            >
                              <Marker
                                position={new window.google.maps.LatLng(this.state.latitude, this.state.longitude)}
                              >
                              </Marker>
                            </Map>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-xs-8">
                          <div className="row">
                            <div className="col-xs-12">
                              <label>Horário Estipulado de Funcionamento</label>
                              <div className="row">
                                <div className="col-xs-4">
                                  <label>Início</label>
                                  <input
                                    className="form-control"
                                    onChange={event => this.setState({horarioFuncionamentoInicio:event.target.value})}
                                  />
                                </div>
                                <div className="col-xs-4">
                                  <label>Fim</label>
                                  <input
                                    className="form-control"
                                    onChange={event => this.setState({horarioFuncionamentoFim:event.target.value})}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <hr />
                      <div className="row">

                        <div className="col-xs-12">
                          <h3 style={{margin: 0}} className="box-title">Sobre a Via</h3>
                        </div>
                        <div className="col-xs-4">
                          <div className="form-group">
                            <label htmlFor="larguraVia">Largura da via</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              placeholder="metros"
                              value={this.state.larguraVia}
                              onChange={e => this.setState({ larguraVia: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div className="form-group">
                            <label htmlFor="larguraPasseio">Largura do passeio</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              placeholder="metros"
                              value={this.state.larguraPasseio}
                              onChange={e => this.setState({ larguraPasseio: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div className="form-group">
                            <label>Tipo de pavimento</label>
                            <select
                              className="form-control select2"
                              style={{ width: '100%' }}
                              value={this.state.tipoPavimento}
                              onChange={e => this.setState({ tipoPavimento: e.target.value })}
                            >
                              {
                                this.state.lstTipoPavimento.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                              }
                            </select>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-xs-12">
                          <h3 style={{margin: 0}} className="box-title">Sobre o Poste</h3>
                        </div>
                        <div className="col-xs-3">
                          <div className="form-group">
                            <label htmlFor="altura">Altura do poste</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              placeholder="metros"
                              value={this.state.altura}
                              onChange={e => this.setState({ altura: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-xs-3">
                          <div className="form-group">
                            <label>Tipo de material</label>
                            <select
                              className="form-control select2"
                              style={{ width: '100%' }}
                              value={this.state.tipoMaterial}
                              onChange={e => this.setState({ tipoMaterial: e.target.value })}
                            >
                              {
                                this.state.lstTipoMaterial.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-3">
                          <div className="form-group">
                            <label>Tipo de medição</label>
                            <select
                              className="form-control select2"
                              value={this.state.tipoMedicao}
                              style={{ width: '100%' }}
                              onChange={e => this.setState({ tipoMedicao: e.target.value })}
                            >
                              {
                                this.state.lstTipoMedicao.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                              }
                            </select>

                          </div>
                        </div>
                        {this.state.tipoMedicao == 0 &&
                          <div className="col-xs-3">
                            <div className="form-group">
                              <label>CONDUC</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                value={this.state.conduc}
                                onChange={event => this.setState({ conduc: event.target.value })}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="tab-pane" id="fotos">
                      <div className="form-horizontal">
                        <div className="box-body">
                          <div className="row margin-bottom">
                            <div className="col-sm-6">
                              <img className="img-responsive" src={`fot.jpg`} alt="Photo1" />
                            </div>
                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-sm-6">
                                  <img className="img-responsive" src={`fot.jpg`} alt="Photo2" />
                                  <br />
                                  <img className="img-responsive" src={`fot.jpg`} alt="Photo3" />
                                </div>
                                <div className="col-sm-6">
                                  <img className="img-responsive" src={`fot.jpg`} alt="Photo4" />
                                  <br />
                                  <img className="img-responsive" src={`fot.jpg`} alt="Photo5" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-footer">
                          <button type="button" className="btn btn-info btn-flat">Editar fotos</button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="materiais">
                      <ol className="breadcrumb">
                        <li>
                          <button
                            type="button"
                            className="btn btn-info btn-flat"
                            onClick={() => this.setState({ showNovoItem: !this.state.showNovoItem })}
                          >
                            {this.state.showNovoItem ? "Ocultar inclusão" : "Incluir itens"}
                          </button>
                        </li>
                      </ol>

                      <div className="box box-success" style={{ display: `${this.state.showNovoItem ? "block" : "none"}` }}>
                        <div className="box-header with-border">
                          <h3 className="box-title">Novo Item</h3>
                        </div>
                        <div className="box-body" >
                          <div className="row">
                            <div className="col-xs-8">
                              <div className="form-group">
                                <label htmlFor="Material">Material</label>
                                <Select
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  options={this.state.lstMateriais}
                                  style={{ width: '100%' }}
                                  value={this.state.materialItem}
                                  onChange={selectedOption => this.setState({ materialItem: selectedOption })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-2">
                              <div className="form-group">
                                <label htmlFor="quantidade">Quantidade</label>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  placeholder="quantidade"
                                  value={this.state.materialQuantidade || 1}
                                  onChange={event => this.setState({ materialQuantidade: event.target.value })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-2">
                              <div className="form-group">
                                <label htmlFor="botao">&nbsp;</label>
                                <button
                                  type="button"
                                  onClick={() => this.adicionarItens()}
                                  className="btn btn-block btn-primary"
                                >
                                  Incluir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.lstMaterial.length > 0 &&
                        <div className="box box-success">
                          <div className="box-header with-border">
                            <h3 className="box-title">Material do Ponto</h3>
                          </div>
                          <div className="box-body">
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                  <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                  <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.lstMaterial.map((item, index) =>
                                    <tr key={index} style={{ display: `${item.remover ? "none" : "table-row"}` }}>
                                      <td style={{ verticalAlign: "middle" }}>{item.material && item.material.descricao}</td>
                                      <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.quantidade}</td>
                                      <td style={{ verticalAlign: "middle" }}><button type="button" className="btn btn-danger" onClick={() => this.removerItens(index)}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                  )
                                }
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                  <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                  <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div className="box-footer">
                          </div>
                        </div>
                      }
                    </div>
                    <div className="tab-pane" id="OS">
                      {this.state.lstOS && this.state.lstOS.length == 0 &&
                        <h3>Não há registro de ocorrências para este ponto.</h3>
                      }
                      {this.state.lstOS && this.state.lstOS.length > 0 && this.state.lstOS.map((os, index) =>
                        <ul className="timeline timeline-inverse" key={index}>
                          <li>
                            <i className="fa fa-bullhorn bg-blue" onClick={() => {
                              let item = document.getElementById(`${os.protocolo}`)
                              item.style.display = (item.style.display == "none") ? "block" : "none"
                            }}></i>
                            <div className="timeline-item">
                              <span className="time"><i className="fa fa-clock-o"></i> {format(new Date(os.datahora_atual), 'dd/MM/yyyy HH:mm')}</span>
                              <h3 className="timeline-header">
                                Protocolo: {os.protocolo}
                              </h3>
                              <div className="timeline-body" id={os.protocolo} style={{ display: "none" }}>
                                Problema notificado: {os.desc_problema}<br />
                                Situação atual: {os.status_name} <br />
                                {os.id_status_atual > 2 && <span>Equipe responsável: {os.equipe_tecnicos}</span>}
                              </div>
                            </div>
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="tab-pane" id="telegestao">
                      <div className="row">
                        <div className="col-xs-8">
                          <div className="row">
                            <div className="col-xs-4">
                              <label >ID</label>
                              <div className="input-group input-group-sm">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={this.telemetria.idDispositivo || ''}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <label >Tensão</label>
                              <input
                                type="text"
                                className="form-control"
                                readOnly={true}
                                value={this.telemetria.electricPotential || ''}
                              />
                            </div>
                            <div className="col-xs-4">
                              <label>Corrente</label>
                              <input
                                type="text"
                                className="form-control"
                                readOnly={true}
                                value={this.telemetria.electricCurrent || ''}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>Potência Atual</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                value={this.telemetria.power || ''}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>Potência Lâmpada</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={this.telemetria.power || ''}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>Firmware</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={this.telemetria.firmware || ''}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>Latitude</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={this.telemetria.gpsLat || ''}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label >Longitude</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={this.telemetria.gpsLon || ''}
                                />
                              </div>
                            </div>                            
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label>Grupo</label>
                                <select
                                  className="form-control"
                                >
                                  {
                                    this.gruposTelemetria.map(grupo => (
                                      <option key={grupo.id} value={grupo.id}> {grupo.descricao}</option>
                                      ))
                                  }
                                  <option>Apipucos</option>
                                </select>
                                <div className="text-right">
                                  <button type="button" className='btn btn-flat btn-primary'>Adicionar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex flex-row align-self-baseline">
                            <div className="col-xs-4">
                        <label >Estado do Poste</label>
                        <div className="input-group input-group-sm">
                          <input
                            required
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={(this.telemetria.electricPotential && this.telemetria.electricCurrent) ? "Ligado" : "Desligado"}
                          />
                        </div>
                      </div>
                      <div className="col-xs-8">
                        <label >Horário Estipulado de Funcionamento</label>
                        <div>
                          <span>{`${this.telemetria.horarioFuncionamentoInicio || ''} - ${this.telemetria.horarioFuncionamentoFim || ''}`}</span>
                        </div>
                      </div>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className="col-xs-8">
                          <div className="row">
                            <div className="col-xs-4 text-center">
                              <button type="button" className="btn btn-danger btn-flat" >Desligar</button>
                            </div>
                            <div className="col-xs-8">
                                <label className="form-label">Dimerizar:</label>
                                <div className="input-group input-group-sm">
                                  <input type="range" className="form-range dimerizar" min="0" max="100" step="10" onChange={event => this.setState({telemetria: {intensidade: event.target.value }})} />
                                  <span className='input-group-btn'>
                                    <div className='btn btn-info'>{this.state.telemetria.intensidade}</div>
                                  </span>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </>
    )
  }
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey,
    language: 'pt-BR'
  }
  ))(PontosIluminacao)