import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import ClipLoader from "react-spinners/ClipLoader"
import Distrito from "./distrito"
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'


export default class lstDistritos extends Component {

  state = {result: null }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0)
        return ( <center><h2>Não há Distritos cadastrados.</h2></center> )
        
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>Nome</th>
              <th style={{width:"104px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap"}}>{`${item.distrito}`}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={()=>ReactDOM.render(<Distrito id={item.id} action="edit" />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Distrito id={item.id} action="delete" />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
              <th style={{whiteSpace: "nowrap"}}>Nome</th>
              <th style={{width:"104px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
        </tfoot>
      </table>
      )
    }
  }

  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/distrito/list.php`,
      JSON.stringify({
                      local: sessionStorage.getItem('local'),
                      chaveMunicipio: usuario().municipio.chave
      }))
    .then( async (response) => {
      this.setState({result: response.data})
    }) 
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Distritos
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Distrito id="0" />, document.getElementById('Content'))}> Novo Distrito</button>
          </ol>
        </section>
        <section className="content">
          <div className="box"> 
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Nome"}>Nome</a></li>
                  </ul>
                </div>
                <input type="text" id="pesquisar" className="form-control" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat">Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">
              
            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}