import apiService from "./BaseService"
import { usuario } from '../lib/utils2'

const ENDPOINT_PATH_CLIENT_LIST = "/php/sistema/cliente_list.php" 
const ENDPOINT_PATH_COUNTRY_LIST = "/php/sistema/clienteMunicipio_list.php" 
const ENDPOINT_PATH_CLIENT_INSERT = "/php/sistema/cliente_insert.php" 
const ENDPOINT_PATH_COUNTRY_UPDATE = "/php/sistema/cliente_update.php" 

const LOCAL = sessionStorage.getItem('local')
const CHAVEMUNICIPIO = usuario() ? usuario().municipio.chave : ""

const ClientService = {
    findAllCounty: async (data) => apiService.post(ENDPOINT_PATH_COUNTRY_LIST,   JSON.stringify({
        local: LOCAL,
        chaveMunicipio: CHAVEMUNICIPIO,
        ...data
    })),
    findAllClient: async (data) => apiService.post(ENDPOINT_PATH_CLIENT_LIST,   JSON.stringify({
        local: LOCAL,
        chaveMunicipio: CHAVEMUNICIPIO,
        ...data
    })),
    updateClient: async (data) => apiService.post(ENDPOINT_PATH_COUNTRY_UPDATE,   JSON.stringify({
        local: LOCAL,
        chaveMunicipio: CHAVEMUNICIPIO,
        ...data
    })),
    createClient: async (data) => apiService.post(ENDPOINT_PATH_CLIENT_INSERT,   JSON.stringify({
        local: LOCAL,
        chaveMunicipio: CHAVEMUNICIPIO,
        ...data
    })),
};

export default ClientService;
