import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import LstMateriais from './lstMateriais'
import Utils from '../../../lib/utils'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Config from '../../../config'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'

export default class materiais extends Component {

  state={
          id: this.props.id,
          codigo: '',
          descricao: '',
          unidade_medida: 0,
          quantidade: 0,
          potencia: 0,
          consumo: 0,
          grupo_id: null,
          lstGrupos: [],
          lstUnidadeMedida:[],
          showModal: false
        }

    handleSubmit = this.handleSubmit.bind(this)
    confirmaExcluir = this.confirmaExcluir.bind(this)

  cancelaExcluir() {
    ReactDOM.render(<LstMateriais />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    const _this = this;
    // Verificar se existe os materais estão associados a OS
    await AxiosPost(
      `${Config.dominio}/php/osMateraisEntrada.php`,
      JSON.stringify({
        materialId: this.state.id,
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
      })
    )
    .then(async function (response) {
      if (!response.data.status) {
        _this.setState({showModal: false})
        toast.warning('Houve um erro inesperado, entre em contato com o suporte', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        return;
      }

      if (response.data.result.total > 0) {
        _this.setState({showModal: false})
        toast.warning(`Não foi possível excluir o material, foi encontrado ${response.data.result.total} material(is) associado(s) a OS.`, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        return;
      }

      await AxiosPost(`${Config.dominio}/php/materiais_delete.php`, 
                        JSON.stringify({
                                        id: _this.state.id,
                                        local: sessionStorage.getItem('local'),
                                        chaveMunicipio: usuario().municipio.chave
                                      })
      )
      .then(async function (response) {
        _this.setState({showModal: false})
        toast.info('Material removido!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstMateriais />, document.getElementById('Content'))
          })
      })
      .catch(function (erro) {
        console.error(erro)
      })
    })
    .catch(erro => console.error(erro))
  }

  async listarUnidadeMedida() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
                    JSON.stringify({
                      tabela: 10,
                      local: sessionStorage.getItem('local'),
                      chaveMunicipio: usuario().municipio.chave
                    })
    )
    .then(async function (response) {
      let _lista = []
      response.data.map(item => _lista.push({valor: item.valor, descricao: item.descricao}))
      this.setState({lstUnidadeMedida: _lista})
    }.bind(this))
    .catch(function (erro) {
      console.error(erro);
    })
  }

  async listarGrupos() {
    await AxiosPost(`${Config.dominio}/php/grupoMateriais_list.php`,
                      JSON.stringify({
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: usuario().municipio.chave
                      })
    )
    .then(async function (response) {
      let lista = response.data.map(item => ({id: item.vgrupo_id, descricao: item.vgrupo_desc, level: item.level}))
      let grupo_id = lista.length > 0 ? lista[0].id : null
      this.setState({lstGrupos: lista, grupo_id})
    }.bind(this))
    .catch(function (erro) {
      console.error(erro);
    })
  }

  async saldoEstoque() {
    let _saldo=0
    if (this.props.id!=0) {
      await AxiosPost(`${Config.dominio}/php/estoque/estoque_saldo.php`,
                        JSON.stringify({
                          material: this.props.id,
                          local: sessionStorage.getItem('local'),
                          chaveMunicipio: usuario().municipio.chave
                        })
      )
      .then(async function (response) {
        if (response.data.length==1)
        _saldo = response.data[0].quantidade_total
      })
      .catch(erro => console.error(erro))
    }
    this.setState({quantidade: _saldo})
  }

  async handleSubmit(event) {
    event.preventDefault()
    let _acao = (this.state.id==0)? '/php/materiais_insert.php' : '/php/materiais_update.php'    
    await AxiosPost(`${Config.dominio}${_acao}`, 
                        JSON.stringify({
                          id: this.props.id,
                          codigo: this.state.codigo,
                          descricao: this.state.descricao,
                          unidade_medida: this.state.unidade_medida,
                          quantidade: this.state.quantidade,
                          potencia: this.state.potencia,
                          consumo: this.state.consumo,
                          grupo_id: this.state.grupo_id,
                          local: sessionStorage.getItem('local'),
                          chaveMunicipio: usuario().municipio.chave
                        })
    )
    .then(async function (response) {
      toast.success('Material armazenado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: ReactDOM.render(<LstMateriais />, document.getElementById('Content'))
        })
      
    })
    .catch(function (erro) { 
      console.error(erro) 
    })
  }

  componentWillMount(){
    this.listarUnidadeMedida()
    this.listarGrupos()
  }

  async componentDidMount(){
    if (this.props.id>0) {
      await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
                        JSON.stringify({
                          id: this.props.id,
                          local: sessionStorage.getItem('local'),
                          chaveMunicipio: usuario().municipio.chave                          
                        })
      )
      .then(async function (response) {
        this.setState({
          id: response.data[0].id,
          descricao: response.data[0].descricao,
          unidadeMedida: response.data[0].unidade_medida,
          potencia: response.data[0].potencia,
          consumo: response.data[0].consumo,
          grupo_id: response.data[0].grupo_id,
          codigo: response.data[0].codigo
        })
      }.bind(this))
      .catch(erro => console.error(erro))
      this.setState({showModal: (this.props.action == "delete")})
      this.saldoEstoque()
    }
    

  }

  setGrupoMaterial(e) {
    this.setState({grupo_id: e.target.value})
  }
  
  render() {
    return (
      <>
      <Modal 
          show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir esse material?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  id="btnExcluir" 
                  onClick={this.confirmaExcluir} 
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={this.cancelaExcluir} 
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
      <form onSubmit={this.handleSubmit}>
        <ToastContainer />
        <section className="content-header">
          <h1>
            Material
          </h1>
          <ol className="breadcrumb">
            <li>
              <button 
                type="button" 
                className="btn btn-danger btn-flat" 
                onClick={() => ReactDOM.render(<LstMateriais />, document.getElementById('Content'))}
              >Voltar</button>&nbsp;&nbsp;
              <button className="btn btn-primary" type="submit" >Salvar</button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Identificação</h3>
                </div>
                <div className="box-body box-profile">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <label htmlFor="larguraVia">Código do material</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        value={this.state.codigo}
                        onChange={e => this.setState({codigo: e.target.value})}
                      />
                      <br />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label htmlFor="larguraVia">Descrição (nome)</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      value={this.state.descricao}
                      onChange={e => this.setState({descricao: e.target.value})}
                    />
                  </div>
                </div>
              </div>
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active"><a href="#dados" data-toggle="tab">Dados do Material</a></li>
                  <li><a href="#eletricas" data-toggle="tab">Características elétricas</a></li>
                  <li><a href="#abc" data-toggle="tab">Estoque</a></li>
                  { // <li><a href="#OS" data-toggle="tab">Ordens de Serviço</a></li> 
                  }
                </ul>
                <div className="tab-content">
                  <div className="active tab-pane" id="dados">
                    <div className="row">
                      <div className="col-xs-9">
                        <div className="form-group">
                          <label>Grupo</label>
                          <select 
                            className="form-control select2" 
                            style={{width: '100%'}}
                            value={this.state.grupo_id || ""}
                            onChange={e => this.setGrupoMaterial(e)}
                          >
                              {
                                this.state.lstGrupos.map((opt, index) => <option key={index} value={opt.id}>{`${String("\u00A0").repeat(opt.level*4)}${opt.descricao}`}</option>)
                              }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-5">
                        <div className="form-group">
                          <label>Unidade de Medida</label>
                          <select 
                            className="form-control select2" 
                            style={{width: '100%'}}
                            value={this.state.unidade_medida || ""}
                            onChange={e => this.setState({unidade_medida: e.target.value})}
                          >
                              {
                                this.state.lstUnidadeMedida.map((opt, index) => <option key={index} value={opt.valor}>{opt.descricao}</option>)
                              }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="eletricas">
                    <div className="row">
                      <div className="col-xs-2">
                        <div className="form-group">
                          <label htmlFor="altura">Potência</label>
                          <input 
                            type="text" 
                            className="form-control"
                            style={{textAlign: "right"}}
                            value={this.state.potencia}
                            placeholder="Watts"
                            onChange={e => this.setState({potencia: e.target.value})}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-2">
                        <div className="form-group">
                          <label>Consumo</label>
                          <input 
                            type="text" 
                            className="form-control"
                            placeholder="Watts"
                            style={{textAlign: "right"}}
                            value={this.state.consumo}
                            onChange={e => this.setState({consumo: e.target.value})}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="abc">
                    <div className="row">
                      <div className="col-xs-2">
                        <div className="form-group">
                          <label>Quantidade</label>
                          <input 
                            type="text" 
                            className="form-control"
                            value={this.state.quantidade}
                            style={{textAlign: "right"}}
                            onChange={e => this.setState({quantidade: e.target.value})}
                            readOnly={this.props.action == 'edit'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="OS">
                    <ul className="timeline timeline-inverse">
                      <li>
                        <i className="fa fa-bullhorn bg-blue" onClick={() => {
                                                                              let item = document.getElementById("123456789")
                                                                              item.style.display = (item.style.display=="none")? "block" : "none"
                                                                             }}></i>
                        <div className="timeline-item">
                          <span className="time"><i className="fa fa-clock-o"></i> 01/05/2021 12:05</span>
                          <h3 className="timeline-header">
                            Protocolo: 123456789
                          </h3>
                          <div className="timeline-body" id="123456789" style={{display:"none"}}>
                            Problema notificado: lâmpada apagada à noite<br />
                            Distribuído em 02/05/2021 Para: Luiz Henrique Souza<br />
                            Resolvido em: 02/05/2021<br />
                            Observação: Troca da lâmpada por LED 100W
                          </div>
                        </div>
                      </li>
                      <li>
                        <i className="fa fa-bullhorn bg-blue" onClick={() => {
                                                                              let item = document.getElementById("2342345")
                                                                              item.style.display = (item.style.display=="none")? "block" : "none"
                                                                             }}></i>
                        <div className="timeline-item">
                          <span className="time"><i className="fa fa-clock-o"></i> 04/05/2021 08:19</span>
                          <h3 className="timeline-header">
                            Protocolo: 2342345
                          </h3>
                          <div className="timeline-body" id="2342345" style={{display:"none"}}>
                            Problema notificado: lâmpada apagada à noite<br />
                            Distribuído em 02/05/2021 Para: Luiz Henrique Souza<br />
                            Resolvido em: 02/05/2021<br />
                            Observação: Troca da lâmpada por LED 100W
                          </div>
                        </div>
                      </li>
                      <li>
                        <i 
                          className="fa fa-bullhorn bg-blue" 
                          onClick={() => {
                                          let item = document.getElementById("678693434")
                                          item.style.display = (item.style.display=="none")? "block" : "none"
                                          }}>
                        </i>
                        <div className="timeline-item">
                          <span className="time"><i className="fa fa-clock-o"></i> 21/05/2021 16:00</span>
                          <h3 className="timeline-header">
                            Protocolo: 678693434
                          </h3>
                          <div className="timeline-body" id="678693434" style={{display:"none"}}>
                            Problema notificado: lâmpada apagada à noite<br />
                            Distribuído em 02/05/2021 Para: Luiz Henrique Souza<br />
                            Resolvido em: 02/05/2021<br />
                            Observação: Troca da lâmpada por LED 100W
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
              </div>
          </div>
        </section>
      </form>
      </>
    )
  }
} 