import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import Modal from 'simple-react-modal'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import { usuario, factoryLamp } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export class mapaGeorref extends Component {

  state = {
    result: [],
    showModal: false,
    showModalCarga: true,
    latitude: usuario().municipio.longitude,
    longitude: usuario().municipio.latitude
  }


  displayMarkers() {
    return this.state.result && this.state.result.map((ponto, index) => <Marker
      key={index} id={index}
      position={{ lat: ponto.latitude, lng: ponto.longitude }}
      icon={factoryLamp(0, false)}
    />
    )

  }

  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/georreferenciamento/lerPontosPaginados.php`,
      JSON.stringify({
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
      })
    )
      .then(async (response) => {
        this.setState({
          result: response.data,
          showModalCarga: false
        })
      })
      .catch(erro => console.log(erro))
  }

  render() {
    console.log(this.state)
    return (
      <>
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={100}
          onClose={() => this.setState({ showModal: false })}
        >
          <div className="box box-info">
            <div className="box-header">
              Dados do Ponto
            </div>
            <div className="box-body">
              Endereço: <br />
              Lâmpada:
            </div>
            <div className="box-footer">
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showModalCarga}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={100}>
          <center>
            <ClipLoader className="override" color={"#123abc"} loading={true} />
          </center>
        </Modal>
        <section className="content-header">
          <h1>
            Mapa Geral
            <small>Parque de Iluminação Pública
            </small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button
                type="button"
                className="btn btn-default"
                data-toggle="control-sidebar"
                data-target="#menuMapa"
              >
                <i className="fa fa-sliders"></i>
              </button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="box box-info">
            <div className="box-header">
              <div className="pull-right box-tools">
              </div>
              <div className="row">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-map-marker"></i>&nbsp;
                <h3 className="box-title">Localização</h3>
              </div>
            </div>
            <div className="box-body" >
              <div className="form-group" style={{ height: '460px' }}>
                <Map
                  id="Mapa"
                  google={this.props.google}
                  zoom={10}
                  containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                  center={{ lat: this.state.latitude, lng: this.state.longitude }}
                >
                  {this.state.result && this.displayMarkers()}
                </Map>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

} export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey,
    language: 'pt-BR'
  }
  ))(mapaGeorref)
