
import React, { Component, useEffect, useState } from 'react'
import HeaderSimples from '../../../common/HeaderSimples'
import Config from '../../../../config'
import { AxiosPost } from '../../../../lib/utils3'
import { usuario } from '../../../../lib/utils2'
import ClipLoader from "react-spinners/ClipLoader"

const initialValue = {
    periodoInicial: '',
    periodoFinal: '',
    statusOs: '',
    local: '',
    problema: '',
    tipoRelatorio: '',
    ponto: 0,
}

function RelatorioMateriais() {
    const [spinner, setSpinner] = useState(false)
    const [values, setValue] = useState(initialValue);
    const [listaEquipes, setListaEquipes] = useState([]);
    const [listaMateriais, setListaMateriais] = useState([]);
    const [listaGrupos, setListaGrupos] = useState([]);


    function changeValue(e) {
        const { name, value } = e.target;
        setValue({ ...values, [name]: value });
    }

    const materiais = async () => {
        try {
            await AxiosPost(`${Config.dominio}/php/materiais_list.php`, JSON.stringify({ a: 1 }))
                .then(async (response) => {
                    if (response.data.length === 0) {
                        alert("Não possui materiais cadastrados. Por favor, cadastre o material");
                    } else {
                        setListaMateriais(response.data);
                    }
                });

        } catch (e) {
            alert("Não foi possível carregar a lista de materiais. Contate o administrador do sistema.");
            console.log(e);
        }
    }

    const grupos = async () => {
        try {
            await AxiosPost(`${Config.dominio}/php/grupoMateriais_list.php`, JSON.stringify({ a: 1 }))
                .then(async (response) => {
                    if (response.data.length === 0) {
                        alert("Não possui grupos de materiais cadastrados. Por favor, cadastre o grupo de materiais.");
                    } else {
                        setListaGrupos(response.data);
                    }
                });
        } catch (e) {
            alert("Não foi possível carregar a lista de grupos. Contate o administrador do sistema.");
            console.log(e);
        }
    }

    const equipes = async () => {
        try {
            await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`, JSON.stringify({ a: 1 }))
                .then(async (response) => {
                    if (response.data.length === 0) {
                        alert("Não possui equipe cadastrada. Por favor, cadastre a equipe.");
                    } else {
                        setListaEquipes(response.data);
                    }
                });
        } catch (e) {
            alert("Não foi possível carregar a lista de técnicos, contate o administrador do sistema.");
            console.log(e);
        }
    }

    function verificaForm() {
        document.forms["formPrint"].submit()
    }

    function habilitacao() {
        if (document.getElementById('sintetico').checked == true) {
            document.getElementById('ponto').disabled = true;
            document.getElementById('bairro').disabled = true;
        } else if (document.getElementById('analitico').checked == true) {
            document.getElementById('ponto').disabled = false;
            document.getElementById('bairro').disabled = false;
        }
    }

    useEffect(() => {
        materiais();
        equipes();
        grupos();
    }, []);

    return (
        <>
            <HeaderSimples titulo='Relatório' subtitulo='Materiais' />

            <form
                target="_blank"
                id="formPrint"
                action={`${Config.dominio}/php/relatorios/materiais.php`}
                method="post"
            >
   
                <input type="hidden" name="userSys" value={usuario().nome} />
                <input type="hidden" name="municipio" value={usuario().municipio.nome} />
                <input type="hidden" name="baseMunicipio" value={usuario().municipio.dbase} />
                <div className="col-md-6">
                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Materiais</h3>
                        </div>
                        <div className="box-body">
                            <div>
                                <label className="radio-inline"><input type="radio" value="E" name="movimentacaoMaterial" defaultChecked onChange={changeValue} /> Aplicados</label>
                                <label className="radio-inline"><input type="radio" value="S" name="movimentacaoMaterial" onChange={changeValue} />Removidos</label>
                                <label className="radio-inline"><input type="radio" value="" name="movimentacaoMaterial" onChange={changeValue} />Todos</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Tipo de relatório</h3>
                        </div>
                        <div className="box-body">
                            <div>
                                <label className="radio-inline"><input type="radio" value="analitico" name="tipoRelatorio" id="analitico" onClick={habilitacao} defaultChecked onChange={changeValue} />Analítico</label>
                                <label className="radio-inline"><input type="radio" value="sintetico" name="tipoRelatorio" id="sintetico" onClick={habilitacao} onChange={changeValue} />Sintético</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Período</h3>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Início:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date"
                                                    name="periodoInicial"
                                                    className="form-control pull-right"
                                                    id="periodoInicial"
                                                    onChange={changeValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Final:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date"
                                                    name="periodoFinal"
                                                    className="form-control pull-right"
                                                    id="periodoFinal"
                                                    onChange={changeValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="button" className="btn btn-block btn-success" onClick={() => verificaForm()}>Gerar relatório</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <div className="box-body">
                                <div className="row mb-5">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="material">Materiais:</label>
                                            <div className="">
                                                <select id="material" name="material" onChange={changeValue} className="form-control">
                                                    <option value="">Selecione...</option>
                                                    {listaMateriais.map(opt =>
                                                        <option
                                                            key={opt.id}
                                                            value={opt.descricao}
                                                        >
                                                            {opt.descricao}
                                                        </option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="equipe">Equipes:</label>
                                            <select id="equipe" name="equipe" onChange={changeValue} className="form-control">
                                                <option value="">Selecione...</option>
                                                {listaEquipes.map(opt =>
                                                    <option
                                                        key={opt.id}
                                                        value={opt.nome}
                                                    >
                                                        {opt.nome}
                                                    </option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Rua:</label>
                                            <input type="text" name="rua" className="form-control" id="rua" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Ponto:</label>
                                            <input type="number" name="ponto" className="form-control" id="ponto" onChange={changeValue} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Bairro:</label>
                                            <input type="text" name="bairro" className="form-control" id="bairro" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ClipLoader
                css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
                color={"#123abc"}
                loading={spinner}
            />
        </>
    );
}
export default RelatorioMateriais;



