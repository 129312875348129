import React, { useEffect, useState } from 'react';
import './list-alerts-device.css';
import { ALERTS_ICONS, ALERTS_MESSAGE } from './enumTelegestao';

const ListAlertsDevice = (props) => {
  const [historicAlertsState, setHistoricAlertsState] = useState([]);

  useEffect(() => {
    setHistoricAlertsState(props.alertsDevice)
  }, [props.alertsDevice]);

  return (
    <div
      className="alerts-list-container simple-data-container"
    >
      {/* <ul className="alerts-list">
        {console.log('historico alertas', historicAlertsState)}
        {historicAlertsState.map((alert, index) => (
          <li
            key={index}
          >
            <div className='form-group'>
              <img height="38" width="38" src={ALERTS_ICONS[alert.type]} alt={ALERTS_MESSAGE[alert.type]} />
              <span>{ALERTS_MESSAGE[alert.type]}</span>
            </div>
          </li>

        ))}
      </ul> */}
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th colSpan={"3"} style={{textAlign: "center", whiteSpace: "nowrap"}}>Histórico de alertas</th>
          </tr>
        </thead>
        <tbody>
          {historicAlertsState.map((alert, index) => (
            <tr key={index}>
              <td style={{textAlign: "center", whiteSpace: "nowrap"}}><img height="38" width="38" src={ALERTS_ICONS[alert.type]} alt={ALERTS_MESSAGE[alert.type]} /></td>
              <td style={{textAlign: "center", whiteSpace: "nowrap"}}><span>{ALERTS_MESSAGE[alert.type]}</span></td>
              <td style={{textAlign: "center", whiteSpace: "nowrap"}}><span>{alert.create_at}</span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}
export default ListAlertsDevice;
