import React from 'react';
import Modal from 'simple-react-modal';


const ModalCancelar = ({showCancelarOS, protocolo, desc_problema, handleCancelar = () => {}, onClose = () =>{}}) => (
    
        <Modal 
          show={showCancelarOS} 
          containerStyle={{background: 'white', width: '507px', minHeight: '410px'}} 
          transitionSpeed={100}
        >
            <section className="content">
              <center><h3>Cancelar ordem de Serviço</h3></center>
                <div className="col-md-12">
                <div className="box box-warning">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <h4>OS selecionada: {protocolo}</h4>
                        {desc_problema}
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="row">
                      <br />
                      <center>
                        <button 
                          type="button"
                          onClick={onClose} 
                          className="btn btn-warning btn-flat"
                        >
                          Cancelar
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button 
                          type="button"
                          onClick={handleCancelar}
                          className="btn btn-info btn-flat"
                        >
                          Continuar
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </Modal>
);
export default ModalCancelar;