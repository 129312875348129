import React, { Component } from "react";


export default class veiculoMapa extends Component {
  
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <>
      Mapa de veículos
      </>
    )
  }
}