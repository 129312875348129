import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'simple-react-modal'
import CadastroDispositivos from "./cadastroDispositivos";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import GraficoDispositivo from "./graficoDispositivo";
import { processarData } from "./utilTelegestao";

const LstDispositivos = () => {
  const [showModal,setShowModal] = useState(false);
  const [arquivo,setArquivo] = useState(null);
  const [dispositivos, setDispositivos] = useState([])
  const [dispositivoExclusao,setDispositivoExclusao] = useState("");
  const [tipoModal,setTipoModal] = useState(null);
  // const dispositivo = {id_device:"fff165", id_brooker:"tctec", id_device_origin:"bottomup", id_poste: "123acx",last_id_package: "",last_update: "",id_group': "apipucos",last_lat: -8.3,last_long: -34.7,idMunicipio: "",create_at: ""}
  
  const GRUPO = {
    apipucos: "APIPUCOS"
  }

  const TIPO_MODAL = {
    EXCLUSAO: 'EXCLUSAO',
    LOTE: 'LOTE'
  }

  const FORNECEDOR = {
    bottomup:"Bottomup",
    tron:"Tron",
    kdl:"Kdl"
  }

  const BROOKER = {
    tctec: "Tctec"
  }

  useEffect(() => {
    getDispositivos();
  }, [])

  const getDispositivos = async () => {
    const resp = await Axios.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices");
    setDispositivos(resp.data);
  };

  const handleFileSelected = (event) => {
    const data = event.target.files[0]
    // console.log('evento',data);
    setArquivo(data);
    // console.log('selecionado', arquivo);
  }
  const enviarArquivo = (event) => {
    event.preventDefault()
    let formData = new FormData();
    formData.append("file", arquivo);
    formData.append("fileName", arquivo.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    // console.log("carregado",formData);
    setShowModal(!showModal)
  }

  const confirmaExcluir = () => {
    // console.log('confirmaExcluir',dispositivoExclusao);
    Axios.delete(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/${dispositivoExclusao.id_device}`)
    .then(async (res) => {
      toast.info('Dispositivo excluído com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          // console.log('toast');
          setShowModal(false);
          getDispositivos();
        }
      })
    })
    .catch((error) => {
      // console.log(error);
    })
  }

  const cancelaExcluir = () => {
    setDispositivoExclusao(null)
    setShowModal(false);
    return;
  }

  const configurarModalExclusao = (device) => {
    // console.log('configurarModalExclusao', device);
    setTipoModal(TIPO_MODAL.EXCLUSAO)
    setDispositivoExclusao(device);
    setShowModal(true);
  }

  const configurarModalLote = () => {
    // console.log('configurarModalLote');
    setTipoModal(TIPO_MODAL.LOTE)
    setShowModal(true);
  }

  return(
    <>
      <ToastContainer />
      <Modal 
        show={showModal && tipoModal === TIPO_MODAL.LOTE} 
        containerStyle={{background: 'Transparent'}} 
        transitionSpeed={400}
      >
        <form onSubmit={enviarArquivo}>
        <div className="box box-primary">
          <div className="box-body">            
              <div className="row">
                <div className="col-xs-6">
                  <div className="form-group">
                    <label>Brooker</label>
                    <select className="form-control" name="id_brooker" required>
                      <option value="">Selecione</option>
                      <option value="tctec">TCTEC</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="form-group">
                    <label>Fornecedor</label>
                    <select className="form-control" name="id_device_origin" required>
                      <option value="">Selecione</option>
                      <option value="bottomup">BottomUp</option>
                      <option value="tron">Tron</option>
                      <option value="kdl">Kdl</option>
                      <option value="tecfab">Tecfab</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-12">
                  {/* <div className="input-group"> */}
                  <input type="file" id="arquivo" placeholder="Carregar arquivo" className="form-control" onChange={handleFileSelected} required/>
                  {/* <span className="input-group-btn">
                    <button type="button" className="btn btn-info btn-flat" onClick={procurarArquivo}>Carregar</button>
                  </span> */}
                {/* </div> */}
                </div>
              </div>            
          </div>
          <div className="box-footer">
            <center>
              <button 
                id="btnEnviar" 
                type="submit"
                className="btn btn-primary btn-flat"
              >
                Enviar
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button                 
                className="btn btn-warning btn-flat"
                type="button"
                onClick={() => {setShowModal(!showModal)}}
              >
                Cancelar
              </button>
            </center>
          </div>
        </div>
        </form>
      </Modal>
      <Modal
          show={showModal && tipoModal === TIPO_MODAL.EXCLUSAO}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma a exclusão deste dispositivo?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  id="btnExcluir"
                  onClick={confirmaExcluir}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={cancelaExcluir}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
      <section className="content-header">
        <h1>Dispositivos</h1>
        {/* <ol className="breadcrumb">
          <li>
          <button className="btn btn-block btn-success" type="button" >Salvar Lote</button>&nbsp;&nbsp;
          <button className="btn btn-block btn-primary" onClick={() => ReactDOM.render(<CadastroDispositivos />, document.getElementById('Content'))}>Novos Dispositivos</button>
          </li>
        </ol> */}
        <ol className="breadcrumb">
            <li>
              <button 
                type="button" 
                className="btn btn-success btn-flat" 
                onClick={configurarModalLote}
              >Salvar Lote</button>&nbsp;&nbsp;
              <button className="btn btn-primary" onClick={() => ReactDOM.render(<CadastroDispositivos />, document.getElementById('Content'))} >Novos Dispositivos</button>
            </li>
          </ol>
      </section>
      <section className="content">
        <div className="box" style={{paddingBottom:"10px"}}>
          <p />
          <div className="row">
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  {/* <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button> */}
                  {/* <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="GrupoMateriais"}>Grupo de Materiais</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Descrição"}>Descrição</a></li>
                  </ul> */}
                </div>
                <input type="text" id="pesquisar" placeholder="Fornecedor" className="form-control" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat">Pesquisar</button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-12">            
              <div className="table-responsive">
                <table className="table table-bordered table-striped" style={{width:"1280px"}}>
                  <thead>
                    <tr>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>ID Dispositivo</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Brooker</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Fornecedor</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>ID Poste</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Firmware</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Tensão</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Corrente</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Dimmer</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Estado</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Potência Ativa</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Potência Reativa</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Fator de Potência</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Grupo</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Atualização</th>
                      <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dispositivos.map((disp) => (<tr key={disp.id_device}>
                        <td style={{whiteSpace: "nowrap"}}>{disp.id_device}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BROOKER[disp.id_brooker]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{FORNECEDOR[disp.id_device_origin]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.id_poste}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.firmware_version ? disp.telemetria.firmware_version : ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.tension ? disp.telemetria.tension : "0"}V</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.current ? disp.telemetria.current : "0"}A</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.dimmer ? disp.telemetria.dimmer : "0"}%</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria ? disp.telemetria.is_access ? "Ligado" : "Desligado" : ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.pa ? disp.telemetria.pa : "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.pr ? disp.telemetria.pr : "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.pf ? disp.telemetria.pf : "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.group?.name}</td>
                        <td style={{whiteSpace: "nowrap"}}>{disp.telemetria?.create_at ? disp.telemetria?.create_at: ""}</td>
                        <td style={{textAlign: "center"}}>
                          <div style={{width:"150px"}}>
                            <div className="btn-group">
                              <button type="button" className="btn btn-primary" onClick={()=>ReactDOM.render(<GraficoDispositivo dispositivo={disp} />, document.getElementById('Content'))}><i className="fa fa-bar-chart" aria-hidden="true"></i></button>
                              <button type="button" className="btn btn-success" onClick={()=>ReactDOM.render(<CadastroDispositivos dispositivo={disp} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                              <button type="button" className="btn btn-danger" onClick={() => {configurarModalExclusao(disp)}} ><i className="fa fa-trash"></i></button>
                            </div>
                          </div>
                        </td>
                      </tr>))
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                    <th style={{width:"1px", whiteSpace: "nowrap"}}>ID Dispositivo</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Brooker</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Fornecedor</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>ID Poste</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Firmware</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Tensão</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Corrente</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Dimmer</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Estado</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Potência Ativa</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Potência Reativa</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Fator de Potência</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Grupo</th>
                      <th style={{width:"1px", whiteSpace: "nowrap"}}>Atualização</th>
                      <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default LstDispositivos