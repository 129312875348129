import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Login from './login'
import Actions from './actions'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/actions" component={Actions} />
      </Switch>
    </BrowserRouter>
  )
}