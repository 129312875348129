import { center } from "@turf/turf";
import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet"

const FlyToPoints = (props) => {
  const [pontosState,setPontosState] = useState([]);
  const defaultPoint = [-8.043202, -34.895833]
  const map = useMap()

  const calcCenter = (points) => {
    let center = {lat:0,lng:0}

    points.forEach(point => {
      center.lat += point.lat;
      center.lng += point.lng;
    })

    center.lat = center.lat/points.length;
    center.lng = center.lng/points.length;
    return center;
  }
  // console.log(map);
  // console.log("points", props.points);

  if((props.points || props.points?.length > 0) && (props.pontosArea?.length === 0)) {
    // console.log("(props.points || props.points?.length > 0) && (props.pontosArea?.length === 0)");
    // console.log("props.points || props.points?.length > 0 && (props.pontosArea?.length === 0)",props.points);
    // console.log("flyToPoints Device props.pontosArea", props.pontosArea);
    if(pontosState !== props.points){
      setPontosState(props.points)
      let ponto = defaultPoint//props.points[0]
      // console.log("flyTopoints Device",ponto);
      for (const p of props.points) {
        if ((p.lat !== 0 && p.lat !== "") && (p.lng !== 0 && p.lng !== "")) {
          ponto = p;
          // console.log('ponto fly', p);
          break;
        }
      }
      if(ponto) {
        map.flyTo([ponto.lat || defaultPoint[0], ponto.lng || defaultPoint[1]], map.getZoom())
      }
    }
  } else if (props.pontosArea && props.pontosArea.length > 0) {
    // console.log("props.pontosArea && props.pontosArea.length > 0");
    if(pontosState !== props.pontosArea){
      setPontosState(props.pontosArea)
      // let ponto = defaultPoint//props.pontosArea[0]
      // console.log("flyTopoints Area",ponto);
      // for (const p of props.pontosArea) {
      //   if ((p.lat !== 0 && p.lat !== "") && (p.lng !== 0 && p.lng !== "")) {
      //     ponto = p;
      //     // console.log('ponto fly', p);
      //     break;
      //   }
      // }
      // if(ponto) {
        // console.log('ponto esperado', ponto);
        // console.log('ponto fly', [ponto.lat ? ponto.lat : defaultPoint[0], ponto.lng ? ponto.lng : defaultPoint[1]]);
        const ponto = pontosState?.length > 0 ? calcCenter(props.pontosArea) : defaultPoint;
        map.flyTo([ponto.lat ? ponto.lat : defaultPoint[0], ponto.lng ? ponto.lng : defaultPoint[1]], map.getZoom())
      // }
    }
  } else {
    // console.log("else");
    map.flyTo(defaultPoint, map.getZoom())
  }

  return null;
}

export default FlyToPoints
