import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from 'react-geocode'
import LstAcompanhamentoOS from './lstAcompanhamentoOS'
import { addDays, differenceInHours, format } from 'date-fns'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Utils from '../../../../lib/utils'
import { usuario } from '../../../../lib/utils2'
import { AxiosPost } from '../../../../lib/utils3'
import Config from '../../../../config'

const animatedComponents = makeAnimated()
Geocode.setApiKey(Config.apiKey)

export class baixa extends Component {

  state = {
    protocolo: null,
    OS: null,
    OSResumo: null,
    ponto: null,
    result: null,
    quantidade: 0,
    quantidadeMaterialRemovidoItem: 0,
    MaterialRemovidoItem: null,
    lstItens: [],
    id: null,
    verDetalhesOS: false,
    dataOS: null,
    horaInicio: null,
    horaFim: null,
    atividadeRealizada: null,
    observacoesDoTecnico: null,
    lstFotos: null,
    lstStatus: [],
    lstMateriais: [],
    lstMaterial: [],          // lista de todo material cadastrado na base
    lstMaterialIncluso: [],   // lista de todo material que foi incluído no ponto pelo técnico
    lstMaterialRemovido: [],   // lista de todo material que foi removido do ponto pelo técnico
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude,
    loadingButtonSave: false,
  }


  async baixar() {
    this.setState({loadingButtonSave: true})
    let _horaInicio = new Date(this.state.dataOS + " " + String(this.state.horaInicio) + ":00")
    let _horaFim = new Date(this.state.dataOS + " " + String(this.state.horaFim) + ":00")
    if (_horaFim < _horaInicio)
      _horaFim = addDays(_horaFim, 1)

    let _lstMaterialIncluso = []
    let _lstMaterialInclusoQuantidade = []
    let _lstMaterialRemovido = []
    let _lstMaterialRemovidoQuantidade = []

    this.state.lstMaterialIncluso.map(item => {
      _lstMaterialIncluso.push(item.material.id)
      _lstMaterialInclusoQuantidade.push(item.quantidade)
    })

    this.state.lstMaterialRemovido.map(itemR => {
      _lstMaterialRemovido.push(itemR.material.id)
      _lstMaterialRemovidoQuantidade.push(itemR.quantidade)
    })
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_baixar.php`,
      JSON.stringify({
        protocolo: this.state.protocolo,
        dataOS: format(new Date(this.state.dataOS + " 00:00:00"), "yyyy-MM-dd 00:00:00"),
        horaInicio: format(_horaInicio, "yyyy-MM-dd HH:mm:00"),
        horaFim: format(_horaFim, "yyyy-MM-dd HH:mm:00"),
        atividadeRealizada: this.state.atividadeRealizada,
        observacoesDoTecnico: this.state.observacoesDoTecnico,
        lstMaterialIncluso: _lstMaterialIncluso.toString(),
        lstMaterialInclusoQuantidade: _lstMaterialInclusoQuantidade.toString(),
        lstMaterialRemovido: _lstMaterialRemovido.toString(),
        lstMaterialRemovidoQuantidade: _lstMaterialRemovidoQuantidade.toString()
      })
    )
      .then(async function (response) {
        toast.success('Baixa registrada.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstAcompanhamentoOS />, document.getElementById('Content'))
        })
      })
      .catch(erro => {
        console.error(erro)
        this.setState({loadingButtonSave: false})
      })
  }

  verDetalhesOS() {
    document.getElementById("resolucaoOS").click()
    this.setState({ verDetalhesOS: !this.state.verDetalhesOS })
  }

  incluirMaterial() {
    let lista = this.state.lstMaterialIncluso
    if (this.state.material == null) {
      alert("Por favor, adicione pelo menos um material!");
    } else if (document.getElementById("quantidadeMaterialIncluir").value == "" || this.state.quantidade === 0) {
      alert("Por favor, adicione uma quantidade válida");
    } else {
      lista.push({
        material: this.state.lstMaterial.find(item => item.id == this.state.material.value),
        quantidade: this.state.quantidade
      })
      this.setState({ lstMaterialIncluso: lista, material: null, quantidade: null })
      document.getElementById("quantidadeMaterialIncluir").value = ""
    }
  }

  removerMaterialIncluso(item) {
    let _lista = this.state.lstMaterialIncluso
    _lista.splice(item, 1)
    this.setState({ lstMaterialIncluso: _lista })
  }

  removerMaterialRemovido(item) {
    let _lista = this.state.lstMaterialRemovido
    _lista.splice(item, 1)
    this.setState({ lstMaterialRemovido: _lista })
  }

  incluirMaterialRemovido() {
    let lista = this.state.lstMaterialRemovido
    if (this.state.MaterialRemovidoItem === null) {
      alert("Por favor, adicione pelo menos um material!");
    } else if (document.getElementById("quantidadeMaterialRemover").value == "" || this.state.quantidadeMaterialRemovidoItem === 0) {
      alert("Por favor, adicione uma quantidade válida");
    } else {
      lista.push({
        material: this.state.lstMaterial.find(item => item.id == this.state.MaterialRemovidoItem.value),
        quantidade: this.state.quantidadeMaterialRemovidoItem
      })
      this.setState({ lstMaterialRemovido: lista, MaterialRemovidoItem: null, quantidadeMaterialRemovidoItem: null })
      document.getElementById("quantidadeMaterialRemover").value = ""
    }

  }

  async ponto(_id) {
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
      JSON.stringify({ id: _id })
    )
      .then(async function (response) {
        this.setState({ ponto: response.data[0].result[0] })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async OSResumo(_protocolo) {
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_resumo.php`,
      JSON.stringify({ id: _protocolo })
    )
      .then(async function (response) {
        this.setState({ OSResumo: response.data })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async materiais() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`, JSON.stringify({ a: 1 }))
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ value: item.id, label: item.codigo + ' ' + item.descricao }))
        this.setState({ lstMaterial: response.data, lstMateriais: _lista })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async listaStatus() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 5 }))
      .then(async (response) => this.setState({ lstStatus: response.data }))
      .catch(erro => console.log(erro))
  }

  async buscarLocal(e) {
    await Geocode.fromLatLng(this.state.latitude, this.state.longitude)
      .then((response) => {
        let resultado = Utils.Endereco(response)
        if (resultado.siglaUF == usuario().municipio.nome.split(' / ')[1] &&
          resultado.nomeCidade.toUpperCase() == usuario().municipio.nome.split(' / ')[0].toUpperCase()) {
          this.setState({
            cep: resultado.cep,
            logradouro: (resultado.rua + (resultado.numero ? ', ' + resultado.numero : '')),
            bairro: resultado.bairro
          })
        } else {
          toast.warning('Ponto não localizado no município. Verifique.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        }

      })
      .catch(erro => console.log(erro))
  }

  UNSAFE_componentWillMount() {
    this.buscarOS()
    this.materiais()
    this.listaStatus()
  }
  async buscarOS(){
    this.setState({ protocolo: this.props.protocolo })
    if (this.props.protocolo) {
      await AxiosPost(`${Config.dominio}/php/os/get_by_protocolo.php`,
        JSON.stringify({ protocolo: this.props.protocolo })
      )
        .then(async function (response) {
          this.setState({
            OS: response.data[0],
            latitude: response.data[0].latitude || usuario().municipio.latitude,
            longitude: response.data[0].longitude || usuario().municipio.longitude
          })
        }.bind(this))
        .catch(erro => console.log(erro))
    } else {
      toast.warning('Ordem de Serviço inexistente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => ReactDOM.render(<LstAcompanhamentoOS />, document.getElementById('Content'))
      })
    }
  }
  render() {
    return (
      <>
        <ToastContainer />
        {this.state.OS &&
          <>
            <section className="content-header">
              <h1>
                Ordem de Serviço
                <small>Baixa</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <button
                    type="button"
                    className="btn btn-danger btn-flat"
                    onClick={() => ReactDOM.render(<LstAcompanhamentoOS />, document.getElementById('Content'))}
                  >Voltar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                </li>
              </ol>
            </section>
            <section className="content">

              <div className="col-md-3">
                <button
                  className="btn btn-primary btn-block margin-bottom"
                  onClick={e => this.baixar()}
                  disabled={this.state.loadingButtonSave}
                >
                  Baixar
                </button>
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Ordem de Serviço</h3>
                    <div className="box-tools"></div>
                  </div>
                  <div className="box-body no-padding">
                    <center>
                      <h2>{this.state.protocolo}</h2>
                      {this.state.OS.nome_equipe}
                    </center>
                  </div>
                </div>
                <center>
                  {this.state.verDetalhesOS && <a href="#" onClick={() => this.verDetalhesOS()}>Ocultar Detalhes</a>}
                  {!this.state.verDetalhesOS && <a href="#" onClick={() => this.setState({ verDetalhesOS: !this.state.verDetalhesOS })}>Ver Detalhes</a>}
                </center>
              </div>
              <div className="col-md-9">
                <div id="entradaDados" className="box box-success">
                  <div className="box-body">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        {this.state.OS && this.state.OS.pontoTemporario == 1 &&
                          <li><a href="#cadastrarPonto" data-toggle="tab">Cadastro do Ponto</a></li>
                        }
                        <li className="active"><a id="resolucaoOS" href="#resolucao" data-toggle="tab">Resolução da OS</a></li>
                        { // <li><a href="#fotos" data-toggle="tab">Fotos</a></li>
                        }
                        <li><a href="#materiaisIncluidos" data-toggle="tab">Materiais incluídos</a></li>
                        <li><a href="#materiaisRemovidos" data-toggle="tab">Materiais removidos</a></li>
                        {this.state.verDetalhesOS &&
                          <>
                            <li><a href="#dadosOS" data-toggle="tab">Dados da OS</a></li>
                            <li><a href="#endereco" data-toggle="tab">Endereço</a></li>
                          </>
                        }
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane" id="dadosOS">
                          <div className="row">
                            <div className="col-xs-3">
                              <label htmlFor="abertura" data-toggle="tooltip" title="Data/Hora de Abertura da OS">Abertura</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.OS && format(new Date(this.state.OS.data_abertura), "dd/MM/yyyy HH:mm")}
                              />
                            </div>
                            <div className="col-xs-3">
                              <label htmlFor="autorizacao">Autorização</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.OS && this.state.OS.datahora_autorizacao && format(new Date(this.state.OS.datahora_autorizacao), "dd/MM/yyyy HH:mm")}
                              />
                            </div>
                            <div className="col-xs-3">
                              <label htmlFor="situacao">Prazo de execução</label>
                              <input
                                type="text"
                                className="form-control"
                                value={differenceInHours(new Date(), new Date(this.state.OS.data_abertura)) + " horas"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-6">
                              <label>Solicitante</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.OS && this.state.OS.nome_contribuinte}
                              />
                            </div>
                            <div className="col-xs-6">
                              <label>Contato</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.OS && this.state.OS.contato}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <label>Problema relatado</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.OS.descricao_problema}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="endereco">
                          <div className="row">
                            <div className="col-xs-6">
                              <label htmlFor="distrito">Distrito</label>
                              <input
                                type="text"
                                id="cep"
                                className="form-control"
                                value={this.state.OS && this.state.OS.distrito}
                              />
                            </div>
                            <div className="col-xs-6">
                              <label htmlFor="bairro">Bairro</label>
                              <input
                                type="text"
                                id="cep"
                                className="form-control"
                                value={this.state.OS && this.state.OS.bairro}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <label htmlFor="logradouro">Logradouro</label>
                              <input
                                type="text"
                                className="form-control"
                                id="logradouro"
                                value={this.state.OS && this.state.OS.rua}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="form-group" style={{ height: '200px' }}>
                            <Map
                              id="Mapa"
                              google={this.props.google}
                              zoom={16}
                              containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                              initialCenter={{ lat: this.state.OS.latitude, lng: this.state.OS.longitude }}
                              mapTypeControl={false}
                              fullscreenControl={true}
                              streetViewControl={true}
                            >
                              <Marker
                                position={new window.google.maps.LatLng(this.state.OS.latitude, this.state.OS.longitude)}
                              >
                              </Marker>
                            </Map>
                          </div>
                        </div>
                        <div className="tab-pane" id="cadastrarPonto">
                          <div className="row">
                            <div className="col-xs-6">
                              <label htmlFor="distrito">Distrito</label>
                              <input
                                type="text"
                                id="cep"
                                className="form-control"
                                value={this.state.OS && this.state.OS.distrito}
                              />
                            </div>
                            <div className="col-xs-6">
                              <label htmlFor="bairro">Bairro</label>
                              <input
                                type="text"
                                id="cep"
                                className="form-control"
                                value={this.state.OS && this.state.OS.bairro}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <label htmlFor="logradouro">Logradouro</label>
                              <input
                                type="text"
                                className="form-control"
                                id="logradouro"
                                value={this.state.OS && this.state.OS.rua}
                                
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label htmlFor="latitude">Latitude</label>
                                <input
                                  type="text"
                                  value={this.state.latitude || ""}
                                  className="form-control"
                                  
                                  onChange={event => this.setState({ latitude: event.target.value })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group">
                                <label htmlFor="longitude">Longitude</label>
                                <input
                                  type="text"
                                  value={this.state.longitude || ""}
                                  className="form-control"
                                  
                                  onChange={event => this.setState({ longitude: event.target.value })}
                                />
                              </div>
                            </div>
                            <div className="col-xs-4">
                              <div className="form-group" style={{ textAlign: 'center' }}>
                                <br />
                                <button type="button" onClick={event => this.buscarLocal(event)} className="btn btn-info btn-flat" >Buscar local</button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group" style={{ height: '200px' }}>
                            <Map
                              google={this.props.google}
                              zoom={16}
                              containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                              initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                              center={{ lat: this.state.latitude, lng: this.state.longitude }}
                              mapTypeControl={false}
                              fullscreenControl={true}
                              streetViewControl={true}
                              onClick={(t, map, coord) => {
                                const { latLng } = coord
                                this.setState({
                                  latitude: latLng.lat().toString(),
                                  longitude: latLng.lng().toString()
                                })
                              }}
                            >
                              <Marker
                                position={new window.google.maps.LatLng(this.state.latitude, this.state.OS.longitude)}
                              />
                            </Map>
                          </div>
                        </div>
                        <div className="active tab-pane" id="resolucao">
                          <div className="row">
                            <div className="col-xs-3">
                              <label htmlFor="data">Data</label>
                              <input
                                type="date"
                                className="form-control"
                                value={this.state.dataOS}
                                onChange={event => this.setState({ dataOS: event.target.value })}
                              />
                            </div>
                            <div className="col-xs-3">
                              <label htmlFor="horario">Horário</label>
                              <input
                                type="time"
                                className="form-control"
                                value={this.state.horaInicio}
                                onChange={event => this.setState({ horaInicio: event.target.value })}
                              />
                            </div>
                            <div className="col-xs-1">
                              <label htmlFor="horario">&nbsp;</label>
                              <center>às</center>
                            </div>
                            <div className="col-xs-3">
                              <label htmlFor="horario">&nbsp;</label>
                              <input
                                type="time"
                                className="form-control"
                                value={this.state.horaFim}
                                onChange={event => this.setState({ horaFim: event.target.value })}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group">
                                <label htmlFor="atividadeRealizada">Atividade realizada</label>
                                <textarea
                                  className="form-control"
                                  rows="5"
                                  onChange={event => this.setState({ atividadeRealizada: event.target.value })}
                                >{this.state.atividadeRealizada}</textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group">
                                <label htmlFor="observacoesTecnico">Observações do técnico</label>
                                <textarea
                                  className="form-control"
                                  rows="5"
                                  onChange={event => this.setState({ observacoesDoTecnico: event.target.value })}
                                >{this.state.observacoesDoTecnico}</textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="fotos">
                          <div className="form-horizontal">
                            <div className="box-body">
                              <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                  <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                                  <li data-target="#carousel-example-generic" data-slide-to="1" className=""></li>
                                  <li data-target="#carousel-example-generic" data-slide-to="2" className=""></li>
                                </ol>
                                <div className="carousel-inner">
                                  {this.state.lstFotos &&
                                    this.state.lstFotos.map((foto, index) =>
                                      <div className="item">
                                        <img className="img-responsive" src={foto.src} alt={foto.desc} style={{ witdh: "100%", height: "320px" }} />
                                        <div className="carousel-caption">
                                          {foto.desc}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <a className="left carousel-control" href="#carousel-example-generic" data-slide="prev">
                                  <span className="fa fa-angle-left"></span>
                                </a>
                                <a className="right carousel-control" href="#carousel-example-generic" data-slide="next">
                                  <span className="fa fa-angle-right"></span>
                                </a>
                              </div>
                            </div>
                            <button type="button" className="btn btn-info btn-flat">Editar fotos</button>
                          </div>
                        </div>
                        <div className="tab-pane" id="materiaisIncluidos">
                          <div id="entradaDados" className="box">
                            <div className="box-body" >
                              <div className="row">
                                <div className="col-xs-8">
                                  <div className="form-group">
                                    <label htmlFor="Material">Material</label>
                                    <Select
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      options={this.state.lstMateriais}
                                      style={{ width: '100%' }}
                                      value={this.state.material}
                                      onChange={selectedOption => this.setState({ material: selectedOption })}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label htmlFor="quantidade">Quantidade</label>
                                    <input
                                      id="quantidadeMaterialIncluir"
                                      type="number"
                                      style={{ textAlign: "right" }}
                                      className="form-control"
                                      placeholder="quantidade"
                                      onChange={event => this.setState({ quantidade: event.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label htmlFor="botao">&nbsp;</label>
                                    <button
                                      type="button"
                                      onClick={() => this.incluirMaterial()}
                                      className="btn btn-block btn-primary"
                                    >
                                      Incluir
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <table className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Código</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Tipo</th>
                                      <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.lstMaterialIncluso.map((item, index) =>
                                        <tr key={index}>
                                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                                          <td>{item.material.codigo}</td>
                                          <td>{item.material.grupo_desc}</td>
                                          <td>{item.material.descricao}</td>
                                          <td style={{ textAlign: "right" }}>{item.quantidade}</td>
                                          <td><button style={{ display: `${this.props.action ? 'none' : 'block'}` }} type="button" className="btn btn-danger" onClick={() => this.removerMaterialIncluso(index)}><i className="fa fa-trash"></i></button></td>
                                        </tr>
                                      )
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="materiaisRemovidos">
                          <div className="box">
                            <div className="box-body" >
                              <div className="row">
                                <div className="col-xs-8">
                                  <div className="form-group">
                                    <label htmlFor="Material">Material</label>
                                    <Select
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      options={this.state.lstMateriais}
                                      style={{ width: '100%' }}
                                      value={this.state.MaterialRemovidoItem}
                                      onChange={selectedOption => this.setState({ MaterialRemovidoItem: selectedOption })}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label htmlFor="quantidade">Quantidade</label>
                                    <input
                                      type="number"
                                      id="quantidadeMaterialRemover"
                                      style={{ textAlign: "right" }}
                                      className="form-control"
                                      placeholder="quantidade"
                                      onChange={event => this.setState({ quantidadeMaterialRemovidoItem: event.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label htmlFor="botao">&nbsp;</label>
                                    <button
                                      type="button"
                                      onClick={() => this.incluirMaterialRemovido()}
                                      className="btn btn-block btn-primary"
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <table className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Código</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Tipo</th>
                                      <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                      <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.lstMaterialRemovido.map((item, index) =>
                                        <tr key={index}>
                                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                                          <td>{item.material.codigo}</td>
                                          <td>{item.material.grupo_desc}</td>
                                          <td>{item.material.descricao}</td>
                                          <td style={{ textAlign: "right" }}>{item.quantidade}</td>
                                          <td><button type="button" className="btn btn-danger" onClick={() => this.removerMaterialRemovido(index)} style={{ display: `${this.props.action ? 'none' : 'block'}` }} ><i className="fa fa-trash"></i></button></td>
                                        </tr>
                                      )
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </>
    )
  }

}
export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey,
    language: 'pt-BR'
  }
  ))(baixa) 