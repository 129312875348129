import React, { Component } from 'react'
import { format } from 'date-fns'
import { PieChart, Pie, Legend, Tooltip, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts'
import { usuario, lstAgrupamentoPeriodo, trataPeriodo } from '../../../lib/utils2'
import { AxiosPost, AxiosGet } from '../../../lib/utils3'
import Config from '../../../config'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#7DB3FF', '#49457B', '#FF7C78', '#FED3D0', '#6F76D9', '#9ADFB4', '#2E7987'];

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const legendStyle = {
  color: 'blue'
}

export default class dashboardOperacional extends Component {

  constructor(props) {
    super(props);
    this.state = {
      latitude: usuario().municipio.latitude,
      longitude: usuario().municipio.longitude,
      periodo: 0,
      novasReclamacoes: 0,
      resolucao48Horas: 0,
      falsasReclamacoes: 0,
      totalOrdensServicos: 0,
      statusOS: [],
      totalOsPorEquipe: [],
      totalMateriaisSaida: [],
      totalMateriaisEntrada: [],
      rangeDate: null,
      dataInicial: new Date(),
      dataFinal: null,
      periodoInicial: '',
      periodoFinal: ''

    }
    this.handleChange = this.handleChange.bind(this);
  }

  async novasReclamacoes() {
    await AxiosPost(`${Config.dominio}/php/dashboard/novasreclamacoes.php`,
      JSON.stringify({
        periodoInicial: this.state.periodoInicial.toString(),
        periodoFinal: this.state.periodoFinal.toString()
      }))
      .then(async response => this.setState({ novasReclamacoes: response.data[0].total }))
      .catch(erro => console.log(erro))
  }

  async statusOS() {
    await AxiosPost(`${Config.dominio}/php/dashboard/dashboard_total_status.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async (response) => {
        let lista = [];
        response.data.map(item => lista.push({ name: item.name, value: parseInt(item.value) }))
        this.setState({ statusOS: lista })
      })
      .catch(erro => console.log(erro))
  }

  async resolucao48Horas() {
    await AxiosPost(`${Config.dominio}/php/dashboard/resolucao48Horas.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async response => this.setState({ resolucao48Horas: Number(response.data[0].percentualResolvido).toFixed(2) }))
      .catch(erro => console.log(erro))

  }

  async falsasReclamacoes() {
    await AxiosPost(`${Config.dominio}/php/dashboard/falsasreclamacoes.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async response => this.setState({ falsasReclamacoes: response.data[0].total }))
      .catch(erro => console.log(erro))
  }

  async totalOrdensServicos() {
    await AxiosPost(`${Config.dominio}/php/dashboard/totalOrdensServicos.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async response => this.setState({ totalOrdensServicos: response.data[0].total }))
      .catch(erro => console.log(erro))
  }

  async osPorEquipe() {
    await AxiosPost(`${Config.dominio}/php/dashboard/dashboard_os_por_equipe.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async (response) => {
        let lista = [];
        response.data.map(item => lista.push({ name: item.name, value: parseInt(item.value) }))
        this.setState({ totalOsPorEquipe: lista })
      })
      .catch(erro => console.log(erro))
  }

  async totalMateriaisSaida() {
    await AxiosPost(`${Config.dominio}/php/dashboard/dashboard_total_materiais_utilizados.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async (response) => {
        let lista = [];
        response.data.map(item => lista.push({ name: item.name, value: parseInt(item.value) }))
        this.setState({ totalMateriaisSaida: lista })
      })
      .catch(erro => console.log(erro))
  }

  async totalMateriaisEntrada() {
    await AxiosPost(`${Config.dominio}/php/dashboard/dashboard_total_materiais_utilizados_entrada.php`,
      JSON.stringify({
        inicio: this.state.periodoInicial,
        fim: this.state.periodoFinal
      }))
      .then(async (response) => {
        let lista = [];
        response.data.map(item => lista.push({ name: item.name, value: parseInt(item.value) }))
        this.setState({ totalMateriaisEntrada: lista })
      })
      .catch(erro => console.log(erro))
  }

  handleChange(e) {
    let target = e.target.name;
    let value = e.target.value;
    this.setState({ [target]: value })
  }

  filtraPorData() {
    if (this.state.periodoInicial == '' || this.state.periodoFinal == '') {
      alert("É necessário inserir um período para gerar os gráficos.")
    } else {
      this.novasReclamacoes();
      this.resolucao48Horas();
      this.falsasReclamacoes();
      this.totalMateriaisSaida();
      this.totalMateriaisEntrada();
      this.statusOS();
      this.osPorEquipe();
    }
  }

  novoPeriodo(e) {
    let periodo = e.target.value;
    this.setState({ periodo: periodo });
    //this.novasReclamacoes(periodo);
    //this.resolucao48Horas(periodo);
    //this.falsasReclamacoes(periodo);
    //this.statusOS(periodo);
    //this.totalOrdensServicos(periodo);
    //this.osPorEquipe(periodo);
    //this.totalMateriaisSaida();
    //this.totalMateriaisEntrada(periodo);
  }

  componentDidMount() {
    //this.novasReclamacoes(this.state.periodo);
    //this.resolucao48Horas(this.state.periodo);
    //this.falsasReclamacoes(this.state.periodo);
    //this.statusOS(this.state.periodo);
    //this.totalOrdensServicos(this.state.periodo);
    //this.osPorEquipe(this.state.periodo);
    //this.totalMateriaisSaida();
    //this.totalMateriaisEntrada(this.state.dataInicial, this.state.dataFinal);
  }

  render() {
    let os_atendidas = [
      { name: 'Até 12 horas', value: 560 },
      { name: 'Mais de 12 horas', value: 1040 }
    ]

    return (

      <>
        <section className="content-header">
          <h1>
            Dashboard
            <small>Operacional</small>
          </h1>
        </section>
        <section className="content">
          <div className="row ">
            <div className="form-group">
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-aqua">
                <div className="inner">
                  <h3>{this.state.novasReclamacoes}</h3>
                  <p>Novas reclamações</p>
                </div>
                <div className="icon">
                  <i className="ion ion-speakerphone"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-green">
                <div className="inner">
                  <h3>{this.state.resolucao48Horas}<sup style={{ fontSize: '20px' }}>%</sup></h3>
                  <p>Resoluções em até 48 horas</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-yellow">
                <div className="inner">
                  <h3>{this.state.falsasReclamacoes}</h3>
                  <p>Falsas reclamações</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>

              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box box-default">
                  <div className="box-header">
                    <h3 className="box-title">Período</h3>
                    <div className="box-body">
                      <div className="row">
                        <div className="col-xs-4">
                          <div className="form-group">
                            <label>Início:</label>
                            <div className="input-group date">
                              <div className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </div>
                              <input type="date" className="form-control pull-right" onChange={e => this.setState({ periodoInicial: e.target.value })} />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div className="form-group">
                            <label>Final:</label>
                            <div className="input-group date">
                              <div className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </div>
                              <input type="date" className="form-control pull-right" onChange={e => this.setState({ periodoFinal: e.target.value })} />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-4" style={{ marginTop: '25px' }}>
                          <button type="button" className="btn btn-block btn-success" onClick={e => this.filtraPorData()}>Gerar gráficos</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <section className="col-lg-6 connectedSortable">
              <div className="box box-success">
                <div className="box-header">
                  <h3 className="box-title">Ordens de serviço</h3>
                </div>
                <div className="box-body" id="chat-box">
                  <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                      <li className="active"><a href="#os_total" data-toggle="tab">Status</a></li>
                      <li><a href="#os_equipe" data-toggle="tab">Equipe</a></li>

                    </ul>
                    <div className="tab-content no-padding">
                      <div className="chart tab-pane active" id="os_total" style={{ position: 'relative', height: '350px' }}>
                        <div className="col-md-12">
                          <div align="center">
                            {this.state.statusOS.length == 0 ?
                              <div ><center ><h3 >Sem ordens de serviço no <br />período informado!</h3></center></div> :
                              <PieChart width={500} height={350}>
                                <Pie
                                  dataKey="value"
                                  isAnimationActive={true}
                                  data={this.state.statusOS}
                                  cx={250}
                                  cy={150}
                                  outerRadius={100}
                                  fill="#8884d8"
                                >
                                  {this.state.statusOS.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                                </Pie>
                                <Tooltip />
                              </PieChart>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="chart tab-pane" id="os_equipe" style={{ position: 'relative', height: '350px' }}>
                        {this.state.totalOsPorEquipe.length == 0 ?
                          <div ><center ><h3>Sem ordens de serviço no <br />período informado!</h3></center></div> :
                          <div className="col-md-5">
                            <div align='center'>

                              <PieChart width={500} height={350}>
                                <Pie
                                  dataKey="value"
                                  isAnimationActive={true}
                                  data={this.state.totalOsPorEquipe}
                                  cx={250}
                                  cy={150}
                                  outerRadius={100}
                                  fill="#8884d8"
                                >
                                  {this.state.totalOsPorEquipe.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                                </Pie>
                                <Tooltip />
                              </PieChart>

                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="col-lg-6 connectedSortable">
              <div className="box box-success">
                <div className="box-header">
                  <h3 className="box-title">Materiais</h3>
                </div>
                <div className="box-body" id="chat-box">
                  <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                      <li className="active"><a href="#ip_rua" data-toggle="tab">Mat. aplicados</a></li>
                      <li><a href="#ip_bairro" data-toggle="tab">Removidos</a></li>
                    </ul>
                    <div className="tab-content no-padding">
                      <div className="chart tab-pane active" id="ip_rua" style={{ position: 'relative', height: '350px' }}>
                        {this.state.totalMateriaisSaida.length == 0 ?
                          <div ><center ><h3 >Sem materiais no <br />período informado!</h3></center></div> :
                          <div className="col-md-6">
                            <div align="center">
                              <PieChart width={500} height={350}>
                                <Pie
                                  dataKey="value"
                                  isAnimationActive={true}
                                  data={this.state.totalMateriaisSaida}
                                  cx={250}
                                  cy={150}
                                  outerRadius={100}
                                  fill="#8884d8"
                                >
                                  {this.state.totalMateriaisSaida.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                                </Pie>
                                <Tooltip />
                              </PieChart>

                            </div>
                          </div>
                        }
                      </div>
                      <div className="chart tab-pane" id="ip_bairro" style={{ position: 'relative', height: '350px' }}>
                        {this.state.totalMateriaisSaida.length == 0 ?
                          <div ><center ><h3 >Sem materiais no <br />período informado!</h3></center></div> :
                          <div className="col-md-12">
                            <div align='center'>
                              <PieChart width={500} height={350}>
                                <Pie
                                  dataKey="value"
                                  isAnimationActive={true}
                                  data={this.state.totalMateriaisEntrada}
                                  cx={250}
                                  cy={150}
                                  outerRadius={100}
                                  fill="#8884d8"
                                >
                                  {this.state.totalMateriaisEntrada.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                                </Pie>
                                <Tooltip />
                              </PieChart>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    )
  }
}