
export default class utils{

  static Endereco(resp) {
    var numero, cep, rua, bairro, nomeCidade, siglaUF
    var address = resp.results[0].address_components
    for (let i = 0; i < address.length; i++) {
      for (let j = 0; j < address[i].types.length; j++) {
        switch (address[i].types[j]) {
          case "street_number":
            numero = address[i].long_name
            break
          case "postal_code":
            cep = address[i].long_name
            break
          case "route":
            rua = address[i].long_name
            break
          case "sublocality":
            bairro = address[i].long_name
            break
          case "administrative_area_level_2":
            nomeCidade = address[i].long_name
            break
          case "administrative_area_level_1":
            siglaUF = address[i].short_name
            break
          default:
            break
        }
      }
    }
    return  {
      numero: numero, 
      cep: cep, 
      rua: rua, 
      bairro: bairro, 
      nomeCidade: nomeCidade, 
      siglaUF: siglaUF
    }
  }

  static gerarChave() {
    let parte1 = ((new Date()).getTime()*2024719).toString().substring(6,12)
    let parte2 = ((new Date()).getTime()*2472379).toString().substring(6,12)
    let parte3 = ((new Date()).getTime()*7472349).toString().substring(6,12)
    let parte4 = ((new Date()).getTime()*1942389).toString().substring(6,12)
    return parte1+"-"+parte2+"-"+parte3+"-"+parte4
  }
  

}