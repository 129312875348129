import { getYear, getMonth, 
    endOfWeek, startOfWeek, startOfWeekYear, addWeeks,
    startOfMonth, lastDayOfMonth, addMonths, 
    startOfYear, lastDayOfYear,
    addDays, differenceInDays, getDate, format, locale } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import lampBranca from '../assets/icons/lampada_sem_telegestao_branca.png'
import lampAzul from '../assets/icons/lampada_sem_telegestao_azul.png'
import lampVerde from '../assets/icons/lampada_sem_telegestao_verde.png'
import lampAmarela from '../assets/icons/lampada_sem_telegestao_amarela.png'
import lampVermelha from '../assets/icons/lampada_sem_telegestao_vermelha.png'
import lampRoxo from '../assets/icons/lampada_sem_telegestao_roxo.png'
import lampTGBranca from '../assets/icons/lampada_com_telegestao_branca.png'
import lampTGAzul from '../assets/icons/lampada_com_telegestao_azul.png'
import lampTGVerde from '../assets/icons/lampada_com_telegestao_verde.png'
import lampTGAmarela from '../assets/icons/lampada_com_telegestao_amarela.png'
import lampTGVermelha from '../assets/icons/lampada_com_telegestao_vermelha.png'
import lampTGRoxo from '../assets/icons/lampada_com_telegestao_roxo.png'


/// FUNÇÕES DE CRIPTOGRAFIA

export function criptografar(dados='') {
    if (dados.length == 0) return dados
	var mensx="";
	var l;
	var i;
	var j=0;
	var ch;
	ch = "LHljhljHlhlKJH454lkJHl*kjhlkjh73LKjhljHljhlJhlkjHlkjhLjhlkjh$lKjhljK##jhiuytu@876jj09769";	
	for (i=0;i<dados.length; i++){
		j++;
		l=(Asc(dados.substr(i,1))+(Asc(ch.substr(j,1))));
		if (j==50){
			j=1;
		}
		if (l>255){
			l-=256;
		}
		mensx+=(Chr(l));
	}
	return mensx
}

export function descriptografar(dados='') {
    if (dados == null) return dados
    if (dados.length == 0) return dados
	var mensx="";
	var l;
	var i;
	var j=0;
	var ch;
	ch = "LHljhljHlhlKJH454lkJHl*kjhlkjh73LKjhljHljhlJhlkjHlkjhLjhlkjh$lKjhljK##jhiuytu@876jj09769";	
	for (i=0; i<dados.length;i++){
		j++;
		l=(Asc(dados.substr(i,1))-(Asc(ch.substr(j,1))));
		if (j==50){
			j=1;
		}
		if (l<0){
			l+=256;
		}
		mensx+=(Chr(l));
	}	
	return mensx
}

function Asc(String){
	return String.charCodeAt(0);
}
 
function Chr(AsciiNum){
	return String.fromCharCode(AsciiNum)
}
/// FUNÇÕES DE CRIPTOGRAFIA

// FUNÇÕES DE ACESSO
export function usuario() { return JSON.parse(descriptografar(sessionStorage.getItem("usuario"))) }
export function lstCidades() { return JSON.parse(descriptografar(sessionStorage.getItem('lstCidades'))) }
// FUNÇÕES DE ACESSO

// FUNÇÕES VISUAIS
function Interpolate(start, end, steps, count) {
    let s = start, 
        e = end, 
        final = s + (((e - s) / steps) * count)
    return Math.floor(final)
}

function Color(_r, _g, _b) {
    let r, g, b
    this.setColors = function(_r, _g, _b) {
        r = _r
        g = _g
        b = _b
    }
    //setColors(_r, _g, _b)
    this.getColors = function() {
        let colors = { r: r, g: g, b: b }
        return colors;
    }
}

function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

export function Cor(index, totalItems=2) {
    let val = parseInt((100/(totalItems-1)) * index),
        green = new Array(6, 170, 60),
        yellow = new Array(255, 255, 0),
        red = new Array(232, 9, 26),
        start = green,
        end = yellow

    if (val > 50) {
        start = yellow
        end = red
        val = val % 51
    }
    // let startColors = start.getColors()
    // let endColors = end.getColors()
    let r = Interpolate(start[0], end[0], 50, val)
    let g = Interpolate(start[1], end[1], 50, val)
    let b = Interpolate(start[2], end[2], 50, val)
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
}
// FUNÇÕES VISUAIS

// FUNÇÕES DE DATA
export function lstPeriodo(hasPanel = true) {
    if (hasPanel) return [
            {value:"7",   label:"Semanal"},
            {value:"15",  label:"Quinzenal"},
            {value:"30",  label:"Mensal"},
            {value:"90",  label:"Trimestral"},
            {value:"180", label:"Semestral"},
            {value:"365", label:"Anual"},
            {value:"0",   label:"Painel"},
            {value:"-1",  label:"Personalizado"}
           ]
    return [
            {value:"7",   label:"Semanal"},
            {value:"15",  label:"Quinzenal"},
            {value:"30",  label:"Mensal"},
            {value:"90",  label:"Trimestral"},
            {value:"180", label:"Semestral"},
            {value:"365", label:"Anual"},
            {value:"-1",  label:"Personalizado"}
           ]
}

export function lstAgrupamentoPeriodo() {
    return [
            {value:"0",   label:"Diário"},
            {value:"7",   label:"Semanal"},
            {value:"15",  label:"Quinzenal"},
            {value:"30",  label:"Mensal"},
            {value:"90",  label:"Trimestral"},
            {value:"180", label:"Semestral"},
            {value:"365", label:"Anual"}
           ]
}

export function trataPeriodo(tipoPeriodo, dataInicio="", dataFim="", Painel=null) {
    let agora = new Date()
    let tmpAgoraInicio = agora
    let _trataPeriodo = { 
                            hoje: agora,
                            inicio: agora,
                            fim: agora,
                            iniciorelativo: 0,
                            fimrelativo: 0
                        }

    switch (String(tipoPeriodo)) {
        case "0":
            if (Painel != null) {
                _trataPeriodo.inicio = trataPeriodo(Painel.periodo, Painel.periodoInicio, Painel.periodoFim).inicio
                _trataPeriodo.fim = trataPeriodo(Painel.periodo, Painel.periodoInicio, Painel.periodoFim).fim
            }
            break
        case "7":
            // Início: primeiro dia da semana (segunda-feira)
            _trataPeriodo.inicio = startOfWeek(_trataPeriodo.hoje,  { weekStartsOn: 1 })
            _trataPeriodo.fim = endOfWeek(_trataPeriodo.hoje,  { weekStartsOn: 1 })
            break
        case "15":
            _trataPeriodo.inicio = (_trataPeriodo.hoje.getDay() <= 15) ? startOfMonth(_trataPeriodo.hoje): new Date(_trataPeriodo.hoje.getFullYear(), _trataPeriodo.hoje.getMonth(), 16)
            _trataPeriodo.fim = (_trataPeriodo.hoje.getDay() > 15) ? lastDayOfMonth(_trataPeriodo.hoje): new Date(_trataPeriodo.hoje.getFullYear(), _trataPeriodo.hoje.getMonth(), 16)
            break
        case "30":
            _trataPeriodo.inicio = startOfMonth(_trataPeriodo.hoje)
            _trataPeriodo.fim = lastDayOfMonth(_trataPeriodo.hoje)
            break
        case "90":
           while ([0,3,6,9].indexOf(tmpAgoraInicio.getMonth()) == -1)  addMonths(tmpAgoraInicio, -1)
           _trataPeriodo.inicio = startOfMonth(tmpAgoraInicio)
           _trataPeriodo.fim = lastDayOfMonth(addDays(_trataPeriodo.inicio, 75))
           break
        case "180":
            while ([0,6].indexOf(tmpAgoraInicio.getMonth()) == -1)  addMonths(tmpAgoraInicio, -1)
            _trataPeriodo.inicio = startOfMonth(tmpAgoraInicio)
            _trataPeriodo.fim = lastDayOfMonth(addDays(_trataPeriodo.inicio, 165))
            break
        case "365":
            _trataPeriodo.inicio = startOfYear(_trataPeriodo.hoje)
            _trataPeriodo.fim = lastDayOfYear(_trataPeriodo.hoje)
            break
        case "-1":
            // Personalizado
            _trataPeriodo.inicio = (String(dataInicio).length == 0 ) ? agora : (String(dataInicio).length == 10)? new Date(dataInicio + ' 00:00'): addDays (agora, dataInicio)
            _trataPeriodo.fim = (String(dataFim).length == 0 ) ? agora : (String(dataFim).length == 10)? new Date(dataFim + ' 00:00'): addDays (agora, dataFim)
            break
        default:
            _trataPeriodo.inicio = agora
            _trataPeriodo.fim = agora
    }
    _trataPeriodo.iniciorelativo = differenceInDays(_trataPeriodo.inicio, _trataPeriodo.hoje)
    _trataPeriodo.fimrelativo = differenceInDays(_trataPeriodo.fim, _trataPeriodo.hoje)
    return _trataPeriodo
  }

export function agrupamentoPeriodo(tipoAgrupamento = 0, dataReferencia = new Date()) {
    switch (String(tipoAgrupamento)) {
        case "7":
            return format(dataReferencia, 'w/yyyy', {weekStartsOn: 1})
        case "15":
            return dataReferencia.getDate() < 16 ? format(dataReferencia, '01/MM/yyyy'): format(dataReferencia, '16/MM/yyyy')
        case "30":
            return format(dataReferencia, 'MMM/yyyy', {locale: ptBR ,})
        case "90":
            return format(dataReferencia, 'Q/yyyy')
        case "180":
            return format(dataReferencia, 'Q')<3 ? format(dataReferencia, '1/yyyy') : format(dataReferencia, '2/yyyy') 
        case "365":
            return format(dataReferencia, 'yyyy')
        default:
            return format(dataReferencia, "dd/MM/yyyy")
    }
}

export function labelAgrupamentoPeriodo(tipoAgrupamento = 0, dataReferencia='') {
    let inicio = ''
    let fim = ''
    switch (String(tipoAgrupamento)) {
        
        case "7":
            inicio = addWeeks(startOfWeekYear(new Date(dataReferencia.split('/')[1], '00','01'), {weekStartsOn: 1}), dataReferencia.split('/')[0]-1)
            fim = endOfWeek(inicio, {weekStartsOn: 1})
            return format(inicio, "dd/MM/yyyy") + ' a ' + format(fim, "dd/MM/yyyy")
        case "15":
            inicio = new Date(dataReferencia.split('/')[2], dataReferencia.split('/')[1]-1, dataReferencia.split('/')[0], '00', '01')
            fim = inicio.getDate() < 16 ? addDays(startOfMonth(inicio), 14): lastDayOfMonth(inicio)
            return format(inicio, "dd/MM/yyyy") + ' a ' + format(fim, "dd/MM/yyyy")
        case "30":
            return dataReferencia
        case "90":
            return dataReferencia.replace('/','º Trim/')
        case "180":
            return dataReferencia.replace('/','º Sem/')
        case "365":
            return dataReferencia
            break
        default:
            return dataReferencia
    }
}

export function DateToDatabase(data, format) {
    let dataSplited = data.split("/")
    let _data 
    switch (format) {
        case "dd/mm/yyyy":
            _data = dataSplited[2] + "-" + dataSplited[1] + "-" + dataSplited[0] + " 00:00"
            break
        default:
            _data = dataSplited[2] + "-" + dataSplited[1] + "-" + dataSplited[0] + " 00:00"
            break
    }
    return ( _data )
}

// FUNÇÕES DE DATA

export function factoryLamp(status=0, teleGestao=false) {
    switch (status) {
      case "100": // ponto selecionado no mapa - não é status da OS
        return teleGestao? lampTGRoxo : lampRoxo
      case "5": // ordem de serviço aguardando peças para execução
        return teleGestao? lampTGBranca : lampBranca
      case "4": // manutenção realizada
        return teleGestao? lampTGVerde : lampVerde
      case "3": // ordem de serviço distribuída para a equipe
        return teleGestao? lampTGAzul : lampAzul
      case "2": // ordem de serviço aberta e já autorizada pela prefeitura
        return teleGestao? lampTGAmarela : lampAmarela
      case "1": // ordem de serviço aberta, mas ainda não autorizado pela prefeitura
        return teleGestao? lampTGVermelha : lampVermelha
      default:
        return teleGestao? lampTGVerde : lampVerde
    }
  
  }

export  function factoryLampFile(status=0, teleGestao=false) {
    let tg = teleGestao? "com": "sem" 
    switch (status) {
        case "100": // ponto selecionado no mapa - não é status da OS
        return `/assets/icons/lampada_${tg}_telegestao_roxo.png`
        case "5": // ordem de serviço aguardando peças para execução
        return `/assets/icons/lampada_${tg}_telegestao_branca.png`
        case "4": // manutenção realizada
        return `/assets/icons/lampada_${tg}_telegestao_verde.gif`
        case "3": // ordem de serviço distribuída para a equipe
        return `/assets/icons/lampada_${tg}_telegestao_azul.png`
        case "2": // ordem de serviço aberta e já autorizada pela prefeitura
        return `/assets/icons/lampada_${tg}_telegestao_amarela.png`
        case "1": // ordem de serviço aberta, mas ainda não autorizado pela prefeitura
        return `/assets/icons/lampada_${tg}_telegestao_vermelha.png`
        default:
        return `/assets/icons/lampada_${tg}_telegestao_verde.png`
    }
}
