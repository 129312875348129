import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import Modal from 'simple-react-modal'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import { usuario, factoryLamp } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export class dashboardMapa extends Component {

  state = {
            result: [],
            resultTodos: [],
            resultLuminarias: [],
            resultTodosLuminarias: [],
            showModal: false,
            showModalCarga: true,
            pontoSelecionado: null,
            latitude: usuario().municipio.latitude,
            longitude: usuario().municipio.longitude
          }
  

  filtro (_filtro) {
    switch(_filtro) {
      case 1:
        this.setState({result: this.state.resultTodos.filter(ponto => ponto.status_os == "1")})
        break
      case 2:
        this.setState({result: this.state.resultTodos.filter(ponto => ponto.status_os == "2")})
        break
      case 3:
        this.setState({result: this.state.resultTodos.filter(ponto => ponto.status_os == "3")})
        break
      default:
        this.setState({result: this.state.resultTodos})
        break
    }
  }

  filtroLampadas(){}



  async componentDidMount(){
    await AxiosPost(`${Config.dominio}/php/dashboard/mapa_os.php`, JSON.stringify({ a:1 }))
    .then(async (response) => {
                    let resultado = response.data.filter(ponto => ['1','2','3'].includes(ponto.status_os))
                    this.setState({ resultTodos: resultado, result: resultado, showModalCarga: false }) 
    })
    .catch(erro => console.log(erro))

    await AxiosPost(`${Config.dominio}/php/dashboard/mapa_lampadas.php`, JSON.stringify({ a:1 }))
    .then(async (response) => {
        let resultado = response.data
        let lstTipoLampadas = resultado.map(item => lstTipoLampadas.push(resultado.material))
        this.setState({ resultTodosLuminarias: resultado, resultLuminarias: resultado, lstTipoLampadas: [...new Set(lstTipoLampadas)] })
    })
    .catch(erro => console.log(erro))

  }

  render () {
    return (
      <>
        <Modal 
          show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={100}
          onClose={() => this.setState({showModal:false})}
        >
          <div className="box box-info">
            <div className="box-header">
              Dados do Ponto
            </div>
            <div className="box-body">
              Endereço: <br />
              Lâmpada: LED (80W) <br />
              Última OS em: (Sem registro)
            </div>
            <div className="box-footer">
            </div>
          </div>
        </Modal>
        <Modal
         show={this.state.showModalCarga} 
         containerStyle={{background: 'Transparent'}}
         transitionSpeed={100}>
          <center>
          <ClipLoader className="override" color={"#123abc"} loading={true} />
          </center>
        </Modal>
        <section className="content-header">
          <h1>
            Mapa Geral
            <small>Parque de Iluminação Pública 
            </small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button 
                type="button" 
                className="btn btn-default" 
                data-toggle="control-sidebar"
                data-target="#menuMapa"
              >
                <i className="fa fa-sliders"></i>
              </button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="box box-info">
            <div className="box-body" >
              <div className="form-group" style={{height: '460px'}}>
                <Map
                  id="Mapa"
                  google={this.props.google}
                  zoom={10}
                  containerStyle = {{position: 'relative', width: '100%', height: '100%' }}
                  initialCenter={{ lat: usuario().municipio.latitude, lng: usuario().municipio.longitude }}
                  center={{ lat: this.state.latitude, lng: this.state.longitude }}
                >
                  {this.state.result && this.state.result.map((ponto, index ) =>  
                      <Marker 
                        key={index} id={index} 
                        position={{ lat: ponto.latitude, lng: ponto.longitude }}
                        icon={factoryLamp(ponto.status_os, (ponto.teleGestao) )}
                      />
                    )
                  } 
                </Map>
              </div>
            </div> 
          </div>
        </section>
        <aside className="control-sidebar control-sidebar-dark" id="menuMapa" style={{display: "none"}}>
          <ul className="nav nav-tabs nav-justified control-sidebar-tabs">
            <li><a href="#" data-toggle="control-sidebar" data-target="#menuMapa"><i className="fa  fa-mail-forward"></i></a></li>
            <li><a href="#control-sidebar-OS-tab" data-toggle="tab">OS</a></li>
            <li><a href="#control-sidebar-pontos-tab" data-toggle="tab">Pontos</a></li>
          </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="control-sidebar-OS-tab">
            <h3 className="control-sidebar-heading">Ordens de Serviço</h3>
            <ul className="control-sidebar-menu">
              <li>
                <a href="#" onClick={() => this.filtro(1)} >
                  <i className="menu-icon fa bg-red"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Abertos ({this.state.resultTodos && this.state.resultTodos.filter(ponto => ponto.status_os == "1").length})
                    </h4>
                    <p>Aguardando aprovação</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => this.filtro(2)} >
                  <i className="menu-icon fa bg-yellow"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Aprovados ({this.state.resultTodos && this.state.resultTodos.filter(ponto => ponto.status_os == "2").length})
                    </h4>
                    <p>Prontos para distribuição</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => this.filtro(3)} >
                  <i className="menu-icon fa bg-light-blue"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Em execução ({this.state.resultTodos && this.state.resultTodos.filter(ponto => ponto.status_os == "3").length})
                    </h4>
                    <p>Técnicos em campo</p>
                  </div>
                </a>
              </li>              
            </ul>
          </div>
          <div className="tab-pane" id="control-sidebar-pontos-tab">
            <h3 className="control-sidebar-heading">Tipos de Lâmpadas</h3>
            <ul className="control-sidebar-menu">
              <li>
                <a href="#">
                  <i className="menu-icon fa fa-lightbulb-o bg-red"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Luminárias de LED
                      <span className="label label-danger pull-right">30%</span>
                    </h4>
                    <br />
                      <div className="progress progress-xxs">
                        <div className="progress-bar progress-bar-danger" style={{width: "30%"}}></div>
                      </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="menu-icon fa fa-lightbulb-o bg-yellow"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Vapor de Sódio
                      <span className="label label-success pull-right">31%</span>
                    </h4>
                    <br />
                    <div className="progress progress-xxs">
                      <div className="progress-bar progress-bar-success" style={{width: "31%"}}></div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="menu-icon fa fa-lightbulb-o bg-light-blue"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Vapor de Mercúrio
                      <span className="label label-warning pull-right">26%</span>
                    </h4>
                    <br />

                      <div className="progress progress-xxs">
                        <div className="progress-bar progress-bar-warning" style={{width: "26%"}}></div>
                      </div>

                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="menu-icon fa fa-lightbulb-o bg-green"></i>

                  <div className="menu-info">
                    <h4 className="control-sidebar-subheading">
                      Outros tipos
                      <span className="label label-primary pull-right">13%</span>
                    </h4>
                    <br />

                      <div className="progress progress-xxs">
                        <div className="progress-bar progress-bar-primary" style={{width: "13%"}}></div>
                      </div>

                  </div>
                </a>
              </li>
            </ul>

          </div>
          <div className="tab-pane" id="control-sidebar-mapas-tab">
            <form method="post">
              <h3 className="control-sidebar-heading">Pontos de Iluminação</h3>

              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>

              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>

              <div className="form-group">
                <label className="control-sidebar-subheading">
                  Turn off notifications
                  <input type="checkbox" className="pull-right" />
                </label>
              </div>
            </form>
          </div>
        
        </div>
      </aside>
      </>
    )
  }

}export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey, 
    language: 'pt-BR' 
  }
))(dashboardMapa)
