import axios from "axios";
import Config from "../config"

const apiService = axios.create({
    baseURL: Config.dominio,
    timeout: 60000,
});

apiService.interceptors.response.use((response) => response, (error) => {
    console.error("Response error", error);
    alert("Ocorreu um erro não identificado, entre em contato com nossa equipe de suporte.")
    throw error;
});

export default apiService