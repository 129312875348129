import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import LeafletCanvasMarker from "./LeafletCanvasMarker"
import LightPoints from "../../../services/lightPoints"
import "./map.css"

const position = [-8.246380, -35.062704]

const MapaDashBoard = () => {
    const [loading, setLoading] = useState(true)
    const [dataPoints, setDataPoints] = useState([])

    const getPositionMap = async () => {
        setLoading(true)
        await LightPoints.findAll().then((response) => {
            setDataPoints(response.data)
        }).catch((err) => console.error(err))

        setLoading(false)
    }
    useEffect(() => {
        getPositionMap()
    }, [])

    return (
        <>
            {loading ?
                <div className="loader-container-map">
                    <div className="spinner-map" />
                    <div className="dashboardMapFirst" />
                </div>
                :
                <div className="dashboardMapFirst">
                    <MapContainer
                        center={position}
                        zoom={12}
                        style={{ height: '100%' }}
                        preferCanvas={true}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LeafletCanvasMarker dataPoints={dataPoints} />
                    </MapContainer>
                </div>}
        </>

    );
}

export default MapaDashBoard