import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import { format } from "date-fns";
import Modal from "simple-react-modal";
import { usuario, factoryLamp } from "../../../lib/utils2";
import { AxiosPost } from "../../../lib/utils3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Config from "../../../config";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import AtencaoMsg from "../../common/AtencaoMsg";

const animatedComponents = makeAnimated();

const scrollList = {
  overflowX: "scroll",
  with: "100%",
};

const toastParam = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

export class distribuicao extends Component {
  state = {
    poligono: null,
    show: "tabela",
    showImpressao: false,
    equipeImpressao: null,
    osImpressao: null,
    result: [],
    lstTecnicos: null,
    lstDistribuidas: null,
    lstBairros: [],
    lstTipoOs: [
      { value: "0", label: "Ornamental" },
      { value: "1", label: "Convencional" },
    ],
    tipoOs: [],
    bairro: [],
    lstDistritos: [],
    distrito: [],
    filtroValor: "",
    filtroPlaceholder: "OS",
    tecnico: [],
    totalSelecionados: 0,
    lstProtocolosSelecionados: [],
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude,
    paginacao: 0,
    dataAbertura: "",
    vencimento: 0,
    loading: true,
  };

  async distribuir() {
    // event.preventDefault()
    if (this.state.lstProtocolosSelecionados.length === 0) {
      toast.error("Escolha pelo menos uma OS para distribuir.", toastParam);
    } else if (this.state.tecnico == null) {
      toast.error(
        "Escolha pelo menos uma equipe para distribuir as OS.",
        toastParam
      );
    } else {
      await AxiosPost(
        `${Config.dominio}/php/os/ordemServico_distribuir.php`,
        JSON.stringify({
          lista: this.state.lstProtocolosSelecionados.toString(),
          equipe: this.state.tecnico,
          observacao:
            "Distribuído para a equipe " +
            this.state.lstTecnicos.find((item) => item.id == this.state.tecnico)
              .nome,
        })
      )
        .then(async function (response) {
          toast.success("Ordens de Serviço distribuídas!", toastParam);
        })
        .then(
          function () {
            this.setState({
              poligono: null,
              show: "tabela",
              result: [],
              lstTecnicos: null,
              lstDistribuidas: null,
              lstBairros: [],
              lstDistritos: [],
              tecnico: [],
              totalSelecionados: 0,
              lstProtocolosSelecionados: [],
              latitude: usuario().municipio.latitude,
              longitude: usuario().municipio.longitude,
            });
            this.tecnicos();
            this.osPendentes();
          }.bind(this)
        )
        .catch((erro) => console.error(erro));
    }
  }

  async tecnicos() {
    await AxiosPost(
      `${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get_with_sum_protocol.php`,
      JSON.stringify({ a: 1 })
    )
      .then(async (response) => this.setState({ lstTecnicos: response.data }))
      .catch((erro) => console.error(erro));
  }

  async pesquisar() {
    let filtroValor = this.state.filtroValor;
    let filtroPlaceholder = this.state.filtroPlaceholder;
    let _lstBairros = [];
    this.state.bairro.length > 0 &&
      this.state.bairro.map((item) => _lstBairros.push(item.value));
    let _lstDistritos = [];
    this.state.distrito.length > 0 &&
      this.state.distrito.map((item) => _lstDistritos.push(item.value));
    await AxiosPost(
      `${Config.dominio}/php/os/ordemServicoAcompanhamento_list.php`,
      JSON.stringify({
        status: 2,
        //filtro_barramento: filtroPlaceholder == 'Barramento' ? filtroValor : '',
        //filtro_distrito: filtroPlaceholder == 'Distrito' ? _lstDistritos.toString() : '',
        filtro_bairro:
          filtroPlaceholder == "Bairro" ? _lstBairros.toString() : "",
        filtro_tipo_os:
          filtroPlaceholder == "Tipo de os" ? this.state.tipoOs.value : "",
        filtro_rua: filtroPlaceholder == "Logradouro" ? filtroValor : "",
        filtro_os: filtroPlaceholder == "OS" ? filtroValor : "",
        filtro_dataAbertura:
          filtroPlaceholder == "Data de Abertura"
            ? this.state.dataAbertura
            : "",
      })
    )
      .then(async (response) => {
        if (response.data.length === 0) {
          //o Array deve ser zerado
          toast.warning("Nenhuma ordem de serviço encontrada!", toastParam);
        } else {
          this.setState({ result: response.data });
          toast.success("A requisição foi recebida com sucesso!", toastParam);
        }
      })
      .catch((erro) => console.error(erro));
  }

  async osPendentes() {
    await AxiosPost(
      `${Config.dominio}/php/os/ordemServicoAcompanhamento_list.php`,
      JSON.stringify({ status: 2 })
    )
      .then(
        async function (response) {
          let bairros = [];
          let _bairros = [];
          let distritos = [];
          let _distritos = [];

          response.data.map((item) => bairros.push(item.bairro));
          bairros = [...new Set(bairros)];
          bairros.map(
            (item) =>
              item != "null" && _bairros.push({ value: item, label: item })
          );

          response.data.map((item) =>
            distritos.push(item.distrito + "|" + item.id_distrito)
          );
          distritos = [...new Set(distritos)];
          distritos.map(
            (item) =>
              item != "null|null" &&
              _distritos.push({
                value: item.split("|")[1],
                label: item.split("|")[0],
              })
          );

          this.setState({
            result: response.data,
            totalRegistros: response.data.length,
            lstBairros: _bairros,
            lstDistritos: _distritos,
            loading: false,
          });
        }.bind(this)
      )
      .catch((erro) => console.error(erro));
  }

  async addPontoPoligono(_lat, _lng) {
    let _poligono = [];
    if (this.state.poligono) _poligono = this.state.poligono;
    if (_poligono.length === 0) {
      _poligono.push({ lat: _lat, lng: _lng });
      _poligono.push({ lat: _lat, lng: _lng });
    } else {
      _poligono.splice(_poligono.length - 1, 0, { lat: _lat, lng: _lng });
    }
    this.setState({ poligono: _poligono });

    let saida = "";
    _poligono.map(
      (aresta) => (saida = saida + aresta.lng + " " + aresta.lat + ", ")
    );
    await AxiosPost(
      `${Config.dominio}/php/pontosNoPoligono.php`,
      JSON.stringify({
        poligono: saida,
        status: "2",
        local: sessionStorage.getItem("local"),
        chaveMunicipio: usuario().municipio.chave,
      })
    )
      .then(
        function (response) {
          response.data.map((item) => this.selecionarPonto(item.protocolo));
        }.bind(this)
      )
      .catch((erro) => console.error(erro));
  }

  async remPontoPoligono() {
    let _poligono = this.state.poligono;
    if (_poligono.length === 2) {
      _poligono = [];
    } else {
      _poligono.splice(_poligono.length - 2, 1);
    }
    this.setState({ poligono: _poligono });
    let saida = "";
    _poligono.map(
      (aresta) => (saida = saida + aresta.lng + " " + aresta.lat + ", ")
    );
    await AxiosPost(
      `${Config.dominio}/php/pontosNoPoligono.php`,
      JSON.stringify({
        poligono: saida,
        status: "2",
      })
    )
      .then(
        function (response) {
          response.data.map((item) => this.selecionarPonto(item.protocolo));
        }.bind(this)
      )
      .catch(function (erro) {
        console.error(erro);
      });
  }

  // ok - não mexer
  selecionarPonto(protoloco) {
    let lista = this.state.lstProtocolosSelecionados;
    if (lista.find((item) => item == protoloco)) {
      lista = lista.filter((item) => item != protoloco);
    } else {
      lista.push(protoloco);
    }
    this.setState({
      lstProtocolosSelecionados: lista,
      totalSelecionados: lista.length,
    });
  }
  changeLineColor(os) {
    const dataAberturaOs = this.dataDiff(format(
      new Date(os.datahora_abertura),
      "dd/MM/yyyy HH:mm"
    ))
    if (dataAberturaOs == 0) {
      return "green"
    } else if (dataAberturaOs == 1) {
      return "yellow"
    } else {
      return "red"
    }

  }
  changeBackGroundColorLabel(os) {
    const dataAberturaOs = this.dataDiff(format(
      new Date(os.datahora_abertura),
      "dd/MM/yyyy HH:mm"
    ))
    if (dataAberturaOs == 0) {
      return "green"
    } else if (dataAberturaOs == 1) {
      return "yellow"
    } else {
      return "red"
    }
  }

  // ok - não mexer
  selecionarTodas() {
    let lista = [];
    if (document.getElementById("selectAll").checked) {
      this.state.result.map((item) => lista.push(item.protocolo));
    }
    this.setState({ lstProtocolosSelecionados: lista });
  }

  trocaVisao(visao) {
    if (visao == "tabela") {
      this.setState({ poligono: null, paginacao: 15 });
      this.osPendentes(1, 15);
    } else {
      this.setState({ paginacao: 1000000 });
      this.osPendentes(1, 1000000);
    }
    this.setState({ show: visao });
  }

  dataDiff(dataAberturaOs) {
    let dataAtual = moment().format("DD/MM/YYYY HH:mm:ss");
    let d2 = dataAberturaOs;
    let diff = moment(dataAtual, "DD/MM/YYYY HH:mm:ss").diff(
      moment(d2, "DD/MM/YYYY HH:mm:ss")
    );
    let dias = moment.duration(diff).asDays();

    if (dias <= 7) {
      this.state.vencimento = dias;
      return 0;
    } else if (dias == 8) {
      this.state.vencimento = dias;
      return 1;
    } else if (dias > 9) {
      this.state.vencimento = dias;
      return 2;
    }
  }

  async componentDidMount() {
    this.tecnicos();
    this.osPendentes();
  }

  render() {
    let _p = this.state.poligono;
    return (
      <>
        <ReactTooltip />
        <ToastContainer />
        <Modal
          show={this.state.showImpressao}
          containerStyle={{
            background: "white",
            width: "507px",
            minHeight: "250px",
          }}
          transitionSpeed={100}
        >
          <section className="content">
            <center>
              <h3>Imprimir Ordens de Serviço</h3>
              <a
                href="#"
                className="btn btn-primary btn-block margin-bottom"
                onClick={() => {
                  document.forms["formPrint"].submit();
                  this.setState({ showImpressao: false, os: null });
                }}
              >
                Imprimir
              </a>
              <p />
              <form
                target="_blank"
                id="formPrint"
                action={
                  usuario().municipio.razaoSocial ==
                    "Prefeitura Municipal de João Pessoa"
                    ? `${Config.dominio}/php/osAbertaGrupoJp.php`
                    : `${Config.dominio}/php/osAbertaGrupo.php`
                }
                method="post"
              >
                <input
                  type="hidden"
                  name="municipio"
                  value={usuario().municipio.razaoSocial}
                />
                <input type="hidden" name="idstatus" value={3} />
                <input
                  type="hidden"
                  name="idequipe"
                  value={this.state.equipeImpressao}
                />
                <input
                  type="hidden"
                  name="empresa"
                  value={usuario().nomeCliente}
                />
                <input type="hidden" name="usuario" value={usuario().nome} />
                <input
                  type="hidden"
                  name="local"
                  value={sessionStorage.getItem("local")}
                />
                <input
                  type="hidden"
                  name="chaveMunicipio"
                  value={usuario().municipio.chave}
                />
                <input type="checkbox" name="mapa" value="1" /> Incluir Mapa
                no(s) ponto(s).
              </form>
              <p />
              <a
                href="#"
                className="btn btn-warning btn-block margin-bottom"
                onClick={() => {
                  this.setState({ showImpressao: false });
                }}
              >
                Fechar
              </a>
            </center>
          </section>
        </Modal>
        <section className="content-header">
          <h1>
            Ordem de Serviço
            <small>Distribuição de ordens de serviço</small>
          </h1>
          <div className="breadcrumb">
            <a href="#" onClick={(e) => this.trocaVisao("tabela")}>
              <i className="fa fa-th-list"></i>{" "}
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="#" onClick={(e) => this.trocaVisao("mapa")}>
              <i className="fa fa-map-o"></i>{" "}
            </a>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="box">
              <p />
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-warning dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Filtro&nbsp;&nbsp;
                      <span className="fa fa-caret-down"></span>
                    </button>
                    <ul className="dropdown-menu">
                      {/* <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Barramento' })}>Barramento</a></li>
                  <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Distrito' })}>Distrito</a></li> */}
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({ filtroPlaceholder: "Bairro" })
                          }
                        >
                          Bairro
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({ filtroPlaceholder: "Tipo de os" })
                          }
                        >
                          Tipo de os
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({ filtroPlaceholder: "Logradouro" })
                          }
                        >
                          Logradouro
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({ filtroPlaceholder: "OS" })
                          }
                        >
                          OS
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              filtroPlaceholder: "Data de Abertura",
                            })
                          }
                        >
                          Data
                        </a>
                      </li>
                    </ul>
                  </div>
                  {this.state.filtroPlaceholder != "Distrito" &&
                    this.state.filtroPlaceholder != "Bairro" &&
                    this.state.filtroPlaceholder != "Tipo de os" &&
                    this.state.filtroPlaceholder != "Data de Abertura" && (
                      <input
                        type="text"
                        onChange={(e) =>
                          this.setState({ filtroValor: e.target.value })
                        }
                        className="form-control"
                        placeholder={this.state.filtroPlaceholder}
                      />
                    )}
                  {this.state.filtroPlaceholder == "Distrito" && (
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={this.state.lstDistritos}
                      style={{ width: "100%" }}
                      value={this.state.distrito}
                      onChange={(selectedOption) =>
                        this.setState({ distrito: selectedOption })
                      }
                    />
                  )}
                  {this.state.filtroPlaceholder == "Bairro" && (
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={this.state.lstBairros}
                      style={{ width: "100%" }}
                      value={this.state.bairro}
                      onChange={(selectedOption) =>
                        this.setState({ bairro: selectedOption })
                      }
                    />
                  )}
                  {this.state.filtroPlaceholder == "Tipo de os" && (
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      options={this.state.lstTipoOs}
                      style={{ width: "100%" }}
                      value={this.state.tipoOs}
                      onChange={(selectedOption) =>
                        this.setState({ tipoOs: selectedOption })
                      }
                    />
                  )}
                  {this.state.filtroPlaceholder == "Data de Abertura" && (
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.dataAbertura}
                      onChange={(event) =>
                        this.setState({ dataAbertura: event.target.value })
                      }
                    />
                  )}
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-info btn-flat"
                      onClick={() => this.pesquisar()}
                    >
                      Pesquisar
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-4">

              <div className="box box-warning" style={{margin: 0}}>
                <div className="box-header with-border">
                  <h3 className="box-title">Equipes</h3>
                  <div className="box-tools"></div>
                </div>
                <div className="box-body no-padding" style={{ maxHeight: "76vh", overflow: "auto", margin: "0" }}>
                  <ul className="nav nav-pills nav-stacked">
                    <ClipLoader
                      css={{ display: "block", margin: "0 auto", borderColor: "red" }}
                      color={"#123abc"}
                      loading={this.state.lstTecnicos === null}
                    />
                    {this.state.lstTecnicos &&
                      this.state.result &&
                      this.state.lstTecnicos.map((_tecnico, index) => (
                        <li style={{ margin: "3px" }} key={index}>
                          <input
                            type="radio"
                            name="equipe"
                            value={_tecnico.id}
                            onClick={(e) =>
                              this.setState({ tecnico: e.target.value })
                            }
                          />
                          &nbsp;{_tecnico.nome}
                          <span className="pull-right-container">
                            <small className="label pull-right">
                              {" "}
                              <a
                                href="#"
                                onClick={() =>
                                  this.setState({
                                    showImpressao: true,
                                    equipeImpressao: _tecnico.id,
                                  })
                                }
                              >
                                <i className="fa fa-print"></i>
                              </a>{" "}
                            </small>
                            <small className="label pull-right bg-blue">
                              {_tecnico.total_os}
                            </small>
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="box box-success" style={{ maxHeight: "81vh", overflow: "hidden", margin: "0" }}>
                <div className="box-header with-border">
                  <h3 className="box-title">Ordens a Distribuir</h3>
                  <span className="pull-right">
                    OS selecionadas:{" "}
                    {this.state.lstProtocolosSelecionados.length} /{" "}
                    {this.state.result && this.state.result.length}
                  </span>
                </div>
                <div className="box-body no-padding">
                  <div
                    id="tabela"
                    style={{
                      display: this.state.show == "tabela" ? "block" : "none",
                    }}
                  >
                    <div className="mailbox-controls">
                      <div className="row" style={{ margin: "0px" }}>
                        <div className="col-md-8" style={{ padding: 0 }}>
                          <input
                            type="checkbox"
                            id="selectAll"
                            checked={
                              this.state.result &&
                              this.state.lstProtocolosSelecionados.length ==
                              this.state.result.length
                            }
                            onChange={() => this.selecionarTodas()}
                          />{" "}
                          Marcar / desmarcar todas
                        </div>
                        <div className="col-md-4">
                          <a
                            href="#"
                            className="btn btn-primary btn-block margin-bottom"
                            onClick={() => this.distribuir()}
                          >
                            Distribuir
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mailbox-messages" style={{ maxHeight: "69vh", overflow: "auto" }}>
                      <table className="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Logradouro</th>
                            <th>Bairro</th>
                            <th>OS</th>
                            <th>Problema</th>
                            <th>Contribuinte</th>
                            <th>Tipo OS</th>
                            <th>Data/hora</th>
                            <th>Dias</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.result &&
                            this.state.result
                              .sort((a, b) =>
                                a.datahora_abertura > b.datahora_abertura
                                  ? 1
                                  : -1
                              )
                              .map((os, index) => {
                                return (
                                  <tr key={index} style={{ color: this.changeLineColor(os) }}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        id={os.protocolo}
                                        checked={
                                          this.state.lstProtocolosSelecionados.find(
                                            (item) => item == os.protocolo
                                          ) != undefined
                                        }
                                        onChange={() =>
                                          this.selecionarPonto(os.protocolo)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <p >
                                        {os.rua != "" ? os.rua : "Outros"}
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        {os.bairro != ""
                                          ? os.bairro
                                          : "Outros"}
                                      </p>
                                    </td>
                                    <td className="mailbox-name" >
                                      <p>
                                        {os.protocolo}
                                      </p>
                                    </td>
                                    <td className="mailbox-subject" >
                                      <span
                                        data-tip={os.descricao_problema}
                                      >
                                        <b>{os.desc_problema}</b>
                                      </span>
                                    </td>
                                    <td className="mailbox-subject">
                                      <p>
                                        {os.nome_contribuinte}
                                      </p>
                                    </td>
                                    <td className="mailbox-subject">
                                      <p >
                                        {" "}
                                        {os.tipo_os === "0"
                                          ? "Ornamental"
                                          : os.tipo_os === "1"
                                            ? "Convencional"
                                            : ""}
                                      </p>
                                    </td>
                                    <td className="mailbox-date">
                                      <p >
                                        {format(
                                          new Date(os.datahora_atual),
                                          "dd/MM/yyyy HH:mm"
                                        )}
                                      </p>
                                    </td>
                                    <td className="mailbox-name">
                                      <small className="label" style={{ background: this.changeBackGroundColorLabel(os) }}>
                                        {Math.round(this.state.vencimento)}
                                      </small>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    id="mapa"
                    style={{
                      height: "76vh",
                      display: this.state.show == "mapa" ? "block" : "none",
                    }}
                    className="form-group"
                  >
                    <Map
                      id="Mapa"
                      google={this.props.google}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        this.renderMarkers(map, maps)
                      }
                      zoom={14}
                      containerStyle={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                      initialCenter={{
                        lat: this.state.latitude,
                        lng: this.state.longitude,
                      }}
                      center={{
                        lat: this.state.latitude,
                        lng: this.state.longitude,
                      }}
                      onClick={(t, map, coord) => {
                        const { latLng } = coord;
                        this.addPontoPoligono(latLng.lat(), latLng.lng());
                      }}
                      onRightclick={(t, map, coord) => {
                        this.remPontoPoligono();
                      }}
                    >
                      {this.state.result &&
                        this.state.result.map((ponto, index) => (
                          <Marker
                            key={index}
                            position={
                              new window.google.maps.LatLng(
                                ponto.latitude,
                                ponto.longitude
                              )
                            }
                            title={ponto.protocolo}
                            id={ponto.protocolo}
                            icon={factoryLamp(
                              this.state.lstProtocolosSelecionados.find(
                                (prot) => prot == ponto.protocolo
                              )
                                ? "100"
                                : ponto.id_status,
                              ponto.telegestao
                            )}
                            draggable={false}
                          />
                        ))}
                      {_p &&
                        _p.map(
                          (ponto, index) =>
                            index === 0 && (
                              <Marker
                                key={index}
                                position={
                                  new window.google.maps.LatLng(
                                    ponto.latitude,
                                    ponto.longitude
                                  )
                                }
                                title={ponto.protocolo}
                                id={ponto.protocolo}
                                draggable={false}
                              />
                            )
                        )}
                      {_p &&
                        _p.map(
                          (aresta, index) =>
                            index > 0 && (
                              <Polyline
                                path={[
                                  {
                                    lat: _p[index - 1].lat,
                                    lng: _p[index - 1].lng,
                                  },
                                  { lat: _p[index].lat, lng: _p[index].lng },
                                ]}
                                fillColor="#BF5E4B"
                                fillOpacity={0.45}
                                strokeColor="#6B352A"
                                strokeOpacity={0.9}
                                strokeWeight={1}
                              />
                            )
                        )}
                    </Map>
                  </div>
                </div>
                <div className="box-footer no-padding"></div>
              </div>
            </div>
          </div>
        </section>

        <ClipLoader
          css={{ display: "block", margin: "0 auto", borderColor: "red" }}
          color={"#123abc"}
          loading={this.state.loading}
        />
      </>
    );
  }
}
export default GoogleApiWrapper((props) => ({
  apiKey: Config.apiKey,
  language: "pt-BR",
}))(distribuicao);