import React, { useState } from "react";
import ReactDOM from "react-dom";
import { AxiosPost } from "../../../lib/utils3";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Config from "../../../config";
import LstContribuintes from "./lstContribuintes"

export default (props) => {
    const [contribuinte, setContribuinte] = useState(props.id ? props : { nome: "", cpf: "", telefone: "", email: "" });
    const [id] = useState(props.id);
    const [buttonNameAction] = useState(props.id ? 'Atualizar' : 'Cadastrar');
    const actionPage = props.id ? 'update' : 'insert'

    const alertMessage = (msg) => {
        toast.warning(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }


    const defaultSpace = (content) => {
        return content.replace(/(^\s+|\s+$)/g, "").replace(/(\s+)/g, " ")
    }
    const sanitizePhonenumber = (phonenumber) => {
        return phonenumber.replace(/[^\d]+/g, '')
    }
    const sanitizeCPF = (cpf) => {
        return defaultSpace(cpf.replace(/[^\d]+/g, ''))
    }
    const checkPhonenumber = async (event) => {
        let sanitizedPhonenumber = sanitizePhonenumber(event.target.value)
        if (!sanitizedPhonenumber.match(/^\d{8,11}$/)) {
            event.target.setCustomValidity('Telefone Invalido!')
            return
        }
        event.target.setCustomValidity('')
    }
    const validCPF = async (event) => {
        let status = true
        let sanitizedCPF = sanitizeCPF(event.target.value)
        if (!sanitizedCPF.match(/^\d{11}$/)) {
            event.target.setCustomValidity('CPF Invalido!')
            return true
        }
        await AxiosPost(
            `${Config.dominio}/php/contribuintes/contribuintes_list.php`,
            JSON.stringify({ cpf: sanitizedCPF })
        )
            .then(async (response) => {
                if (response.data.length > 0) {
                    let user = response.data[0]
                    if (user.id != id) {
                        event.target.setCustomValidity('Esse CPF já existe no sistema!')
                        status = false
                    }
                }
            })
            .catch(erro => console.error(erro))
        if (status) {
            return true
        } else {
            return false
        }
    }

    const validEmail = async (event) => {
        let status = true
        let sanitizedEmail = defaultSpace(event.target.value)
        if (!sanitizedEmail.match(/^[a-z0-9.]+@[a-z0-9]+(\.[a-z]+)+$/i)) {
            event.target.setCustomValidity('Email invalido!')
            return true;
        }
        await AxiosPost(
            `${Config.dominio}/php/contribuintes/contribuintes_list.php`,
            JSON.stringify({ email: sanitizedEmail })
        )
            .then(async (response) => {
                if (response.data.length > 0) {
                    let user = response.data[0]
                    if (user.id != id) {
                        event.target.setCustomValidity('Esse email já foi cadastro no sistema!')
                        status = false
                    }
                }
            })
            .catch(erro => console.error(erro))
        if (status) {
            return true
        } else {
            return false
        }
    }

    const handleChange = (prop) => async (event) => {
        setContribuinte({ ...contribuinte, [prop]: event.target.value });
        let validated = true
        switch (prop) {
            case "email":
                validated = await validEmail(event)
                if (!validated) alertMessage('O Email digitado já existe')
                break;
            case "cpf":
                validated = await validCPF(event)
                if (!validated) alertMessage('O CPF digitado já existe')
                break;
            default:
                break;
        }
    };

    const saveTaxpayer = async (event) => {
        event.preventDefault()
        let cpf = sanitizeCPF(contribuinte.cpf)
        let telefone = sanitizePhonenumber(contribuinte.telefone)
        let nome = defaultSpace(contribuinte.nome).replace(/\s+/g, "*")
        let email = defaultSpace(contribuinte.email).replace(/\./g, "*")

        let params = { action: actionPage, id: contribuinte.id, cpf, telefone, nome, email }

        if (actionPage == 'update') {
            await AxiosPost(`${Config.dominio}/php/contribuintes/contribuintes_edit.php`,
                JSON.stringify(params)
            )
                .then(async (response) => {
                    ReactDOM.render(<LstContribuintes />, document.getElementById('Content'))
                })
                .catch(erro => console.error(erro))
        } else if (actionPage == 'insert') {
            await AxiosPost(`${Config.dominio}/php/contribuintes/contribuintes_edit.php`,
                JSON.stringify(params)
            )
                .then(async (response) => {
                    ReactDOM.render(<LstContribuintes />, document.getElementById('Content'))
                })
                .catch(erro => console.error(erro))
        }
    }

    return (
        <>
            <ToastContainer />
            <section className="content-header">
                <h1>
                    Contribuinte
                </h1>
                <ol className="breadcrumb">
                    <li>
                        <button
                            type="button"
                            className="btn btn-danger btn-flat"
                            onClick={() => ReactDOM.render(<LstContribuintes />, document.getElementById('Content'))}
                        >Voltar</button>
                    </li>
                </ol>
            </section>

            <form onSubmit={saveTaxpayer} >
                <section className="content">
                    <div className="box box-success">
                        <div className="box-header with-border">
                            <h3 className="box-title">Informações do contribuinte</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="Grupo">CPF</label>
                                        <div className="flex">
                                            <input
                                                required
                                                id="cpf"
                                                type="text"
                                                className="form-control"
                                                placeholder="CPF"
                                                value={sanitizeCPF(contribuinte.cpf || "")}
                                                minLength={11}
                                                maxLength={15}
                                                onChange={handleChange("cpf")}
                                                onInvalid={e => validCPF(e)}
                                                onInput={(e) =>  e.target.setCustomValidity('')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label>Nome</label>
                                        <input
                                            required
                                            id="nome"
                                            type="text"
                                            className="form-control"
                                            placeholder="Nome"
                                            value={contribuinte.nome}
                                            onChange={handleChange("nome")}

                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label>Telefone</label>
                                        <input
                                            required
                                            id="telefone"
                                            type="text"
                                            minLength={8}
                                            className="form-control"
                                            placeholder="(99) 999999-9999"
                                            value={contribuinte.telefone}
                                            onChange={handleChange("telefone")}
                                            onInvalid={e => checkPhonenumber(e)}
                                            onInput={(e) =>  e.target.setCustomValidity('')}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            required
                                            id="email"
                                            type="text"
                                            className="form-control"
                                            placeholder="meu@email.com.br"
                                            value={contribuinte.email}
                                            onChange={handleChange("email")}
                                            onInvalid={e => validEmail(e)}
                                            onInput={(e) =>  e.target.setCustomValidity('')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button
                                            id="submit-taxpayer"
                                            type="submit"
                                            className="btn btn-block btn-primary"
                                        >
                                            {buttonNameAction}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </>
    );
};