import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import Config from '../../../config'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LstClientes from './lstClientes'
import Utils from '../../../lib/utils'
import { AxiosPost } from '../../../lib/utils3'
import LstClienteMunicipio from './lstClienteMunicipio'
import LstClienteEmpresa from './lstClienteEmpresa'
import { usuario } from '../../../lib/utils2'

import ClientService from '../../../services/Client'

export default class cliente extends Component {

  state = {
    id: 0,
    nome: "",
    razaoSocial: "",
    chave: "",
    codigo: "",
    bloqueado: 0,
    tipo: 0,
    host: "",
    dbase: "",
    dbuser: "",
    password: "",
    acao: "",
    showModal: false,
    lstMunicipio: [],
    lstMunicipioSelecionado: [],
    lstUF: [],
    UF: "",
    lstCidade: [],
    latitude: "",
    longitude: "",
    prefeito: "",
    obras: "",
    responsavelip: "",
    conf_autorizacaoAutomatica: false,
    valor_kwh: 0,
  }
  checkForm = this.checkForm.bind(this)
  confirmaExcluirClientOrCountry = this.confirmaExcluirClientOrCountry.bind(this)
  async checkForm(e) {
    e.preventDefault()
   this.handleSubmitClientOrCountry()

  }

  async handleSubmitClientOrCountry() {
    if (this.state.tipo === 0) {
      if (!this.state.codigo || !this.state.UF) {
        toast.info('Preencha as informações de Localização!',
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      } else if (!this.state.host || !this.state.dbase || !this.state.dbuser || !this.state.password) {
        // console.log()
        toast.info('Preencha as informações da Base de Dados!',
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }else{
        await this.createOrUpdate()
      }
    }else {
      await this.createOrUpdate()
    }
  }
  async createOrUpdate() {
    const ClientAction = this.state.id == 0 ? ClientService.createClient : ClientService.updateClient
    await ClientAction(
      {
        id: this.state.id,
        nome: this.state.nome && this.state.nome.trim(),
        razaoSocial: this.state.razaoSocial && this.state.razaoSocial.trim(),
        chave: this.state.chave,
        codigo: this.state.codigo,
        bloqueado: this.state.bloqueado,
        tipo: this.state.tipo,
        hostCliente: this.state.host && this.state.host.trim() || '',
        dbaseCliente: this.state.dbase && this.state.dbase.trim() || '',
        dbuserCliente: this.state.dbuser && this.state.dbuser.trim() || '',
        passwordCliente: this.state.password && this.state.password.trim() || '',
        lstMunicipios: this.state.lstMunicipioSelecionado.map(item => item.id).toString(),
        UF: this.state.UF && this.state.UF,
        latitude: this.state.latitude && this.state.latitude,
        longitude: this.state.longitude && this.state.longitude,
        prefeito: this.state.prefeito,
        obras: this.state.obras,
        responsavelip: this.state.responsavelip,
        valor_kwh: this.state.valor_kwh
      }
    ).then((response) => {
      toast.success('Cliente registrado!',
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: this.state.tipo == 0 ?
            ReactDOM.render(<LstClienteMunicipio />, document.getElementById('Content')) :
            ReactDOM.render(<LstClienteEmpresa />, document.getElementById('Content'))
        }
      )
    }).catch((err) => console.error(err))
  }
  cancelaExcluir() {
    ReactDOM.render(<LstClientes />, document.getElementById('Content'))
  }

  async confirmaExcluirClientOrCountry() {
    await AxiosPost(
      `${Config.dominio}/php/sistema/cliente_delete.php`,
      JSON.stringify(
        {
          id: this.state.id
        }
      )
    )
      .then(async function (response) {
        ReactDOM.render(<LstClientes />, document.getElementById('Content'))
      })
      .catch(erro => console.error(erro))
  }

  async pegaChave(e) {
    e.preventDefault()
    if (this.state.chave == '') {
      let _chave = ''
      while (_chave == '') {
        _chave = Utils.gerarChave()
        await AxiosPost(`${Config.dominio}/php/chave.php`,
          JSON.stringify({
            chave: _chave
          })
        )
          .then(async function (response) {
            if (response.data.length != 0) _chave = ''
          })
          .catch(erro => console.error(erro))
        this.setState({ chave: _chave })
      }

    }
  }

  selecionarMunicipios(e) {
    e.preventDefault()
    let lst = this.state.lstMunicipio
    let lst2 = this.state.lstMunicipioSelecionado
    let lista = document.getElementById("naoSelecionados").selectedOptions
    for (let i = 0; i < lista.length; i++) {
      lst2.push(lst.find(item => item.id == lista[i].value))
      lst.splice(lst.findIndex(item => item.id == lista[i].value), 1)
    }
    this.setState({
      lstMunicipio: lst,
      lstMunicipioSelecionado: lst2
    })
  }

  desselecionarMunicipios(e) {
    e.preventDefault()
    let lst2 = this.state.lstMunicipioSelecionado
    let lst = this.state.lstMunicipio
    let lista = document.getElementById("selecionados").selectedOptions
    for (let i = 0; i < lista.length; i++) {
      lst.push(lst2.find(item => item.id == lista[i].value))
      lst2.splice(lst2.findIndex(item => item.id == lista[i].value), 1)
    }
    this.setState({
      lstMunicipioSelecionado: lst2,
      lstMunicipios: lst
    })
  }

  async empreiteiraMunicipio() {
    let lst = this.state.lstMunicipio
    let lst2 = this.state.lstMunicipioSelecionado
    await AxiosPost(`${Config.dominio}/php/sistema/empreiteiraMunicipio_list.php`,
      JSON.stringify({
        idEmpresa: this.state.id,
        tipo: 0,
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
      })
    )
      .then(async function (response) {
        response.data.map(it => lst2.push(lst.find(item => item.id == it.idmunicipio)))
      })
      .catch(erro => console.error(erro))
    for (let i = 0; i < lst2.length; i++) {
      lst.splice(lst.findIndex(item => item.id == lst2[i].id), 1)
    }
    this.setState({
      lstMunicipio: lst,
      lstMunicipioSelecionado: lst2
    })

  }

  async cidadeChange(e) {
    e.preventDefault()
    let _geocodigo = e.target.value
    let endereco = this.state.lstCidade.find(item => item.geocodigo == _geocodigo).municipio + ", " +
      this.state.lstUF.find(item => item.geocodigo == this.state.UF).sigla + ", Brasil"

    await Axios.get(`https://nominatim.openstreetmap.org/?q=${endereco}&format=json`)
      .then(async response => this.setState({ codigo: _geocodigo, latitude: response.data[0].lat, longitude: response.data[0].lon }))
      .catch(erro => console.error(erro))
  }

  async listarCidade(_uf) {
    await AxiosPost(`${Config.dominio}/php/sistema/municipios_list.php`, JSON.stringify({ uf: _uf }))
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ geocodigo: item.geocodigo, municipio: item.municipio }))
        this.setState({ lstCidade: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async listarUF() {
    await AxiosPost(`${Config.dominio}/php/sistema/uf_list.php`, JSON.stringify({ a: 0 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ geocodigo: item.geocodigo, sigla: item.sigla }))
        this.setState({ lstUF: _lista })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async componentDidMount() {
    let lista = []
    await AxiosPost(`${Config.dominio}/php/sistema/clienteMunicipio_list.php`, JSON.stringify({ a: 0 }))
      .then(async (response) => {
        response.data.map(item => lista.push({
          id: item.id,
          nome: item.nome
        })
        )
      })
      .catch(erro => console.error(erro))
    this.setState({ lstMunicipio: lista })

    this.listarUF()

    let _id = this.props.id
    let _acao = this.props.action
    if (_id != 0) {

      await AxiosPost(`${Config.dominio}/php/sistema/cliente_list.php`, JSON.stringify({
        id: _id,
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
      }))
        .then(async function (response) {
          let item = response.data[0]
          this.setState({
            id: _id,
            nome: item.nome,
            razaoSocial: item.razaoSocial,
            chave: item.chave,
            codigo: item.codigo,
            bloqueado: item.bloqueado,
            tipo: item.tipo,
            host: item.host,
            dbase: item.dbase,
            dbuser: item.dbuser,
            password: item.password,
            UF: item.uf,
            latitude: item.latitude,
            longitude: item.longitude,
            prefeito: item.prefeito,
            obras: item.secretarioObras,
            responsavelip: item.responsavelIP,
            acao: _acao
          })
          this.listarCidade(item.uf)
        }.bind(this))
        .catch(erro => console.error(erro))
    }
    if (this.props.action == "delete") this.setState({ showModal: true })
    this.empreiteiraMunicipio()

  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <h3>Confirma excluir esse Cliente?</h3>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  id="btnExcluir"
                  onClick={this.confirmaExcluirClientOrCountry}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={this.cancelaExcluir}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <form id="clientForm" onSubmit={this.checkForm}>
          <section className="content-header">
            <h1>
              Cliente
              <small>Cadastro</small>
            </h1>
            <ol className="breadcrumb">
              <li>
                <button
                  className="btn btn-danger btn-flat"
                  onClick={() => this.state.tipo == 0 ?
                    ReactDOM.render(<LstClienteMunicipio />, document.getElementById('Content')) :
                    ReactDOM.render(<LstClienteEmpresa />, document.getElementById('Content'))}
                >
                  Voltar
                </button>&nbsp;&nbsp;
                <button type="submit" id="btnSalvarClient" className="btn btn-primary" >Salvar</button>
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="col-md-4">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="nome">Nome fantasia</label>
                        <input
                          required
                          type="text"
                          id="fantasyName"
                          value={this.state.nome}
                          className="form-control"
                          onChange={e => this.setState({ nome: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="razaoSolcial">Razão Social</label>
                        <input
                          required
                          type="text"
                          id="businessName"
                          value={this.state.razaoSocial}
                          className="form-control"
                          onChange={e => this.setState({ razaoSocial: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="nome">{this.state.tipo == "1" ? 'CNPJ' : 'Codigo IBGE'}</label>
                        <input
                          required
                          type="text"
                          value={this.state.codigo}
                          className="form-control"
                          onChange={e => this.setState({ codigo: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label htmlFor="nome">Chave de acesso</label>
                        <input
                          required
                          type="text"
                          value={this.state.chave}
                          className="form-control"
                          placeholder="clique para obter a chave"
                          onClick={e => this.pegaChave(e)}
                          onChange={e => this.setState({ chave: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label>Tipo</label>
                        <select
                          className="form-control select2"
                          value={this.state.tipo}
                          onChange={event => this.setState({ tipo: event.target.value })}
                        >
                          <option value={0}>Município</option>
                          <option value={1}>Empresa</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <div className="mailbox-controls">
                          <input
                            type="checkbox"
                            checked={this.state.bloqueado == 1}
                            onChange={() => this.setState({ bloqueado: this.state.bloqueado == 0 ? 1 : 0 })}
                          /> Cliente Bloqueado
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {this.state.tipo == "0" &&
                <div className="box box-warning">
                  <div className="box-body">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        <li className="active"><a href="#localizacao" data-toggle="tab">Localização</a></li>
                        <li><a href="#pessoas" data-toggle="tab">Pessoas</a></li>
                        <li><a href="#basededados" data-toggle="tab">Base de dados</a></li>
                        <li><a href="#parametros" data-toggle="tab">Configuração</a></li>
                      </ul>
                      <div className="tab-content">
                        <div className="active tab-pane" id="localizacao">
                          <div className="box box-success">
                            <div className="box-header">
                              <h3 className="box-title">Localização</h3>
                            </div>
                            <div className="box-body">
                              <div className="row">
                                <div className="col-xs-3">
                                  <div className="form-group">
                                    <label>UF</label>
                                    <select
                                      id="uf"
                                      className="form-control select2"
                                      style={{ width: '100%' }}
                                      value={this.state.UF}
                                      onChange={event => {
                                        this.setState({ UF: event.target.value })
                                        this.listarCidade(event.target.value)
                                      }
                                      }
                                      required
                                    >
                                      <option>Escolha um Estado</option>
                                      {
                                        this.state.lstUF.map(opt => <option key={opt.geocodigo} value={opt.geocodigo}>{opt.sigla}</option>)
                                      }
                                    </select>
                                  </div>
                                </div>
                                <div className="col-xs-9">
                                  <div className="form-group">
                                    <label >Cidade</label>
                                    <select
                                      id="cidade"
                                      className="form-control select2"
                                      style={{ width: '100%' }}
                                      value={this.state.codigo}
                                      onChange={event => this.cidadeChange(event)}
                                    >
                                      <option>Escolha um Município</option>
                                      {
                                        this.state.lstCidade && this.state.lstCidade.map(opt => <option key={opt.geocodigo} value={opt.geocodigo} >{opt.municipio}</option>)
                                      }
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xs-6">
                                  <div className="form-group">
                                    <label htmlFor="latitude">Latitude</label>
                                    <input
                                      required
                                      type="text"
                                      value={this.state.latitude || ""}
                                      className="form-control"
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-6">
                                  <div className="form-group">
                                    <label htmlFor="longitude">Longitude</label>
                                    <input
                                      required
                                      type="text"
                                      value={this.state.longitude || ""}
                                      className="form-control"
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="pessoas">
                          <div className="box box-info">
                            <div className="box-body">
                              <div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Prefeito</label>
                                    <input
                                      type="text"
                                      value={this.state.prefeito}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ prefeito: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div><div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Secretário de obras</label>
                                    <input
                                      type="text"
                                      value={this.state.obras}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ obras: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div><div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Responsável IP</label>
                                    <input
                                      type="text"
                                      value={this.state.responsavelip}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ responsavelip: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basededados">
                          <div className="box box-success">
                            <div className="box-body">
                              <div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Host</label>
                                    <input

                                      type="text"
                                      value={this.state.host}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ host: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div><div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Database</label>
                                    <input

                                      type="text"
                                      value={this.state.dbase}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ dbase: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div><div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Usuário</label>
                                    <input

                                      type="text"
                                      value={this.state.dbuser}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ dbuser: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div><div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="nome">Password</label>
                                    <input

                                      type="text"
                                      value={this.state.password}
                                      className="form-control"
                                      placeholder=""
                                      onChange={e => this.setState({ password: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="parametros">
                          <div className="box box-success">
                            <div className="box-body">
                              <table className="table table-bordered table-striped" style={{ width: "100%" }}>
                                <tbody>
                                  <tr key={1}>
                                    <td style={{ width: "100%", whiteSpace: "nowrap" }}>
                                      Autorizações automáticas OS usuário prefeitura
                                      <span className="label pull-right header">
                                        <a href="#">
                                          <i onClick={() => this.setState({ conf_autorizacaoAutomatica: !this.state.conf_autorizacaoAutomatica })} className={this.state.conf_autorizacaoAutomatica ? "fa fa-toggle-on" : "fa fa-toggle-off"}></i>
                                        </a>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              }
              {this.state.tipo == "1" &&
                <div className="box box-warning">
                  <div className="box-header">
                    <div className="pull-right box-tools">
                    </div>
                    <h3 className="box-title">Associar município</h3>
                  </div>
                  <div className="box-body">
                    <div className="form-group">
                      <label>Municípios não atribuídos à Empresa</label>
                      <select
                        multiple
                        className="form-control"
                        id="naoSelecionados"
                        style={{ height: '150px' }}
                      >
                        {
                          this.state.lstMunicipio &&
                          this.state.lstMunicipio.sort((a, b) => a.nome < b.nome).map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <center>
                        <button className="btn btn-success" onClick={e => this.selecionarMunicipios(e)}>Incluir</button>
                        &nbsp;<button className="btn btn-warning" onClick={e => this.desselecionarMunicipios(e)}>Remover</button>
                      </center>
                    </div>
                    <div className="form-group">
                      <label>Municípios atribuídos à Empresa</label>
                      {
                        this.state.lstMunicipioSelecionado &&
                        <select
                          multiple
                          className="form-control"
                          id="selecionados"
                          style={{ height: '150px' }}
                        >
                          {
                            this.state.lstMunicipioSelecionado &&
                            this.state.lstMunicipioSelecionado.sort((a, b) => a.nome > b.nome).map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                          }
                        </select>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>

          </section >
        </form>
      </>
    )
  }
}
