import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import LstPontosIluminacao from './lstPontosIluminacao'
import ClipLoader from "react-spinners/ClipLoader"
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'


export class pontoIluminacaoMapa extends Component {

  state = { result: null }

  mapa() {
    if (this.state.result)
      return (
        <Map
          google={this.props.google}
          zoom={16}
          containerStyle = {{position: 'relative', width: '100%', height: '100%' }}
          center={{ lat: this.state.result.latitude, lng: this.state.result.longitude }}
          mapTypeControl={false}
          fullscreenControl={false}
        >
          <Marker
            position={new window.google.maps.LatLng(this.state.result.latitude, this.state.result.longitude)}
            name={this.state.result.barramento}
            color="green"
          />
        </Map>
      )
  }

  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
                      JSON.stringify({
                                        id: this.props.id,
                                        chaveMunicipio: usuario().municipio.chave
                                     })
    )
    .then(async function (response) {
        console.log(response.data[0])
        this.setState({result: response.data[0]})
    }.bind(this))
    .catch(erro => console.log(erro))
  }

  render () {
    return (
      <>
      <section className="content-header">
        <h1>
          Ponto de Iluminação
          <small>Mapa</small>
        </h1>
        <ol className="breadcrumb">
          <li>
            <button className="btn btn-primary" onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))} >Voltar</button>
          </li>
        </ol>
      </section>
      <section className="content">
        <ClipLoader
            css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
            color={"#123abc"}
            loading={!this.state.result}
        />
        { this.mapa() }
      </section>
      </>
    )
  }
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey, 
    language: 'pt-BR' 
  }
))(pontoIluminacaoMapa)