import Axios from 'axios'
import { usuario } from './utils2'

/// FUNÇÕES DE Envio de dados ao banco

export async function AxiosPost(destino, parametros) {
    if (destino =="") return "ERRO"
    let resposta = null

    let _parametros = JSON.parse(parametros)
    if (!_parametros.hasOwnProperty('chaveMunicipio')) _parametros.chaveMunicipio = usuario().municipio.chave
    if (!_parametros.hasOwnProperty('local')) _parametros.local = sessionStorage.getItem('local')
    if (!_parametros.hasOwnProperty('useraction')) _parametros.useraction = usuario().id
    
    parametros = JSON.stringify(_parametros)
    await Axios.post(destino, parametros)
    .then(async response => resposta = response)
    .catch(erro => console.log(erro))

    return resposta
}

export async function AxiosGet(destino, parametros) {
    if (destino =="") return "ERRO"
    let resposta = null

    let _parametros = JSON.parse(parametros)
    if (!_parametros.hasOwnProperty('chaveMunicipio')) _parametros.chaveMunicipio = usuario().municipio.chave
    if (!_parametros.hasOwnProperty('local')) _parametros.local = sessionStorage.getItem('local')
    parametros = JSON.stringify(_parametros)

    let u = new URLSearchParams(parametros).toString()

    await Axios.post(destino+"?"+u)
    .then(async response => resposta = response)
    .catch(erro => console.log(erro))

    return resposta
}