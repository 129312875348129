import React, { Component } from 'react'

export default class transformadorMapa extends Component {

  constructor(props) {
    super(props)
  }

  render () {
    return (
      <>
      Mapa de transformadores
      </>
    )
  }
}