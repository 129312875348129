import React, { useEffect, useState } from "react";
import "./map.css";
import Separator from "./Separator";

// -------------------------------------------
// Importação dos icones
// import LampPost0 from '../../../assets/lamp_posts/poste_0.png'
// import DeviceNotRegitered from '../../../assets/lamp_posts/devices_nao_cadastrados.png'
// import Lamp0 from '../../../assets/lamp_posts/lampada_0.png'

// import BlinkingLamp from '../../../assets/lamp_posts/lampada_piscando.png'
// import Megaphone0 from '../../../assets/lamp_posts/megafone_0.png'
import { ALERTS_ICONS, ALERTS_MESSAGE, TYPE_ALERTS } from "./enumTelegestao";

const ALERTS = [{
  type: TYPE_ALERTS.undervoltage,
  img: ALERTS_ICONS.undervoltage
},{
  type: TYPE_ALERTS.overvoltage,
  img: ALERTS_ICONS.overvoltage
},{
  type: TYPE_ALERTS.access_off,
  img: ALERTS_ICONS.access_off
},{
  type: TYPE_ALERTS.access_on,
  img: ALERTS_ICONS.access_on
},{
  type: TYPE_ALERTS.no_communication,
  img: ALERTS_ICONS.no_communication
},{
  type: TYPE_ALERTS.flashing,
  img: ALERTS_ICONS.flashing
},{
  type: TYPE_ALERTS.total_devices,
  img: ALERTS_ICONS.total_devices
}]
// const ALERTS_BKP = [
//   {
//     img: LampPost0,
//     total: 1000,
//   },
//   {
//     img: DeviceNotRegitered,
//     total: 2000,
//   },
//   {
//     img: Lamp0,
//     total: 3000,
//   },
//   {
//     img: overvoltageLamp,
//     total: 3000,
//   },
//   {
//     img: litOutsideHourLamp,
//     total: 3000,
//   },
//   {
//     img: wipedOutsideHourLamp,
//     total: 3000,
//   },
//   {
//     img: undervoltageLamp,
//     total: 3000,
//   },
//   {
//     img: noCommunicationLamp,
//     total: 3000,
//   },
//   {
//     img: BlinkingLamp,
//     total: 3000,
//   },
//   {
//     img: Megaphone0,
//     total: 3000,
//   },
// ]

const MenuAlerta = (props) => {
  const [alertaState, setAlertaState] = useState([])
  const [filtroSelecionadoState, setFiltroSelecionadoState] = useState("")
  const styleIcon = {
    heigth: "15px",
    width: "15px"
  }
  const styleIconLamps = {
    heigth: "21px",
    width: "30px"
  }
  useEffect(() => {
    setAlertaState(props.alertas)
  }, [props.alertas]);

  useEffect(() => {
    setAlertaState(props.alertas)
  }, [props.amountAlerts]);

  const trataFiltro = (filtro) => {
    console.log("filtro",filtro);
    if(filtro === filtroSelecionadoState) {
      setFiltroSelecionadoState("")
    }
    else {
      setFiltroSelecionadoState(filtro)
    }
    console.log("estado filtro selecionado", filtroSelecionadoState);
    props.setFiltro(filtroSelecionadoState)
  }

  const generateRow = (alerts) => {
    let rows = alerts.slice(0, alerts.length - 1).map(alert => (
      <>
        <div className="alert-row d-flex f-dir-row f-jc-sa" title={ALERTS_MESSAGE[alert.type]}>
          <div className="d-flex f-jc-center f-1">
            <img 
              src={alert.img}
            />
          </div>
          <div
            className="info f-1 f-dir-col"
          >
            <div className="text-center">
            <span>
              {props.amountAlerts ? props.amountAlerts[alert.type] : "0"}
            </span>
            <br />
            <span style={{fontSize:"10px"}}>{ALERTS_MESSAGE[alert.type]}</span>
            </div>
            
          </div>
        </div>
        <Separator />
      </>
    ))
    if (alerts.length > 0) {
      rows.push((
        <div className="alert-row d-flex f-dir-row f-jc-sa" title={ALERTS_MESSAGE[alerts[alerts.length-1].type]}>
          <div>
            <img 
              src={alerts[alerts.length-1].img}
            />
          </div>
          <div
            className="info f-dir-col"
          >
            <div className="text-center">
            <span>
              {/* {alerts[alerts.length-1].total} */}
              {props.amountAlerts ? props.amountAlerts[alerts[alerts.length-1].type] : "0"}
            </span>
            <br />
            <span style={{fontSize:"10px"}}>{ALERTS_MESSAGE[alerts[alerts.length-1].type]}</span>&nbsp;&nbsp;
            </div>
          </div>
        </div>
      ))
    }
    return rows;
  }

  return(
    // <div className="relatorio-alerta">
    //     <div className="list-group rounded" style={{marginBottom:"0px"}}>
    //       <div className="list-group-item" onClick={() => trataFiltro(TipoAlerta.LIGADO_DIA)} >
    //         <img src={alertaLigado} style={styleIconLamps}></img>
    //         <span>Aceso dia: 23</span>            
    //       </div>
    //       <div className="list-group-item">
    //         <img className="mr-5" src={alertaDesligado} style={styleIconLamps}></img>
    //         <span>Apagado noite: 30</span>            
    //       </div>
    //       <div className="list-group-item">
    //         <img src={alertaTensao} style={styleIcon}></img>
    //         <span>Variação tensão: 10</span>
    //       </div>
    //       <div className="list-group-item">
    //         <img src={alertaCorrente} style={styleIcon}></img>
    //         <span>Variação corrente: 3</span>
    //       </div>
    //     </div>
    //   </div>

    <div className="relatorio-alerta">
      {generateRow(ALERTS)}
    </div>

  );
}

export default MenuAlerta