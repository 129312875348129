import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { addDays, format } from 'date-fns'

import Config from '../../../config'

export default class dashboardEstoque extends Component {
  render() {
    return(
      <>
        <section className="content-header">
          <h1>
            Dashboard
            <small>Estoque</small>
          </h1>
        </section>
      </>
    )
  }
}