import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../../lib/utils3'
import Modal from 'simple-react-modal'
import LstVeiculos from './lstVeiculos'
import Config from '../../../../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default class veiculo extends Component {

  state = { 
            id: this.props.id,
            veiculo: '',
            modelo: '',
            placa: '',
            munk: 0,
            cesto: 0,
            showModal: false
          }
  handleSubmit = this.handleSubmit.bind(this)

  async handleSubmit(event) {
    event.preventDefault()
    let _acao = (this.state.id==0) ? 'veiculo_insert.php' : 'veiculo_update.php'

    if (this.state.placa != '') {
      await AxiosPost(`${Config.dominio}/php/equipe/${_acao}`, 
          JSON.stringify({
                          id: this.state.id,
                          veiculo: this.state.veiculo,
                          modelo: this.state.modelo,
                          placa: this.state.placa,
                          munk: this.state.munk==1,
                          cesto: this.state.cesto==1
                        })
      )
      .then(async function (response) {
        toast.info('Veículo armazenado com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstVeiculos />, document.getElementById('Content'))
          })
      })
      .catch(function (erro) {
        console.log(erro)
      })
    } else {
      toast.warning('Informe, no mínimo, a plada do veículo.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        })
    }
  }

  cancelaExcluir() {
    ReactDOM.render(<LstVeiculos />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    await AxiosPost(`${Config.dominio}/php/equipe/veiculo_delete.php`, 
                      JSON.stringify({ id: this.state.id })
    )
    .then(async function (response) {
      toast.info('Veículo excluído com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => ReactDOM.render(<LstVeiculos />, document.getElementById('Content'))
        })
    })
    .catch(function (erro) {
      console.log(erro)
    })
  }
 
  async buscar(id = 0) {
    if (id != 0) {
      await AxiosPost(`${Config.dominio}/php/equipe/veiculo_list.php`,
                      JSON.stringify({ id: this.state.id })
      )
      .then(async function (response) {
        if (response.status == 200 ) {
          if (response.data) {
            response.data.map(v => 
              this.setState({
                              veiculo: v.veiculo,
                              modelo: v.modelo,
                              placa: v.placa,
                              munk: v.munk==1,
                              cesto: v.cesto==1,
              })
            )
          } else {
            toast.warning('Veículo não encontrado.', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => ReactDOM.render(<LstVeiculos />, document.getElementById('Content'))
              })
          }
        }
      }.bind(this))
      .catch(function (erro) {
        console.log(erro);
      })
    }
  }

  async componentDidMount() {
    this.buscar(this.props.id)
    this.setState({
                    showModal: this.props.action == "delete",
                    id: this.props.id
                 })
  }
  
  render() {
    return (
      <>
        <ToastContainer />
        <Modal show={this.state.showModal} containerStyle={{background: 'Transparent'}} transitionSpeed={100}>
            <div className="box box-danger">
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-12">
                    <center>
                      <h4>Confirma excluir esse Veículo?</h4>
                    </center>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <center>
                  <button 
                    id="btnExcluir" 
                    onClick={() => this.confirmaExcluir()} 
                    className="btn btn-danger btn-flat"
                  >
                    Excluir
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    onClick={() => this.cancelaExcluir()} 
                    className="btn btn-warning btn-flat"
                  >
                    Cancelar
                  </button>
                </center>
              </div>
            </div>
          </Modal>
        <form onSubmit={this.handleSubmit}>
          <section className="content-header">
            <h1>
              Veículo
              <small>Cadastro</small>
            </h1>
            <ol className="breadcrumb">
              <li>
              <button onClick={this.cancelaExcluir} className="btn btn-danger btn-flat">Voltar</button>
              <button type="submit" className="btn btn-info btn-flat" >Salvar</button>  
              </li>
            </ol>
          </section>
          <section className="content">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Dados do Veículo</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-10">
                    <div className="form-group">
                      <label htmlFor="veiculo">Identificação </label>
                      <input 
                        type="text" 
                        className="form-control" 
                        defaultValue={ this.state.veiculo==null? '' : this.state.veiculo } 
                        onChange={event => this.setState({veiculo: event.target.value})}
                        placeholder="Dê um nome para o veículo"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-10">
                    <div className="form-group">
                      <label htmlFor="modelo">Modelo</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        defaultValue={ this.state.modelo==null? '' : this.state.modelo } 
                        onChange={event => this.setState({modelo: event.target.value})}
                      placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4">
                    <div className="form-group">
                      <label htmlFor="placa">Placa</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        defaultValue={ this.state.placa==null? '' : this.state.placa } 
                        onChange={event => this.setState({placa: event.target.value})}
                        placeholder="Formatos: ABC1234 ou ABC1D23"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-10">
                    <div className="form-group">
                      <div className="checkbox">
                        <label>
                          <input 
                            type="checkbox" 
                            id="cesto" 
                            checked={this.state.cesto} 
                            onChange={e=> this.setState({cesto: e.target.checked})}
                          /> Cesto aéreo (sky)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-10">
                    <div className="form-group">
                      <div className="checkbox">
                        <label>
                          <input 
                            type="checkbox" 
                            id="munk" 
                            checked={this.state.munk}
                            onChange={e=> this.setState({munk: e.target.checked})}
                          /> Munk
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">      
              </div>
            </div>
          </section>
        </form>
      </>
    )
  }
}